// AutoOpenPopupComponent.js
import React, { useState, useEffect } from "react";
import "./Dashboard.scss";
import ProfileIcon from "../assets/defaultprofile.svg";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import WarningIcon from "@mui/icons-material/Warning";
import { useDispatch, useSelector } from "react-redux";
import {
  createBrandProfile,
  updateProfilePhoto,
} from "../../actions/profileAction";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const [showPopup, setShowPopup] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [socialMediaLinks, setSocialMediaLinks] = useState([""]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [inputBrandName, setInputBrandName] = useState("");
  const [inputLocation, setInputLocation] = useState("");
  const [inputStreet, setInputStreet] = useState("");
  const [inputPinCode, setInputPinCode] = useState("");
  const [inputBio, setInputBio] = useState("");
  const [inputBrandWebsite, setInputBrandWebsite] = useState("");
  const [showWarning1, setShowWarning1] = useState(false);
  const [showWarning2, setShowWarning2] = useState(false);
  const [showWarning3, setShowWarning3] = useState(false);
  const [showWarning4, setShowWarning4] = useState(false);
  const [showWarning5, setShowWarning5] = useState(false);
  const [showWarning6, setShowWarning6] = useState(false);
  const [showWarning7, setShowWarning7] = useState(false);
  const [brandPhoto, setBrandPhoto] = useState(null);
  const [profilePhotoForBrand, setProfilePhoto] = useState(ProfileIcon);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { profilePhoto } = useSelector((state) => state.profielPhoto);
  const { user } = useSelector((state) => state.user || {});
  const { isProfileCreated } = useSelector((state) => state.profile);

  useEffect(() => {
    openPopup();
  }, []);

  useEffect(() => {
    if (profilePhoto && profilePhoto.userPhoto) {
      setProfilePhoto(profilePhoto.userPhoto);
    }
  }, [profilePhoto]);

  useEffect(() => {
    if (user && user.isprofilecompleted == 1) {
      navigate("/home");
    }
  }, [user]);

  useEffect(() => {
    if (isProfileCreated) {
      navigate("/home");
    }
  }, [isProfileCreated]);

  const openPopup = () => {
    setShowPopup(true);
    setCurrentStep(1);
  };

  const closePopup = () => {
    console.log(
      socialMediaLinks,
      selectedState,
      selectedCategory,
      inputBio,
      inputBrandName,
      inputLocation,
      inputPinCode,
      inputStreet,
      brandPhoto,
      inputBrandWebsite
    );

    dispatch(
      createBrandProfile({
        social_links: socialMediaLinks,
        state: selectedState,
        brand_categories: selectedCategory,
        bio: inputBio,
        brand_name: inputBrandName,
        flat_apartment: inputLocation,
        pincode: inputPinCode,
        area: inputStreet,
        brand_website: inputBrandWebsite,
        user_photo: profilePhoto?.userPhoto || null,
      })
    );
    setShowPopup(false);
  };

  const nextStep = () => {
    console.log(currentStep);
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    } else {
      closePopup();
    }
  };

  const showStep = (step) => {
    return currentStep === step ? "block" : "none";
  };

  const addSocialMediaLink = () => {
    if (socialMediaLinks.length < 3) {
      setSocialMediaLinks([...socialMediaLinks, ""]);
    }
  };

  const handleInputChange = (index, value) => {
    const updatedLinks = [...socialMediaLinks];
    updatedLinks[index] = value;
    setSocialMediaLinks(updatedLinks);
  };

  const handleBrandNameChange = (event) => {
    setInputBrandName(event.target.value);
    setShowWarning1(false);
  };

  const handleLocationChange = (event) => {
    setInputLocation(event.target.value);
    setShowWarning2(false);
  };

  const handleStreetChange = (event) => {
    setInputStreet(event.target.value);
    setShowWarning3(false);
  };

  const handlePinCodeChange = (event) => {
    setInputPinCode(event.target.value);
    setShowWarning4(false);
  };

  const handleBioChange = (event) => {
    setInputBio(event.target.value);
    setShowWarning5(false);
  };

  const handleBrandWebsiteChange = (event) => {
    setInputBrandWebsite(event.target.value);
    setShowWarning7(false);
  };

  const handleBrandBlur = () => {
    if (inputBrandName.trim() === "") {
      setShowWarning1(true);
    }
  };

  const handleLocationBlur = () => {
    if (inputLocation.trim() === "") {
      setShowWarning2(true);
    }
  };
  const handleStreetBlur = () => {
    if (inputStreet.trim() === "") {
      setShowWarning3(true);
    }
  };

  const handlePinCodeBlur = () => {
    if (inputPinCode.trim() === "") {
      setShowWarning4(true);
    }
  };

  const handleBioBlur = () => {
    if (inputBio.trim() === "") {
      setShowWarning5(true);
    }
  };

  // const handleCategoryBlur = () => {
  //   if (selectedCategory.trim() === "") {
  //     setShowWarning6(true);
  //   }
  // };

  const handleStateBlur = () => {
    if (selectedState.trim() === "") {
      setShowWarning7(true);
    }
  };

  const handleBrandPhotoChange = (event) => {
    const selectedFile = event.target.files[0];
    dispatch(updateProfilePhoto(selectedFile));
    // setProfilePhoto(profilePhoto && profilePhoto.userPhoto)
  };

  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setShowWarning6(false);
  };

  const handleCategoryChange = (event) => {
    const newValue = event.target.value;
    setSelectedCategory((prevState) => [...prevState, newValue]);
    setShowWarning7(false);
  };

  const handleClearAll = () => {
    setSelectedState("");
    setSelectedCategory("");
    setInputBio("");
    setInputLocation("");
    setInputStreet("");
    setInputPinCode("");
    setInputBrandName("");
    setShowWarning1(false);
    setShowWarning2(false);
    setShowWarning3(false);
    setShowWarning4(false);
    setShowWarning5(false);
    setShowWarning6(false);
    setShowWarning7(false);
  };

  return (
    <div className={`auto-open-popup ${showPopup ? "show" : ""}`}>
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <div style={{ display: showStep(1) }} className="popup-step">
              <div className="profile-setup">
                <h2>Let's Set Up Your Profile!</h2>
                <div className="image-section">
                  <label htmlFor="user_photo" className="image-label">
                    <img
                      src={profilePhotoForBrand}
                      alt="profile-logo"
                      className="profile-icon-img"
                    />
                    <div className="edit-icon">
                      <EditIcon />
                    </div>
                  </label>
                  <input
                    type="file"
                    id="user_photo"
                    accept="image/*"
                    onChange={(event) => handleBrandPhotoChange(event)}
                  />
                </div>

                <div className="input-section">
                  <div className="input-row">
                    <div className="input-container">
                      <label htmlFor="brandName" className="field-input-label">
                        Your Brand’s name
                      </label>
                      <input
                        className="profile-input-field"
                        type="text"
                        id="brandname"
                        placeholder="Brand's Name"
                        value={inputBrandName}
                        onChange={handleBrandNameChange}
                        onBlur={handleBrandBlur}
                      />
                      {showWarning1 && (
                        <div className="warning-message">
                          <WarningIcon className="warning-icon" />
                          Oops,You missed this one. Please enter it!
                        </div>
                      )}
                    </div>
                    <div className="input-container">
                      <label htmlFor="category" className="field-input-label">
                        Select Your Brand Category
                      </label>

                      <select
                        className="profile-input-field"
                        type="text"
                        placeholder="Select a Category"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        // onBlur={handleCategoryBlur}
                      >
                        <option value="">Select a Category</option>
                        <option value="Apparel & Footwear">
                          Apparel & Footwear
                        </option>
                        <option value="Beauty & Wellness">
                          Beauty & Wellness
                        </option>
                        <option value="Food & Beverage">Food & Beverage</option>
                        <option value="Home & Living">Home & Living</option>
                        <option value="Electronics & Appliances">
                          Electronics & Appliances
                        </option>
                        <option value="Travel & Tourism">
                          Travel & Tourism
                        </option>
                        <option value="Education & Training">
                          Education & Training
                        </option>
                        <option value="Professional Services">
                          Professional Services
                        </option>
                        <option value="Healthcare & Wellness">
                          Healthcare & Wellness
                        </option>
                        <option value="Entertainment & Media">
                          Entertainment & Media
                        </option>
                        <option value="India-Specific Categories">
                          India-Specific Categories
                        </option>
                        <option value="Traditional Wear">
                          Traditional Wear
                        </option>
                        <option value="Handicrafts & Home Decor">
                          Handicrafts & Home Decor
                        </option>
                        <option value="Ayurvedic & Natural Products">
                          Ayurvedic & Natural Products
                        </option>
                        <option value="Street Food & Local Cuisine">
                          Street Food & Local Cuisine
                        </option>
                        <option value="Festival & Occasion Wear">
                          Festival & Occasion Wear
                        </option>
                        <option value="Bollywood & Entertainment">
                          Bollywood & Entertainment
                        </option>
                        <option value="Cricket & Sports">
                          Cricket & Sports
                        </option>
                        <option value="Spiritual & Religious Products">
                          Spiritual & Religious Products
                        </option>
                        <option value="Educational Resources">
                          Educational Resources
                        </option>
                        <option value="Rural & Agricultural Products">
                          Rural & Agricultural Products
                        </option>
                      </select>
                      {showWarning6 && (
                        <div className="warning-message">
                          <WarningIcon className="warning-icon" />
                          Oops,You missed this one. Please enter it!
                        </div>
                      )}
                    </div>
                  </div>
                  <div id="location-label">
                    <label>Location</label>
                  </div>
                  <div className="input-row">
                    <div className="input-container">
                      <label
                        htmlFor="Location"
                        className="location-field-input-label"
                      >
                        Flat, Building, Apartment
                        <span className="span-profile-input-info">
                          (Optional)
                        </span>
                      </label>
                      <input
                        className="profile-input-field"
                        type="text"
                        id="location"
                        placeholder="Name of your Flat, Building, Apartment......"
                        value={inputLocation}
                        onChange={handleLocationChange}
                        onBlur={handleLocationBlur}
                      />
                      {showWarning2 && (
                        <div className="warning-message">
                          <WarningIcon className="warning-icon" />
                          Oops,You missed this one. Please enter it!
                        </div>
                      )}
                    </div>
                    <div className="input-container">
                      <label
                        htmlFor="street"
                        className="location-field-input-label"
                      >
                        Area, Street, Sector
                      </label>
                      <input
                        className="profile-input-field"
                        type="text"
                        id="street"
                        value={inputStreet}
                        onChange={handleStreetChange}
                        onBlur={handleStreetBlur}
                        placeholder="Name of your Area, Street, Sector..."
                      />
                      {showWarning3 && (
                        <div className="warning-message">
                          <WarningIcon className="warning-icon" />
                          Oops,You missed this one. Please enter it!
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="input-row">
                    <div className="input-container">
                      <label
                        htmlFor="state"
                        className="location-field-input-label"
                      >
                        State
                      </label>
                      <select
                        className="profile-input-field"
                        type="text"
                        id="state"
                        placeholder="Select Your State"
                        value={selectedState}
                        onChange={handleStateChange}
                        onBlur={handleStateBlur}
                      >
                        <option value="">Select Your State</option>
                        {indianStates.map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                      {showWarning7 && (
                        <div className="warning-message">
                          <WarningIcon className="warning-icon" />
                          Oops,You missed this one. Please enter it!
                        </div>
                      )}
                    </div>
                    <div className="input-container">
                      <label
                        htmlFor="pincode"
                        className="location-field-input-label"
                      >
                        PIN Code
                      </label>
                      <input
                        className="profile-input-field"
                        type="text"
                        id="code"
                        placeholder="6 Digit PIN Code"
                        value={inputPinCode}
                        onChange={handlePinCodeChange}
                        onBlur={handlePinCodeBlur}
                      />
                      {showWarning4 && (
                        <div className="warning-message">
                          <WarningIcon className="warning-icon" />
                          Oops,You missed this one. Please enter it!
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="input-container">
                      <label htmlFor="bio" className="field-input-label">
                        Brand's Bio
                      </label>
                      <textarea
                        className="profile-input-field"
                        placeholder="About your brand"
                        rows="4"
                        cols="40"
                        value={inputBio}
                        onChange={handleBioChange}
                        onBlur={handleBioBlur}
                      ></textarea>
                      {showWarning5 && (
                        <div className="warning-message">
                          <WarningIcon className="warning-icon" />
                          Oops,You missed this one. Please enter it!
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="footer-buttons">
                  <span onClick={handleClearAll}>Clear All</span>
                  <div className="button-box-area">
                    <button className="save-info-button">Save</button>
                    <button onClick={nextStep} className="switch-button">
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: showStep(2) }} className="popup-step">
              <div className="profile-setup">
                <h2>Let's Set Up Your Profile!</h2>

                <div className="image-section">
                  <label htmlFor="user_photo" className="image-label">
                    <img
                      src={profilePhotoForBrand}
                      alt="profile-logo"
                      className="profile-icon-img"
                    />
                    <div className="edit-icon">
                      <EditIcon />
                    </div>
                  </label>
                  <input type="file" id="user_photo" accept="image/*" />
                </div>
                <div className="your-component">
                  <div className="input-group">
                    <label htmlFor="website">
                      Your Brand's Website
                      <span className="span-profile-input-info">
                        (Optional)
                      </span>
                    </label>
                    <input
                      type="text"
                      id="website"
                      onChange={handleBrandWebsiteChange}
                    />
                  </div>

                  {socialMediaLinks.map((link, index) => (
                    <div key={index} className="input-group">
                      <label htmlFor={`socialMedia${index}`}>
                        {index === 0 ? "Brand's Social Media Links" : ""}
                      </label>
                      <div className="input-with-icon">
                        <input
                          type="text"
                          id={`socialMedia${index}`}
                          value={link}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                        />

                        {socialMediaLinks.length < 3 && (
                          <span
                            className="add-icon"
                            onClick={addSocialMediaLink}
                          >
                            <AddIcon />
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="finish-action-button">
                <button onClick={closePopup} className="finish-button">
                  Finish
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;

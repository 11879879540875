import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// import "react-phone-input-2/lib/style.css";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useDispatch, useSelector } from "react-redux";
import { setEmailInRedux } from "../../actions/userAction";
import loginimg from "../../components/assets/loginsideimg.png";
import Navbar from "../LandingComponents/Navbar/Navbar";
import Badge from "../assets/Badge.svg";
import UserIcon from "../assets/User.svg";

import { Checkbox, FormControlLabel } from "@mui/material";
import axios from "axios";
import { registerPhone, verifyPhoneOTP } from "../../actions/userAction";
import Footer from "../LandingComponents/Footer/Footer";
import Loader from "../layout/Loader/Loader";
import "./PhoneSignUp.scss";
// import PhoneInput from "react-phone-input-2";

const PhoneDetails = ({ onClick, role }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [phoneNumber, setPhoneNumber] = useState("");
  const phoneRegistrationSuccess = useSelector((state) => state.user.success);
  const isLoading = useSelector((state) => state.user.loading);
  const [phone, setPhone] = useState("");

  const [formValues, setFormValues] = useState({
    countrycode: "91",
    phone: "",
    rememberMe: false,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (phoneRegistrationSuccess) {
      onClick("C");
    }
  }, [phoneRegistrationSuccess, onClick]);

  const validate = () => {
    const newErrors = {};

    if (!formValues.phone) {
      newErrors.phone = "Mobile number is required.";
    } else if (!/^\d+$/.test(formValues.phone)) {
      newErrors.phone = "Only numeric characters are allowed.";
    } else if (formValues.phone.length !== 10) {
      newErrors.phone = "Mobile number must be exactly 10 digits.";
    }

    return newErrors;
  };

  console.log("setFormValues", formValues);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "countryCode") {
      // Update countryCode in the state
      setFormValues({
        ...formValues,
        countryCode: value, // Set the country code correctly here
      });
    } else if (name === "phone") {
      // Prevent non-numeric input
      if (!/^\d*$/.test(value)) {
        setErrors({ ...errors, phone: "Only numeric characters are allowed." });
        return;
      } else {
        // Clear error if input becomes valid
        setErrors({ ...errors, phone: "" });
      }
    }

    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleGenerateOTP = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      const phone_no = `+${formValues.countrycode}${formValues.phone}`;
      debugger;
      dispatch(registerPhone({ phone_no }));
    }
  };

  const formatPhoneNumber = (number) => {
    const formattedNumber = `+91${number}`;
    return formattedNumber;
  };

  // const handleGenerateOTP = () => {
  //   const phone_no = formatPhoneNumber(phone);
  //   dispatch(registerPhone({ phone_no }));
  // };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <div className="container-signup p-0 mt-5 mb-5">
            <div className="card">
              <div className="row no-gutters">
                {/* Left Side (Image Section) */}
                <div className="col-12 col-md-6 col-sm-12 left">
                  <img src={loginimg} alt="login-side-img" className="img" />
                </div>

                {/* Right Side (Login and Signup Buttons) */}
                <div className="col-12 col-md-6 col-sm-12 right">
                  <div className="row">
                    <div className="col-12 col-md-12 text-center">
                      <h2>
                        Collaborate.
                        <br /> Create. Connect.
                      </h2>

                      {/* Phone Number Input Section */}
                      <div className="flex mb-3 phone-input-container">
                        <div className="relative inline-flex items-center">
                          <select
                            className="appearance"
                            name="country-code"
                            id="country-code"
                            onChange={handleChange}
                            value={formValues.countryCode} // Ensure the state value is passed here
                          >
                            <option selected value={1} data-countrycode="IN">
                              IN +91
                            </option>
                            <option value={93} data-countrycode="AF">
                              AF +93
                            </option>
                            <option value={355} data-countrycode="AL">
                              AL +355
                            </option>
                            <option value={213} data-countrycode="DZ">
                              DZ +213
                            </option>
                            <option value={376} data-countrycode="AD">
                              AD +376
                            </option>
                            <option value={244} data-countrycode="AO">
                              AO +244
                            </option>
                            <option value={54} data-countrycode="AR">
                              AR +54
                            </option>
                            <option value={374} data-countrycode="AM">
                              AM +374
                            </option>
                            <option value={61} data-countrycode="AU">
                              AU +61
                            </option>
                            <option value={43} data-countrycode="AT">
                              AT +43
                            </option>
                            <option value={994} data-countrycode="AZ">
                              AZ +994
                            </option>
                            <option value={973} data-countrycode="BH">
                              BH +973
                            </option>
                            <option value={880} data-countrycode="BD">
                              BD +880
                            </option>
                            <option value={375} data-countrycode="BY">
                              BY +375
                            </option>
                            <option value={32} data-countrycode="BE">
                              BE +32
                            </option>
                            <option value={501} data-countrycode="BZ">
                              BZ +501
                            </option>
                            <option value={229} data-countrycode="BJ">
                              BJ +229
                            </option>
                            <option value={975} data-countrycode="BT">
                              BT +975
                            </option>
                            <option value={591} data-countrycode="BO">
                              BO +591
                            </option>
                            <option value={387} data-countrycode="BA">
                              BA +387
                            </option>
                            <option value={267} data-countrycode="BW">
                              BW +267
                            </option>
                            <option value={55} data-countrycode="BR">
                              BR +55
                            </option>
                            <option value={359} data-countrycode="BG">
                              BG +359
                            </option>
                            <option value={226} data-countrycode="BF">
                              BF +226
                            </option>
                            <option value={855} data-countrycode="KH">
                              KH +855
                            </option>
                            <option value={237} data-countrycode="CM">
                              CM +237
                            </option>
                            <option value={1} data-countrycode="CA">
                              CA +1
                            </option>
                            <option value={235} data-countrycode="TD">
                              TD +235
                            </option>
                            <option value={56} data-countrycode="CL">
                              CL +56
                            </option>
                            <option value={86} data-countrycode="CN">
                              CN +86
                            </option>
                            <option value={57} data-countrycode="CO">
                              CO +57
                            </option>
                            <option value={506} data-countrycode="CR">
                              CR +506
                            </option>
                            <option value={385} data-countrycode="HR">
                              HR +385
                            </option>
                            <option value={53} data-countrycode="CU">
                              CU +53
                            </option>
                            <option value={420} data-countrycode="CZ">
                              CZ +420
                            </option>
                            <option value={45} data-countrycode="DK">
                              DK +45
                            </option>
                            <option value={593} data-countrycode="EC">
                              EC +593
                            </option>
                            <option value={20} data-countrycode="EG">
                              EG +20
                            </option>
                            <option value={503} data-countrycode="SV">
                              SV +503
                            </option>
                            <option value={372} data-countrycode="EE">
                              EE +372
                            </option>
                            <option value={251} data-countrycode="ET">
                              ET +251
                            </option>
                            <option value={679} data-countrycode="FJ">
                              FJ +679
                            </option>
                            <option value={358} data-countrycode="FI">
                              FI +358
                            </option>
                            <option value={33} data-countrycode="FR">
                              FR +33
                            </option>
                            <option value={49} data-countrycode="DE">
                              DE +49
                            </option>
                            <option value={30} data-countrycode="GR">
                              GR +30
                            </option>
                            <option value={36} data-countrycode="HU">
                              HU +36
                            </option>
                            <option value={91} data-countrycode="IN">
                              IN +91
                            </option>
                            <option value={62} data-countrycode="ID">
                              ID +62
                            </option>
                            <option value={98} data-countrycode="IR">
                              IR +98
                            </option>
                            <option value={964} data-countrycode="IQ">
                              IQ +964
                            </option>
                            <option value={353} data-countrycode="IE">
                              IE +353
                            </option>
                            <option value={972} data-countrycode="IL">
                              IL +972
                            </option>
                            <option value={39} data-countrycode="IT">
                              IT +39
                            </option>
                            <option value={1} data-countrycode="JM">
                              JM +1-876
                            </option>
                            <option value={81} data-countrycode="JP">
                              JP +81
                            </option>
                            <option value={962} data-countrycode="JO">
                              JO +962
                            </option>
                            <option value={254} data-countrycode="KE">
                              KE +254
                            </option>
                            <option value={82} data-countrycode="KR">
                              KR +82
                            </option>
                            <option value={60} data-countrycode="MY">
                              MY +60
                            </option>
                            <option value={52} data-countrycode="MX">
                              MX +52
                            </option>
                            <option value={31} data-countrycode="NL">
                              NL +31
                            </option>
                            <option value={64} data-countrycode="NZ">
                              NZ +64
                            </option>
                            <option value={234} data-countrycode="NG">
                              NG +234
                            </option>
                            <option value={47} data-countrycode="NO">
                              NO +47
                            </option>
                            <option value={92} data-countrycode="PK">
                              PK +92
                            </option>
                            <option value={63} data-countrycode="PH">
                              PH +63
                            </option>
                            <option value={48} data-countrycode="PL">
                              PL +48
                            </option>
                            <option value={351} data-countrycode="PT">
                              PT +351
                            </option>
                            <option value={974} data-countrycode="QA">
                              QA +974
                            </option>
                            <option value={7} data-countrycode="RU">
                              RU +7
                            </option>
                            <option value={966} data-countrycode="SA">
                              SA +966
                            </option>
                            <option value={65} data-countrycode="SG">
                              SG +65
                            </option>
                            <option value={27} data-countrycode="ZA">
                              ZA +27
                            </option>
                            <option value={34} data-countrycode="ES">
                              ES +34
                            </option>
                            <option value={94} data-countrycode="LK">
                              LK +94
                            </option>
                            <option value={46} data-countrycode="SE">
                              SE +46
                            </option>
                            <option value={41} data-countrycode="CH">
                              CH +41
                            </option>
                            <option value={66} data-countrycode="TH">
                              TH +66
                            </option>
                            <option value={90} data-countrycode="TR">
                              TR +90
                            </option>
                            <option value={380} data-countrycode="UA">
                              UA +380
                            </option>
                            <option value={971} data-countrycode="AE">
                              AE +971
                            </option>
                            <option value={44} data-countrycode="GB">
                              GB +44
                            </option>
                            <option value={1} data-countrycode="US">
                              US +1
                            </option>
                            <option value={84} data-countrycode="VN">
                              VN +84
                            </option>
                            <option value={263} data-countrycode="ZW">
                              ZW +263
                            </option>
                          </select>
                        </div>
                        <input
                          className="mobile-input"
                          placeholder="Mobile Number"
                          name="phone"
                          id="phone"
                          type="tel"
                          onChange={handleChange}
                          value={formValues.phone}
                        />
                      </div>
                      {errors.phone && (
                        <div className="error">{errors.phone}</div>
                      )}

                      <div className="form-check mb-3 w-100 text-start">
                        <FormControlLabel
                          control={
                            <Checkbox
                              id="rememberMe"
                              checked={formValues.rememberMe}
                              onChange={handleChange}
                            />
                          }
                          label="Remember Me"
                        />
                        {/* <input
                          type="checkbox"
                          id="rememberMe"
                          className="form-check-input"
                          name="rememberMe"

                        />
                        <label htmlFor="rememberMe" className="form-check-label">
                          Remember Me
                        </label> */}
                      </div>

                      {/* Remember Me Checkbox */}

                      <button
                        type="button"
                        className="btn btn-success btn-lg mb-2"
                        onClick={handleGenerateOTP} // Generate OTP on button click
                      >
                        Generate OTP
                      </button>
                    </div>
                    <div className="col-12 col-md-12 footer">
                      {/* <div
                        className="d-flex flex-column min-vh-50"
                        style={{
                          marginTop: "8rem",
                        }}
                      > */}
                      {/* Other content */}

                      <div className="sign-link mt-auto">
                        {/* Add link to navigate back to the signup page */}
                        <Link
                          to="/email"
                          className="btn-link"
                          onClick={() => navigate("/email")} // Navigate to Sign Up page
                        >
                          Log In with Email?{" "}
                        </Link>
                      </div>
                      {/* </div> */}

                      <div
                        className="signup-instruction"
                        style={{
                          // marginTop: " 3%",
                          fontSize: "11px",
                        }}
                      >
                        By continuing you agree to our terms and conditions
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

const OtpPage = ({ onClick, role }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(Array.from({ length: 6 }, () => ""));
  const isLoading = useSelector((state) => state.user.loading);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const { userEmail } = useSelector((state) => state.email);
  const email = userEmail && userEmail.email;

  const handleVerifyOTP = () => {
    dispatch(verifyPhoneOTP({ otp, email }));
  };

  useEffect(() => {
    if (user.isRegisterSuccess) {
      navigate("/home");
    }
  });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="login-page">
          <div className="logo">
            {role === "influencer" ? (
              <>
                <img src={UserIcon} alt="User Logo" />
                <span className="brand-text">Influencer</span>
              </>
            ) : (
              <>
                <img src={Badge} alt="Logo" />
                <span className="brand-text">Brand</span>
              </>
            )}
          </div>

          <div className="brand-header-text">
            <h1>Hi, savvy </h1>
            <h1>marketer!</h1>
          </div>
          <div className="phone-login">
            <div>
              <MuiOtpInput
                value={otp}
                onChange={setOtp}
                length={6}
                separator={<span>-</span>}
                isInputNum
              />
            </div>
            <div className="user-pref-box">
              <div className="signup-check-box">
                <input className="signup-checkbox" type="checkbox"></input>
                <label>Remember Me</label>
              </div>

              <p onClick={() => onClick("B")} className="change-phone-number">
                Change Phone No.
              </p>
            </div>

            <button id="generate-otp-button" onClick={handleVerifyOTP}>
              Validate and Sign Up
            </button>
          </div>

          <div className="bottom-text">
            <p>
              Already Have an Account? <a href="/login">Log In Here</a>
            </p>
            <p>
              {role === "influencer" ? (
                <>
                  Not an Influencer?{" "}
                  <Link to="/signup-brand">Sign up as a Brand</Link>
                </>
              ) : (
                <>
                  Not a Brand?{" "}
                  <Link to="/signup-influencer">Sign up as an Influencer</Link>
                </>
              )}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

function Phone_Sign_Up() {
  const [currentComponent, setCurrentComponent] = useState("B");
  const { role } = useParams();
  const [userEmail, setUserEmail] = useState("");
  const dispatch = useDispatch();

  const getUser = async () => {
    try {
      const url = `http://localhost:5000/auth/login/success`;
      const { data } = await axios.get(url, { withCredentials: true });
      console.log("data of user is", data.user.emails[0].value);
      const email = data.user.emails[0].value;
      if (data && data.status === "success") {
        dispatch(setEmailInRedux({ email }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUser();
  });

  const handleButtonClick = (component, email = "") => {
    setCurrentComponent(component);
    setUserEmail(email);
  };

  return (
    <div className="home-div">
      {currentComponent === "B" && (
        <PhoneDetails role={role} onClick={handleButtonClick} />
      )}
      {currentComponent === "C" && (
        <OtpPage role={role} onClick={handleButtonClick} />
      )}
    </div>
  );
}

export default Phone_Sign_Up;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loadUser, verifyEmailOtp } from "../../actions/userAction";
import Navbar from "../../components/LandingComponents/Navbar/Navbar";
import loginimg from "../assets/loginsideimg.png";
import "./MobileOtp.scss";
import Loader from "../layout/Loader/Loader";

function MobileOtp() {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, user, isOtpVerified } = useSelector((state) => state.user);
  const userEmail = useSelector((state) => state.email?.userEmail);

  // Handle OTP input change
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    const newOtp = [...otp];

    if (/^\d$/.test(value) || value === "") {
      newOtp[index] = value;
      setOtp(newOtp);

      // Move to the next input automatically
      if (value !== "" && index < otp.length - 1) {
        document.querySelector(`.otp__field__${index + 2}`).focus();
      }
    }
  };

  // Handle OTP paste event
  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData("Text").trim();

    if (/^\d{6}$/.test(pastedData)) {
      // Adjust the regex for your OTP length
      const otpArray = pastedData.split("").slice(0, otp.length);
      const newOtp = [...otp];
      otpArray.forEach((digit, i) => {
        newOtp[i] = digit;
      });
      setOtp(newOtp);

      // Focus on the last filled input
      const lastFilledIndex = otpArray.length - 1;
      document.querySelector(`.otp__field__${lastFilledIndex + 1}`).focus();
    }
  };

  // Join OTP values into a single string for submission
  const handleSubmitOtp = () => {
    const otpString = otp.join(""); // Combine OTP fields into a single string
    dispatch(verifyEmailOtp({ otp: otpString }));
  };

  // Effect to navigate upon successful OTP verification
  useEffect(() => {
    if (isOtpVerified) {
      dispatch(loadUser());
      navigate("/home"); // Navigate to home after successful login
    }
  }, [isOtpVerified, dispatch, navigate]);

  // Attach paste handler on component mount
  useEffect(() => {
    const otpInputContainer = document.querySelector(".otp-input-fields");
    otpInputContainer.addEventListener("paste", handlePaste);

    return () => otpInputContainer.removeEventListener("paste", handlePaste);
  }, []);

  return (
    <>
      <Navbar />
      <div className="container-mobileotp p-0">
        <div className="card">
          <div className="row no-gutters">
            {/* Left Side (Image Section) */}
            <div className="col-xs-12 col-sm-12 col-md-12 left">
              <img src={loginimg} alt="Image" className="img" />
            </div>

            {/* Right Side (OTP Input Section) */}
            <div className="col-xs-12 col-sm-12 col-md-12 right">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                  <h2>
                    Collaborate.
                    <br /> Create. Connect.
                  </h2>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                      <div className="otp-input-fields">
                        {otp.map((value, index) => (
                          <input
                            key={index}
                            type="text"
                            className={`otp__digit otp__field__${index + 1}`}
                            value={value}
                            maxLength={1}
                            onChange={(e) => handleOtpChange(e, index)}
                          />
                        ))}
                      </div>
                      <div className="otp-resend">
                        Didn't get the OTP?{" "}
                        <Link to="/resend-otp" className="btn-link">
                          Resend OTP
                        </Link>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 mt-5">
                      {/* Submit OTP Button */}
                      <button
                        type="button"
                        className="btn btn-outline-success btn-lg"
                        onClick={handleSubmitOtp}
                        disabled={isLoading} // Disable if loading
                      >
                        {isLoading ? "Loading..." : "Validate and Log in"}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 footer">
                  <div className="sign-link mt-auto">
                    <Link to="/login" className="btn-link">
                      Log In with Email
                    </Link>
                  </div>
                  <div className="signup-instruction">
                    By continuing you agree to our terms and conditions.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileOtp;

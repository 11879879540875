import React from "react";
import "./PathtoExcellenceHome.scss";
import arrow from "../../assets/arrow_left.png";
import mainimg from "../../assets/main-img.png";
import signup from "../../assets/signup.png";
import profile from "../../assets/profile.png";
import search from "../../assets/search.png";
import apply from "../../assets/apply.png";
import collaborate from "../../assets/collaborate.png";
import trackpayment from "../../assets/trackpayment.png";
import report from "../../assets/report.png";
import engagement from "../../assets/engagement.png";
import like from "../../assets/like.png";
import thumb from "../../assets/thumb.png";
import msgicon from "../../assets/msgicon.png";

const StepBlock = ({ icon, title, mainImage, titleWidth }) => (
  <div>
    <div className="image-row">
      <div className="main-img-container">
        <img className="main-img" src={mainImage} alt={`${title} main`} />
        <img className="signup-img" src={icon} alt={`${title} icon`} />
      </div>
    </div>
    <div className="text-title" style={{ width: titleWidth }}>
      {title}
    </div>
  </div>
);

function PathtoExcellenceHome() {
  const steps = [
    {
      icon: signup,
      title: "Sign Up Log In",
      mainImage: mainimg,
      titleWidth: "100px",
    },
    {
      icon: profile,
      title: "Create Profile",
      mainImage: mainimg,
      titleWidth: "100px",
    },
    {
      icon: search,
      title: "Search for Campaigns or Influencers",
      mainImage: mainimg,
      titleWidth: "138px",
    },
    {
      icon: apply,
      title: "Create & Apply to Campaigns",
      mainImage: mainimg,
      titleWidth: "100px",
    },
    {
      icon: collaborate,
      title: "Collaboration",
      mainImage: mainimg,
      titleWidth: "100px",
    },
    {
      icon: trackpayment,
      title: "Track Performance & Payment",
      mainImage: mainimg,
      titleWidth: "100px",
    },
    {
      icon: report,
      title: "Report Results & Return",
      mainImage: mainimg,
      titleWidth: "100px",
    },
    {
      icon: engagement,
      title: "Continued Engagement",
      mainImage: mainimg,
      titleWidth: "100px",
    },
  ];

  return (
    <>
      <div className="container-Path-to-Excellence">
        {/* Heading Section */}
        <div className="heading">
          <div className="">
            <div className="heading-text mb-4">Path to Excellence</div>
          </div>
          <p className="mb-5">
            InSocial Orbit simplifies collaboration between brands and
            influencers, making it easy to create, connect, and succeed. Here’s
            a stepbystep look at the journey:
          </p>
        </div>

        {/* Image Row Section */}

        <div className="design-images img-fluid">
          {steps.map((step, index) => (
            <React.Fragment key={index}>
              {index !== 0 && (
                <img className="arrow-img" src={arrow} alt="arrow" />
              )}
              <StepBlock
                icon={step.icon}
                title={step.title}
                mainImage={step.mainImage}
                titleWidth={step.titleWidth} // passing titleWidth as a prop
              />
            </React.Fragment>
          ))}
        </div>

        {/* <div className="design-images img-fluid">
          <div
            className="design-images-row"
            style={
              {
                // display: "flex",
                // justifyContent: "space-around",
                // marginRight: " 5%",
              }
            }
          >
            <img className="arrow-img" src={arrow} alt="arrow" />
            <div>
              <div className="image-row">
                <div className="main-img-container">
                  <img className="main-img" src={mainimg} alt="main-img" />
                  <img className="signup-img" src={signup} alt="signup" />
                </div>
              </div>
              <div className="text-title">Sign Up Log In</div>
            </div>
            <img className="arrow-img" src={arrow} alt="arrow" />
            <div>
              <div className="image-row">
                <div className="main-img-container">
                  <img className="main-img" src={mainimg} alt="main-img" />
                  <img className="signup-img" src={profile} alt="profile" />
                </div>
              </div>
              <div className="text-title">Create Profile</div>
            </div>
            <img className="arrow-img" src={arrow} alt="arrow" />
            <div>
              <div className="image-row">
                <div className="main-img-container">
                  <img className="main-img" src={mainimg} alt="main-img" />
                  <img className="signup-img" src={search} alt="search" />
                </div>
              </div>
              <div className="text-title">
                Search for Campaigns or Influencers
              </div>
            </div>
            <img className="arrow-img" src={arrow} alt="arrow" />
            <div>
              <div className="image-row">
                <div className="main-img-container">
                  <img className="main-img" src={mainimg} alt="main-img" />
                  <img className="signup-img" src={apply} alt="apply" />
                </div>
              </div>
              <div className="text-title">Create & Apply to Campaigns</div>
            </div>
            <img className="arrow-img" src={arrow} alt="arrow" />
            <div>
              <div className="image-row">
                <div className="main-img-container">
                  <img className="main-img" src={mainimg} alt="main-img" />
                  <img
                    className="signup-img"
                    src={collaborate}
                    alt="collaborate"
                  />
                </div>
              </div>
              <div className="text-title">Collaboration</div>
            </div>
            <img className="arrow-img" src={arrow} alt="arrow" />
            <div>
              <div className="image-row">
                <div className="main-img-container">
                  <img className="main-img" src={mainimg} alt="main-img" />
                  <img
                    className="signup-img"
                    src={trackpayment}
                    alt="trackpayment"
                  />
                </div>
              </div>
              <div className="text-title">Track Performance & Payment</div>
            </div>
            <img className="arrow-img" src={arrow} alt="arrow" />
            <div>
              <div className="image-row">
                <div className="main-img-container">
                  <img className="main-img" src={mainimg} alt="main-img" />
                  <img className="signup-img" src={report} alt="report" />
                </div>
              </div>
              <div className="text-title">Report Results & Return</div>
            </div>
            <img className="arrow-img" src={arrow} alt="arrow" />
            <div className="last-img">
              <div className="image-row">
                <div className="main-img-container">
                  <img className="main-img" src={mainimg} alt="main-img" />
                  <img
                    className="signup-img"
                    src={engagement}
                    alt="engagement"
                  />
                </div>
              </div>
              <div className="text-title">Continued Engagement</div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default PathtoExcellenceHome;

import React, { useCallback, useEffect, useState } from "react";
import "./InfluencerDashboard.scss";
import SearchIcon from "../assets/Search.svg";
import Heart from "../assets/greenHeart.png";
import Liked from "../assets/greenHeart.png";
import Unliked from "../assets/tableFavouriteHeart.png";
import People from "../assets/People.svg";
import Sort from "../assets/Sort.svg";
import Grid from "../assets/Grid.svg";
import ListIcon from "../assets/List.svg";
import Instagram from "../assets/Instagram.svg";
import Facebook from "../assets/Facebook.svg";
import YouTube from "../assets/YouTube.svg";
import User from "../assets/User.png";
import Vector from "../assets/Vector.svg";
import Profile_Photo_Rec from "../assets/Profile_Photo_Rec.png";
import Verify from "../assets/Verify.svg";
import Important from "../assets/Important.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllInfluencers,
  getFavoriteInfluencers,
  getSearchedInfluencers,
  updateInfluencerLikes,
} from "../../actions/influencersAction";
import { Popover } from "@mui/material";
import MuiLoader from "../layout/Loader/MuiLoader";
import axios from "axios";
import _ from "lodash";
import tableimg from '../assets/tableimg.png'

const format = (dateString) => {
  if (!dateString) {
    return "--";
  }

  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const year = String(date.getFullYear()).slice(2); // Get last two digits of the year
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${day}-${month}-${year} ${hours}:${minutes}`;
};

const formatPhoneNumber = (number) => {
  if (!number) return "";

  // Ensure the number is a string
  const numberString = number.toString();

  // Format as "+<Country Code> <First 5 Digits> <Last 5 Digits>"
  const countryCode = numberString.slice(0, 2); // e.g., +91
  const firstPart = numberString.slice(3, 8); // First 5 digits
  const secondPart = numberString.slice(8); // Last 5 digits

  return `+${countryCode} ${firstPart} ${secondPart}`;
};

function InfluencerDashboard() {
  const { influencers, loading } = useSelector((state) => state?.influencers);
  const [view, setView] = useState(true);
  const [filterPopUp, setFilterPopUp] = useState(null);
  const [followersPopUp, setFollowersPopUp] = useState(null);
  const dispatch = useDispatch();
  const [currentFilter, setCurrentFilter] = useState("Engagement");
  const [selectedOptions, setSelectedOptions] = useState({});
  const [filterOptionsData, setFilterOptionsData] = useState({});
  const [isFetchingFavorites, setIsFetchingFavorites] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [searchItem, setSearchTerm] = useState("");
  const [sortParams, setSortParams] = useState({
    isfollowerssortedasc: false,
    isfollowerssorteddesc: false,
    isengagementsortedasc: false,
    isengagementsorteddesc: false,
  });
  const [activeTab, setActiveTab] = useState("brand");

  const { user } = useSelector((state) => state.user);
  let data = useSelector((state) => state.user);
  const columnWidths =
    user.role === "admin"
      ? [60, 100, 60, 60, 100, 60, 60, 60, 60]
      : [40, 40, 15, 45, 35, 20, 25, 50, 10];

  useEffect(() => {
    if (
      (influencers?.length > 0 && activeTab === "influencer") ||
      user?.role === "brand"
    ) {
      setDataList(influencers);
    }
  }, [influencers]);

  const filteroptions = {
    Engagement: [
      { label: "2% - 4%", value: "2-4" },
      { label: "5% - 7%", value: "5-7" },
      { label: "8% - 10%", value: "8-10" },
      { label: "11% - 13%", value: "11-13" },
      { label: "14% - 16%", value: "14-16" },
      { label: "16% +", value: "16+" },
    ],
    Reach: [
      { label: "O - 100K", value: "0-100000" },
      { label: "100K - 200K", value: "100000-200000" },
      { label: "200K - 300K", value: "200000-300000" },
      { label: "300K - 400K", value: "300000-400000" },
      { label: "400K - 500K", value: "400000-500000" },
      { label: "500K - 700K", value: "500000-700000" },
      { label: "700K - 900K", value: "700000-900000" },
      { label: "900K +", value: "900000 +" },
    ],
    Followers: [
      { label: "10K - 100K", value: "10000-100000" },
      { label: "100K - 300K", value: "100000-300000" },
      { label: "300K - 500K", value: "300000-500000" },
      { label: "500K - 700K", value: "500000-700000" },
      { label: "700K - 900K", value: "700000-900000" },
      { label: "900K - 1M", value: "900000-1000000" },
      { label: "1M - 3M", value: "1000000-3000000" },
      { label: "3M +", value: "3000000 +" },
    ],
    Genre: [
      { label: "Art & Photography", value: ["Art", "Photography"] },
      {
        label: "Business & Entrepreneurship",
        value: ["Business", "Entrepreneurship"],
      },
      { label: "Entertainment", value: "Entertainment" },
      {
        label: "Fitness, Fashion & Beauty",
        value: ["Fitness", "Fashion", "Beauty"],
      },
      { label: "Lifestyle & Travel", value: ["Lifestyle", "Travel"] },
      { label: "Tech & Gaming", value: ["Tech", "Gaming"] },
      { label: "Others", value: "Others" },
    ],
    Age: [
      { label: "13 - 17", value: "13-17" },
      { label: "18 - 24", value: "18-24" },
      { label: "25 - 34", value: "25-34" },
      { label: "35 - 44", value: "35-44" },
      { label: "45 - 54", value: "45-54" },
      { label: "55 - 64", value: "55-64" },
      { label: "65 +", value: "65+" },
    ],
    City: [
      { label: "Mumbai", value: "Mumbai" },
      { label: "Delhi", value: "Delhi" },
      { label: "Bangalore", value: "Bangalore" },
      { label: "Chennai", value: "Chennai" },
      { label: "Kolkata", value: "Kolkata" },
      { label: "Jaipur", value: "Jaipur" },
      { label: "Hyderabad", value: "Hyderabad" },
      { label: "Other Cities", value: "Other Cities" },
    ],
  };

  const { isAuthenticated } = useSelector((state) => state.user);
  const navigate = useNavigate();

  function getBrandData(role = activeTab) {
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/by-role`,
        { role: role },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        console.log(res, "useruseruser");
        setDataList(res.data.users);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    setInterval(() => {
      if (!isAuthenticated) {
        navigate("/login");
      }
    }, 3000);

    if (user.role === "admin") {
      getBrandData();
    }
  }, []);

  const resetState = () => {
    setView(true);
    setFilterPopUp(null);
    setFollowersPopUp(null);
    setCurrentFilter("Engagement");
    setSelectedOptions({});
    setFilterOptionsData({});
    setIsFetchingFavorites(false);
    setDataList([]);
    setSearchTerm("");
    setSortParams({
      isfollowerssortedasc: false,
      isfollowerssorteddesc: false,
      isengagementsortedasc: false,
      isengagementsorteddesc: false,
    });
  };

  const formatNumber = (number) => {
    if (number < 1000) {
      return number.toString();
    } else if (number < 1000000) {
      const formatted = (number / 1000).toFixed(1);
      return formatted.endsWith(".0")
        ? `${Math.floor(number / 1000)}k`
        : `${formatted}k`;
    } else if (number < 1000000000) {
      const formatted = (number / 1000000).toFixed(1);
      return formatted.endsWith(".0")
        ? `${Math.floor(number / 1000000)}M`
        : `${formatted}M`;
    } else {
      const formatted = (number / 1000000000).toFixed(1);
      return formatted.endsWith(".0")
        ? `${Math.floor(number / 1000000000)}B`
        : `${formatted}B`;
    }
  };

  function handleClearAll() {
    setSelectedOptions({});
    setFilterOptionsData({});
    dispatch(getAllInfluencers());
  }

  function handleButtonClick(event) {
    const button = event.currentTarget;

    const buttons = document.querySelectorAll(".custom-button");
    buttons.forEach((btn) => {
      btn.classList.remove("active");
    });

    button.classList.add("active");

    const selectedFilter = event.currentTarget.textContent;
    setCurrentFilter(selectedFilter);
  }

  const handleLikeToggle = (InfluencerID) => {
    dispatch(updateInfluencerLikes({ influencer_id: InfluencerID }));
  };

  function handleFavoriteClick(event) {
    const button = event.currentTarget;
    button.classList.toggle("active");
    setIsFetchingFavorites((prev) => !prev);
    // if (isFetchingFavorites) {
    //   dispatch(getAllInfluencers());
    // } else {
    //   dispatch(getFavoriteInfluencers());
    // }
  }

  function handleCheckboxChange(option) {
    setSelectedOptions((prevSelectedOptions) => {
      const updatedOptions = {
        ...prevSelectedOptions,
        [currentFilter]: {
          ...(prevSelectedOptions[currentFilter] || {}),
          [option]: !prevSelectedOptions[currentFilter]?.[option],
        },
      };
      return updatedOptions; // Return the updated state
    });

    // Update filterOptionsData state for storing checked options
    setFilterOptionsData((prevFilterOptionsData) => {
      const currentOptions = prevFilterOptionsData[currentFilter] || [];

      if (currentFilter === "Genre") {
        const optionData = filteroptions[currentFilter].find(
          (genre) => genre.value === option
        );

        const updatedOptions = {
          ...prevFilterOptionsData,
          [currentFilter]: selectedOptions[currentFilter]?.[option]
            ? currentOptions.filter((item) => !optionData.value.includes(item))
            : [...currentOptions, ...optionData.value],
        };

        console.log("FilteredOptionsgenre", updatedOptions);
        return updatedOptions;
      } else {
        // Handle other filters
        const updatedOptions = {
          ...prevFilterOptionsData,
          [currentFilter]: selectedOptions[currentFilter]?.[option]
            ? currentOptions.filter((item) => item !== option)
            : [...currentOptions, option],
        };
        console.log("FilteredOptions", updatedOptions);
        return updatedOptions; // Return the updated state
      }

      // const updatedOptions = {
      //   ...prevFilterOptionsData,
      //   [currentFilter]: selectedOptions[currentFilter]?.[option]
      //     ? currentOptions.filter((item) => item !== option)
      //     : [...currentOptions, option],
    });
  }

  const handleClick = (event) => {
    setFilterPopUp(event.currentTarget);
  };

  const open = Boolean(filterPopUp);
  const id = open ? "simple-popover" : undefined;

  const filterClose = () => {
    setFilterPopUp(null);
  };

  const followersPopUpopen = (event) => {
    setFollowersPopUp(event.currentTarget);
  };

  const followersPopUpclose = () => {
    setFollowersPopUp(null);
  };

  const openfollowersPopUp = Boolean(followersPopUp);

  const toggleView = async () => {
    await setView((prevState) => !prevState);
    localStorage.setItem("veiw", view);
  };

  function getGenderRatio(number, total) {
    return Math.trunc((number / total) * 100);
  }

  useEffect(() => {
    if (user.role !== "admin") {
      const queryParams = { ...sortParams, ...filterOptionsData };
      dispatch(getAllInfluencers(queryParams));
    }
  }, [dispatch, sortParams]);

  const handleSortClick = (field) => {
    // Determine the current sort order for the clicked field
    const currentAsc = sortParams[`is${field}SortedAsc`];
    const currentDesc = sortParams[`is${field}SortedDesc`];

    // Update the sortParams state based on the current sort order
    if (!currentAsc && !currentDesc) {
      // If neither asc nor desc is set, set asc
      setSortParams({
        [`is${field}SortedAsc`]: true,
        [`is${field}SortedDesc`]: false,
        // ... update other queryParams accordingly
      });
    } else if (currentAsc) {
      // If currently ascending, toggle to descending
      setSortParams({
        [`is${field}SortedAsc`]: false,
        [`is${field}SortedDesc`]: true,
        // ... update other queryParams accordingly
      });
    } else {
      // If currently descending, reset to default
      setSortParams({
        [`is${field}SortedAsc`]: false,
        [`is${field}SortedDesc`]: false,
        // ... update other queryParams accordingly
      });
    }
  };

  // console.log("filterOptionsData", filterOptionsData);

  const handleApplyFilters = () => {
    dispatch(getAllInfluencers(filterOptionsData));
    filterClose();
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value;

    if (searchTerm.trim() === "") {
      dispatch(getAllInfluencers());
    } else {
      // If there is a search term, dispatch an action to search influencers
      dispatch(getSearchedInfluencers(searchTerm));
    }
  };

  // Fetch users from API
  const fetchUsers = async (term, role) => {
    debugger;
    try {
      // setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/search-user`,
        {
          params: { search: term, role },
          // },
          // {
          // headers: {
          //   "Content-Type": "application/json",
          // },
          withCredentials: true,
        }
      );
      setDataList(response.data.users);
      // setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      // setLoading(false);
    }
  };

  // Debounced function to handle API calls
  const debouncedFetchUsers = useCallback(
    _.debounce((term, tab) => fetchUsers(term, tab), 500),
    []
  );

  // Handle input changes
  const handleSearchUser = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedFetchUsers(term, activeTab); // Trigger debounced search
  };

  function handleProfileClick() {
    navigate("/influencerstats");
  }
  return (
    <>
      <div className="container">
        <div className="InfluencerDashboard">
          <div className="filters">
            <div className="heading">
              {user.role === "admin" ? (
                <div className="tabs-container">
                  {["brand", "influencer"].map((tab) => (
                    <button
                      key={tab}
                      className={`tab ${activeTab === tab ? "active" : ""}`}
                      onClick={async () => {
                        // if (tab === "brand") {
                        //   getBrandData(tab);
                        // } else {
                        await resetState();
                        getBrandData(tab);
                        //   // dispatch(getAllInfluencers());
                        //   axios
                        //     .get(
                        //       `${process.env.REACT_APP_BACKEND_URL}/api/v1/influencers`,
                        //       {
                        //         withCredentials: true,
                        //       }
                        //     )
                        //     .then((res) => setDataList(res.data.influencers))
                        //     .catch((err) => console.log(err));
                        // }
                        setActiveTab(tab);
                      }}
                    >
                      {tab}
                    </button>
                  ))}
                  <div
                    className="underline"
                    style={{
                      width: activeTab === "brand" ? "80px" : "115px", // Adjust width based on tab text length
                      transform:
                        activeTab === "brand"
                          ? "translateX(0)"
                          : "translateX(96px)", // Adjust translation for tab position
                    }}
                  />
                </div>
              ) : (
                <span>Influencers</span>
              )}
            </div>
            <div className="all-filters">
              <div className="search">
                <img
                  src={SearchIcon}
                  alt="Search Icon"
                  className="search-icon"
                />
                <input
                  className="search-bar"
                  type="text"
                  placeholder="Search"
                  value={searchItem || ""}
                  onChange={(e) =>
                    user.role === "admin"
                      ? handleSearchUser(e)
                      : handleSearch(e)
                  }
                />
              </div>
              <div className="filters-buttons">
                {user.role !== "admin" && (
                  <div
                    className="filters-button favorites"
                    onClick={handleFavoriteClick}
                  >
                    <p className="filter-text">Favorites</p>
                    <img src={Heart} alt="Heart Icon" className="heart-icon" />
                  </div>
                )}
                <div className="filters-button" onClick={handleClick}>
                  <p className="filter-text1">Filters</p>
                  <img src={Vector} alt="Filter Icon" className="filter-icon" />
                </div>
                {user.role !== "admin" && (
                  <div className="view" onClick={toggleView}>
                    {view ? (
                      <img className="viewIcon" src={Grid} alt="View Icon" />
                    ) : (
                      <img
                        className="viewIcon"
                        src={ListIcon}
                        alt="Grid Icon"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>

            <Popover
              id={id}
              anchorEl={filterPopUp}
              open={open}
              onClose={filterClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div className="filter-container">
                <div className="left-container">
                  <div className="filter-title">
                    <span>Filters</span>
                  </div>
                  <div className="filter-buttons">
                    {Object.keys(filteroptions).map((button, index) => (
                      <button
                        key={index}
                        onClick={handleButtonClick}
                        className={`custom-button ${
                          button === currentFilter ? "active" : ""
                        }`}
                      >
                        {button.label || button}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="right-container">
                  <div className="search">
                    <span
                      onClick={handleClearAll}
                      style={{ cursor: "pointer" }}
                      className="clear"
                    >
                      Clear all
                    </span>
                    {/* <img
                        src={SearchIcon}
                        alt="Search Icon"
                        className="search-icon"
                      />
                      <input
                        className="search-bar"
                        type="text"
                        placeholder="Search"
                      /> */}
                  </div>
                  <div className="options-container">
                    {filteroptions[currentFilter].map((filter, index) => (
                      <div key={index} className="filter-option">
                        {/* <div > */}
                        {/* <label className="checkbox">
                              <input
                                type="checkbox"
                                checked={
                                  selectedOptions[currentFilter]?.[filter.value] ||
                                  false
                                }
                                onChange={() => handleCheckboxChange(filter.value)}
                              />
                              <div className="check"></div>
                            </label>
                            {filter.label} */}

                        <label className="checkbox">
                          <input
                            type="checkbox"
                            className="checkbox"
                            checked={
                              selectedOptions[currentFilter]?.[filter.value] ||
                              false
                            }
                            onChange={() => handleCheckboxChange(filter.value)}
                          />
                          <div className="checkmar"></div>
                          {filter.label}
                        </label>
                        {/* </div> */}
                      </div>
                    ))}
                  </div>

                  <div className="bottom-container">
                    {/* <div className="results"> */}
                    <span>results {influencers?.length + 1} influencers</span>
                    {/* </div> */}
                    <button
                      className="apply-button"
                      onClick={handleApplyFilters}
                    >
                      Apply Filter
                    </button>
                  </div>
                </div>
              </div>
            </Popover>
          </div>
          {loading ? (
            <MuiLoader />
          ) : (
            <div className="influencers-list">
              {view ? (
                // TABLEEEEEEE
                <div className="table-container">
                  <table className="custom-table">
                    <thead>
                      <tr className="">
                        <th
                          style={{
                            width: `${
                              user.role === "admin"
                                ? columnWidths[0] + 20
                                : columnWidths[0]
                            }%`,
                          }}
                        >
                          {user.role === "admin" ? "Brand" : "Influencer"}
                        </th>
                        <th
                          style={{
                            width: `${
                              user.role === "admin"
                                ? columnWidths[1] + 50
                                : columnWidths[1]
                            }%`,
                          }}
                        >
                          Genre
                        </th>

                        {user.role === "admin" && (
                          <th style={{ width: `${columnWidths[1] + 40}%` }}>
                            Email
                          </th>
                        )}

                        {user.role === "admin" && (
                          <th style={{ width: `${columnWidths[1] + 20}%` }}>
                            Contact No
                          </th>
                        )}

                        {user.role !== "admin" && (
                          <>
                            <th style={{ width: `${columnWidths[2]}%` }}>
                              CPP{" "}
                            </th>
                            <th
                              style={{ width: `${columnWidths[3]}%` }}
                              onClick={() => handleSortClick("Engagement")}
                            >
                              Engagement %{" "}
                              <img
                                src={Sort}
                                alt="Sort Icon"
                                className="sort-icon"
                              />
                            </th>
                            <th
                              style={{ width: `${columnWidths[4]}%` }}
                              onClick={() => handleSortClick("Followers")}
                            >
                              Followers{" "}
                              <img
                                src={Sort}
                                alt="Sort Icon"
                                className="sort-icon"
                              />
                            </th>
                            <th style={{ width: `${columnWidths[5]}%` }}>
                              Reach{" "}
                            </th>

                            <th
                              style={{ width: `${columnWidths[6]}%` }}
                              className="audience-gender"
                            >
                              Audience Gender{" "}
                            </th>
                          </>
                        )}
                        {user.role === "admin" && (
                          <th style={{ width: `${columnWidths[7]}%` }}>
                            State
                          </th>
                        )}
                        {user.role === "admin" && (
                          <th style={{ width: `${columnWidths[7]}%` }}>
                            Login Time
                          </th>
                        )}
                        {user.role !== "admin" && (
                          <th style={{ width: `${columnWidths[8]}%` }}></th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {dataList?.map((data, index) =>
                        (isFetchingFavorites && data.isliked) ||
                        !isFetchingFavorites ? (
                          <tr key={index}>
                            <td
                              className="profile-info"
                              onClick={handleProfileClick}
                            >
                              <img
                                className="profile-image"
                                // style={{
                                //   backgroundImage: `url('${
                                //     user.role === "admin"
                                //       ? data?.user_photo?.toString()
                                //       : data?.influencer_profile_url
                                //   }')`,
                                // }}
                                src={tableimg}
                                alt="no profile picture"
                              />
                              <span className="influencer-name">
                                {user.role === "admin"
                                  ? data?.first_name + " " + data?.last_name
                                  : data?.influencer_name}
                              </span>
                            </td>
                            <td className="genre" onClick={handleProfileClick}>
                              {user.role === "admin" ? data?.bio : data?.genre}
                            </td>
                            {user.role === "admin" && (
                              <td
                                className="genre"
                                onClick={handleProfileClick}
                              >
                                {data.email}
                              </td>
                            )}

                            {user.role === "admin" && (
                              <td
                                className="genre"
                                onClick={handleProfileClick}
                              >
                                {formatPhoneNumber(data.phone_no) ||
                                  "No Number"}
                              </td>
                            )}
                            {user.role !== "admin" && (
                              <>
                                <td
                                  className="cpp"
                                  onClick={handleProfileClick}
                                >
                                  {data?.cpp}
                                </td>
                                <td
                                  className="engagement"
                                  onClick={handleProfileClick}
                                >{`${data?.engagement} %`}</td>
                                <td
                                  className="followers"
                                  onMouseEnter={followersPopUpopen}
                                  onMouseLeave={followersPopUpclose}
                                  onClick={handleProfileClick}
                                >
                                  <div className="followers-image">
                                    <img src={People} alt="People Icon" />
                                    <span>{formatNumber(data?.followers)}</span>
                                  </div>
                                </td>
                                <td
                                  className="reach"
                                  onClick={handleProfileClick}
                                >
                                  {formatNumber(data?.reach)}
                                </td>

                                <Popover
                                  id={id}
                                  sx={{
                                    pointerEvents: "none",
                                    boxShadow: 1,
                                  }}
                                  elevation={0}
                                  open={openfollowersPopUp}
                                  anchorEl={followersPopUp}
                                  anchorOrigin={{
                                    vertical: "center",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "center",
                                    horizontal: "left",
                                  }}
                                  onClose={followersPopUpclose}
                                  disableRestoreFocus
                                  PaperProps={{
                                    style: {
                                      width: "96px",
                                      height: "103px",
                                      backgroundColor: "#F8E7ED",
                                      color: "rgba(0, 0, 0, 0.8)",
                                      borderRadius: "10px",
                                    },
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    padding: "10px",
                                  }}
                                >
                                  <div className="followers-popover">
                                    <div className="instagram">
                                      <img
                                        src={Instagram}
                                        alt="Instagram Icon"
                                      />
                                      <span> 60k </span>
                                    </div>
                                    <div className="youtube">
                                      <img src={YouTube} alt="YouTube Icon" />
                                      <span> 60k </span>
                                    </div>
                                    <div className="facebook">
                                      <img src={Facebook} alt="Facebook Icon" />
                                      <span> 60k </span>
                                    </div>
                                  </div>
                                </Popover>
                                <td onClick={handleProfileClick}>
                                  <div className="audience-info">
                                    <span className="gender-percentages">
                                      Male{" "}
                                      <span className="male">
                                        {getGenderRatio(
                                          data?.male,
                                          data?.followers
                                        )}
                                        %
                                      </span>
                                    </span>
                                    <span className="gender-percentages">
                                      Female{" "}
                                      <span className="female">
                                        {getGenderRatio(
                                          data?.female,
                                          data?.followers
                                        )}
                                        %
                                      </span>
                                    </span>
                                    <span className="gender-percentages">
                                      Other{" "}
                                      <span className="other">
                                        {getGenderRatio(
                                          data?.other,
                                          data?.followers
                                        )}
                                        %
                                      </span>
                                    </span>
                                  </div>
                                </td>
                              </>
                            )}
                            {user.role === "admin" && (
                              <td>
                                {user.role === "admin"
                                  ? // format(data?.registered_at) +
                                    //   " - " +
                                    data?.state
                                  : ""}
                              </td>
                            )}

                            {user.role === "admin" && (
                              <td>
                                {user.role === "admin"
                                  ? // format(data?.registered_at) +
                                    //   " - " +
                                    format(data?.last_login_timestamp)
                                  : ""}
                              </td>
                            )}
                            {user.role !== "admin" && (
                              <td
                                className="like-icon-column"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLikeToggle(data?.influencer_id);
                                }}
                                width="1%"
                              >
                                <img
                                  src={data?.isliked ? Liked : Unliked}
                                  alt="Like Icon"
                                  className="like-icon"
                                />
                              </td>
                            )}
                          </tr>
                        ) : (
                          <></>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="row">
                  {influencers?.map((influencer, index) =>
                    (isFetchingFavorites && influencer.isliked) ||
                    !isFetchingFavorites ? (
                      <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-4">
                        <div className="profile-card">
                          <div className="profile-card-info">
                            <img
                              src={influencer?.isliked ? Liked : Unliked}
                              alt="Like Icon"
                              className="like-icon like-icon-column"
                              onClick={(e) => {
                                e.preventDefault();
                                handleLikeToggle(influencer?.influencer_id);
                              }}
                              width="1%"
                            />
                            <img
                              // src={influencer?.influencer_profile_url}
                              src={tableimg}
                              className="profile-photo-rec"
                              alt="Profile Photo"
                            />
                            <div className="genuine-followers">
                              <div className="circle">
                                <p className="circle-text">80</p>
                                <span className="circle-persentage">%</span>
                                <span className="genuine-text">Genuine</span>
                              </div>
                            </div>
                          </div>

                          <div
                            className="social-info"
                            onClick={handleProfileClick}
                          >
                            <div className="account-info">
                              <span className="influencer-name">
                                {influencer?.influencer_name}
                              </span>
                              <img
                                className="verify-icon"
                                src={Verify}
                                alt="Verify Icon"
                              />
                              <span className="photography">Photography</span>
                            </div>

                            <div className="social-presence">
                              <div className="heading">
                                <span>Social Presence</span>
                                <img
                                  className="important-icon"
                                  src={Important}
                                  alt="Important Icon"
                                />
                              </div>
                              <div className="engagement-ratio">
                                <div className="metric">
                                  <span className="social-ratio">Reach</span>
                                  <br />
                                  <span className="followers-count">
                                    {formatNumber(influencer?.reach)}
                                  </span>
                                </div>
                                <div className="metric">
                                  <span className="social-ratio">Engage</span>
                                  <br />
                                  <span className="followers-count">
                                    {influencer?.engagement}%
                                  </span>
                                </div>
                                <div className="metric">
                                  <span className="social-ratio">CPP</span>
                                  <br />
                                  <span className="followers-count">$5K</span>
                                </div>
                              </div>
                              <div className="line"></div>
                            </div>

                            <div className="social-media">
                              <div className="heading">
                                <span>Followers</span>
                              </div>
                              <div className="social-media-links">
                                <div>
                                  <img src={Instagram} alt="Instagram Icon" />
                                  <span>120 K</span>
                                </div>
                                <div>
                                  <img src={YouTube} alt="YouTube Icon" />
                                  <span>80 K</span>
                                </div>
                                <div>
                                  <img src={Facebook} alt="Facebook Icon" />
                                  <span>160 K</span>
                                </div>
                              </div>
                            </div>
                            <div className="line"></div>

                            <div className="audience">
                              <div className="heading">
                                <span>Audience</span>
                              </div>
                              <div className="genders">
                                <div className="gender">
                                  <span className="audience">Male</span>
                                  <span className="audience-percentage">
                                    {getGenderRatio(
                                      influencer?.male,
                                      influencer?.followers
                                    )}
                                    %
                                  </span>
                                </div>
                                <div className="gender">
                                  <span className="audience">Female</span>
                                  <span className="audience-percentage">
                                    {getGenderRatio(
                                      influencer?.female,
                                      influencer?.followers
                                    )}
                                    %
                                  </span>
                                </div>
                                <div className="gender">
                                  <span className="audience">Others</span>
                                  <span className="audience-percentage">
                                    {getGenderRatio(
                                      influencer?.other,
                                      influencer?.followers
                                    )}
                                    %
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default InfluencerDashboard;

import React from "react";
import "./ContactUs.scss";
import Navbar from "../LandingComponents/Navbar/Navbar";
import Footer from "../LandingComponents/Footer/Footer";

function ContactUs() {
  return (
    <>
      <Navbar />
      <div className="contact-container">
        {/* Hero Section */}
        <div className="hero-section d-flex align-items-center justify-content-center">
          <div className="text-center">
            <h1 className="hero-title mt-auto">
              Where Connections Spark and Collaborations Begin
            </h1>
            <span className="text-light">
              <hr />
            </span>
          </div>
        </div>

        {/* Main Contact Section */}
        <div className="contact-content container p-5">
          <div className="row g-5">
            {/* Left Column */}
            <div className="col-lg-8 col-md-8 col-xs-12 col-sm-6">
              <h3 className="section-title mb-3">
                We’d Love to Hear From You!
              </h3>
              <p className="description">
                Whether you have a question, a project in mind, or just want to
                say hello, we're here for you. Reach out to us and let's start
                something amazing together!
              </p>
              <h5 className="get-in-touch">Get in Touch</h5>
              <ul className="contact-info">
                <li>
                  <span className="contact-label">Email:</span>{" "}
                  support@insocialorbit.com
                </li>
                <li>
                  <span className="contact-label">Phone:</span> +91 00000 00000
                </li>
                <li>
                  <span className="contact-label">Address:</span> 6, Rajpath
                  Rangoli Rd, PRL Colony, Bodakdev, Ahmedabad, Gujarat 380059
                </li>
                <li>
                  <span className="contact-label">Hours:</span> Open & Close
                  Time
                </li>
              </ul>
            </div>

            {/* Right Column */}
            <div className="col-lg-4 col-md-4 col-xs-12 col-sm-6">
              <div className="map-container">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7343.33802801375!2d72.48093469357907!3d23.035922000000024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9bfa4c41c8d3%3A0x8f07c10faafa4242!2sInSocial%20Orbit%20Pvt.Ltd!5e0!3m2!1sen!2sin!4v1736161027572!5m2!1sen!2sin"
                  width="100%"
                  height="300px"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Google Map Location"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        {/* Form Section */}
        <div className="form-section  p-5">
          <div className="container">
            <div className="form-title">
              <div className=" title text-left mb-4">Connect with us</div>
            </div>
            <form className="connect-form">
              <div className="row g-3">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter your Name"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label htmlFor="email" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter your Name"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label htmlFor="number" className="form-label">
                    Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="number"
                    placeholder="Enter your Number"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label htmlFor="number2" className="form-label">
                    Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="number2"
                    placeholder="Enter your Number"
                  />
                </div>
                <div className="col-md-3 col-sm-12 col-xs-12">
                  <label className="form-label">You are ?</label>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="role"
                        id="brand"
                        value="brand"
                      />
                      <label className="form-check-label" htmlFor="brand">
                        Brand
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="role"
                        id="influencer"
                        value="influencer"
                      />
                      <label className="form-check-label" htmlFor="influencer">
                        Influencer
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12 message">
                  <label htmlFor="message" className="form-label">
                    Your Message
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    rows="5"
                    placeholder="Your Message"
                  ></textarea>
                </div>
                <div className="col-12 text-center mt-5">
                  <button type="submit" className="btn btn-success px-4">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;

import React from 'react'
import './banner.scss'

function Banner() {
    return (    
        <div>
            <div className="about-hero-section">
                <div className="overlay">
                    <h1>Connecting Brands & Influencers Seamlessly</h1>
                    <hr className="underline" />
                </div>
            </div>

        </div>
    )
}

export default Banner

import React from "react";
import "./privacyPolicy.scss";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import NavbarCompo from "../Navbar/Navbar";

const PrivacyPolicy = () => {
  return (
    <>
      <NavbarCompo />
      <div className="row-nav-profile">
        <Navbar expand="lg">
          <Container className="">
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              className="ms-auto"
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Link to="/privacy-policy" className="nav-link">
                  Privacy Policy
                </Link>
                <Link to="#" className="nav-link">
                  Terms & Conditions
                </Link>
                <Link to="#" className="nav-link">
                  Copy Rights
                </Link>
                <Link to="#" className="nav-link">
                  User Agreement
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <Container fluid className="policy-container">
        <Row className="justify-content-start">
          <Col md={12} className="privacy-policy-content">
            <div className="main-policy">
              <div className="head-title">
                <div className="policy-heading">Privacy Policy</div>
              </div>
              <div className="policy-content">
                <p>
                  Welcome to InSocial Orbit, operated by{" "}
                  <span className="iso-title">
                    INSOCIAL ORBIT PRIVATE LIMITED ("we," "our," or "us").
                  </span>{" "}
                  Your privacy is important to us, and we are committed to
                  protecting the personal information you share with us. This
                  Privacy Policy explains how we collect, use, and safeguard
                  your data in compliance with applicable Indian laws.
                </p>
              </div>
            </div>

            {/* about-policy */}
            <div className="about-policy">
              <div className="head-title">
                <div className="about-heading">1. About Us</div>
              </div>
              <div className="about-content">
                <p>
                  InSocial Orbit is operated by{" "}
                  <span className="about-iso">
                    INSOCIAL ORBIT PRIVATE LIMITED
                  </span>
                  , with its registered office located at{" "}
                  <span className="address">
                    Floor A, Flat No. 903, Swati Chrysantha, near Sunrise
                    Cricket Ground, Ahmedabad-380058, Gujarat, India.
                  </span>
                </p>
                <span className="contact">
                  For any inquiries, you can reach us at +91 9426026611 or visit
                  our website at{" "}
                  <span className="website-link">
                    <Link
                      to="https://insocialorbit.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://insocialorbit.com
                    </Link>
                  </span>
                </span>
                <p>
                  We are dedicated to bridging the gap between brands and
                  influencers, making collaboration effortless through a
                  transparent, efficient, and inclusive approach. Born out of a
                  deep understanding of the challenges in media and influencer
                  marketing, InSocial Orbit empowers businesses and creators to
                  connect, grow, and achieve measurable outcomes. Brands can
                  discover perfectly matched influencers, execute impactful
                  campaigns, and monitor results seamlessly, while influencers
                  gain access to genuine opportunities. Guided by core values of
                  transparency, efficiency, inclusivity, and innovation,
                  InSocial Orbit is redefining influencer marketing by fostering
                  meaningful, trust-based partnerships.
                </p>
              </div>
            </div>

            {/* 2 - Information We Collect */}
            <div className="main-Information">
              <div className="head-title">
                <div className="Information-heading">
                  2 - Information We Collect
                </div>
              </div>
              <div className="Information-content">
                <p>
                  &bull; Personal Information
                  <span className="information-point">
                    - Name, email address, and phone number.
                  </span>
                </p>

                <p>
                  &bull; Payment Information
                  <span className="information-point">
                    - Financial details necessary for processing payments.
                  </span>
                </p>
                <p>
                  &bull; Technical Information{" "}
                  <span className="information-point">
                    - IP address, device information, and cookies.
                  </span>
                </p>
                <p>
                  &bull; Data from Social Media Platforms
                  <span className="information-point">
                    - Public metrics and engagement data from platforms like
                    YouTube, Facebook, Instagram, LinkedIn, and Snapchat.
                  </span>
                </p>
              </div>
            </div>

            {/* 3 - Purpose of Data Collection*/}
            <div className="main-Data-Collection">
              <div className="head-title">
                <div className="Data-Collection-heading">
                  3 - Purpose of Data Collection
                </div>
                <div className="Data-Collection-content">
                  We use the collected information to
                </div>
              </div>
              <div className="Data-Collection-content">
                <p>
                  &bull; Facilitate connections between influencers and brands.
                </p>

                <p>
                  &bull; Display metrics on our platform in the "Brands" and
                  "Influencers" sections.
                </p>
                <p>&bull; Process payments securely.</p>
                <p>
                  &bull; Analyse platform usage and improve user experience.
                </p>
              </div>
            </div>

            {/* 4 - Data Sharing */}
            <div className="main-policy">
              <div className="head-title">
                <div className="Data-Sharing-heading">4 - Data Sharing</div>
              </div>
              <div className="Data-Sharing-content">
                <p>
                  We prioritize your privacy and are committed to protecting
                  your personal information. In compliance with Indian data
                  protection laws, including the Information Technology
                  (Reasonable Security Practices and Procedures and Sensitive
                  Personal Data or Information) Rules, 2011, we do not share,
                  sell, or rent your data to third parties for commercial
                  purposes.
                </p>
                <p>
                  However, we may share your data in the following
                  circumstances, as permitted by Indian law
                </p>
              </div>
              {/* 4.1 */}
              <div className="head-title">
                <div className="child-Data-Sharing-heading">
                  <span className="child-titles">
                    {" "}
                    4.1 - Legal Obligations :{" "}
                  </span>
                  We may disclose your data if required by law or in response to
                  a valid legal request, such as a court order, government
                  authority, or law enforcement agency. This includes sharing
                  information to comply with applicable regulations, protect our
                  legal rights, or respond to requests from authorities.
                </div>
              </div>
              {/* 4.2 */}
              <div className="head-title">
                <div className="child-Data-Sharing-heading">
                  <span className="child-titles">
                    {" "}
                    4.2 - With Your Explicit Consent :{" "}
                  </span>
                  We will only share your personal information with third
                  parties after obtaining your explicit consent, where
                  necessary. For instance, if you engage with a service or
                  participate in promotions that involve third-party
                  partnerships, we will ask for your consent before sharing data
                  with them.
                </div>
              </div>
              {/* 4.3 */}
              <div className="head-title">
                <div className="child-Data-Sharing-heading">
                  <span className="child-titles">
                    {" "}
                    4.3 - Business Transactions :{" "}
                  </span>
                  In case of a merger, acquisition, or sale of company assets,
                  your personal data may be transferred as part of the
                  transaction. We will notify you if your data is part of such a
                  transfer, and ensure that the receiving party adheres to the
                  same level of privacy protection.
                </div>
              </div>
              {/* 4.4 */}
              <div className="head-title">
                <div className="child-Data-Sharing-heading">
                  <span className="child-titles">
                    {" "}
                    4.4 - Third-Party Service Providers :{" "}
                  </span>
                  We may share your data with trusted third-party service
                  providers who assist us in operating our website, conducting
                  business, or providing services to you. These providers are
                  required to handle your data in compliance with applicable
                  laws and maintain appropriate security measures.
                </div>
              </div>
              <div className="child-Data-Sharing-heading">
                We ensure that any third parties with whom we share your data
                are contractually obligated to protect it and use it solely for
                the purposes specified.
              </div>
            </div>

            {/* 5 - Cookies */}
            <div className="main-policy">
              <div className="head-title">
                <div className="Cookies-heading">5 - Cookies</div>
                <div className="Cookies-content">
                  We use the collected information to
                </div>
              </div>
              <div className="Cookies-content">
                <p>
                  &bull; Essential Cookies -
                  <span className="Cookies-point">
                    To enable core website functionality, such as navigating
                    pages or accessing secure areas of the website.{" "}
                  </span>
                </p>

                <p>
                  &bull; Performance and Analytics Cookies -
                  <span className="Cookies-point">
                    To understand how users interact with our website, measure
                    website performance, and improve user experience{" "}
                  </span>
                </p>
                <p>
                  &bull; Functional Cookies -
                  <span className="Cookies-point">
                    - IP address, device information, and cookies.
                  </span>
                </p>
                <p>
                  &bull; Data from Social Media Platforms
                  <span className="Cookies-point">
                    - Public metrics and engagement data from platforms like
                    YouTube, Facebook, Instagram, LinkedIn, and Snapchat.
                  </span>
                </p>
              </div>

              {/* 5.1 */}
              <div className="head-title">
                <div className="Cookies-child-heading">
                  <span className="Cookies-child-titles">
                    {" "}
                    5.1 - Cookie Types We Use{" "}
                  </span>
                </div>
                <div className="Cookies-content">
                  <p>
                    &bull; First Party Cookies -
                    <span className="Cookies-point">
                      Set by our website to provide services and remember your
                      preferences
                    </span>
                  </p>

                  <p>
                    &bull; Third-Party Cookies -
                    <span className="Cookies-point">
                      Set by third-party services, such as analytics providers
                      (e.g., Google Analytics) or embedded social media content.
                    </span>
                  </p>
                </div>
              </div>
              {/* 5.2 */}
              <div className="head-title">
                <div className="Cookies-child-heading">
                  <span className="Cookies-child-titles">
                    {" "}
                    5.2 - Your Rights and Choices Regarding Cookies{" "}
                  </span>
                </div>
                <div className="Cookies-content">
                  <p>
                    As per Indian laws, including the Information Technology
                    (Reasonable Security Practices and Procedures and Sensitive
                    Personal Data or Information) Rules, 2011, you have the
                    following rights related to cookies
                  </p>
                </div>
              </div>
              {/* 5.2.1*/}
              <div className="head-title">
                <div className="Cookies-child-heading">
                  <span className="Cookies-child-titles">
                    {" "}
                    5.2.1 - Consent Management{" "}
                  </span>
                </div>
                <div className="Cookies-content">
                  <p>
                    <span className="Cookies-point">
                      &bull; Non-essential cookies (e.g., performance,
                      analytics, and advertising cookies) will only be deployed
                      with your explicit consent.
                    </span>
                  </p>

                  <p>
                    <span>
                      &bull; Upon your first visit, a cookie consent banner will
                      appear, allowing you to accept or reject non-essential
                      cookies.
                    </span>
                  </p>
                </div>
              </div>
              {/* 5.2.2*/}
              <div className="head-title">
                <div className="Cookies-child-heading">
                  <span className="Cookies-child-titles">
                    {" "}
                    5.2.2 - Opting Out{" "}
                  </span>
                  <div className="Cookies-content">
                    We use the collected information to
                  </div>
                </div>
                <div className="Cookies-content">
                  <p>
                    <span className="Cookies-point">
                      &bull; View what cookies are set.
                    </span>
                  </p>

                  <p>
                    <span className="Cookies-point">
                      &bull; Delete cookies individually.
                    </span>{" "}
                  </p>
                  <p>
                    <span className="Cookies-point">
                      &bull; Block third-party cookies.
                    </span>{" "}
                  </p>
                  <p>
                    <span className="Cookies-point">
                      &bull; Block cookies for specific websites.
                    </span>{" "}
                  </p>
                  <p>
                    <span className="Cookies-point">
                      &bull; Clear all cookies when closing your browser.
                    </span>{" "}
                  </p>
                </div>
              </div>
              {/* 5.2.3 */}
              <div className="head-title">
                <div className="Cookies-child-heading">
                  <span className="Cookies-child-titles">
                    {" "}
                    5.2.3 - Withdraw Consent{" "}
                  </span>
                </div>
                <div className="Cookies-content">
                  <p>
                    If you previously consented to cookies, you can withdraw
                    your consent at any time by adjusting your browser settings
                    or using the cookie management tool available on our
                    website.
                  </p>
                </div>
              </div>
              {/* 5.2.4 */}
              <div className="head-title">
                <div className="Cookies-child-heading">
                  <span className="Cookies-child-titles">
                    {" "}
                    5.2.4 - Notification of Changes{" "}
                  </span>
                </div>
                <div className="Cookies-content">
                  <p>
                    <span>
                      If we update our cookie practices, we will notify users
                      through a prominent notice on our website or through email
                      (if applicable).
                    </span>
                  </p>
                </div>
              </div>
              {/* 5.3 */}
              <div className="head-title">
                <div className="Cookies-child-titles">
                  <span className="Cookies-child-titles">
                    {" "}
                    5.3 - Third Party Cookies and Tracking{" "}
                  </span>
                </div>
              </div>
              <div className="Cookies-content">
                <p>
                  We utilize third-party tools and services,
                  <span className="google-text">
                    {" "}
                    such as Google Analytics and Facebook Pixel,
                  </span>{" "}
                  to analyze website traffic and understand user behaviour.
                  These third parties may place cookies on your device to gather
                  data about your interactions with our website. For details on
                  how these providers manage your data, please review their
                  respective privacy policies.
                </p>
                <p>
                  <span className="note">Please note that</span> disabling or
                  rejecting certain cookies may affect the functionality of our
                  website and limit your access to specific features or
                  services.
                </p>
              </div>
              {/* 6 */}
              <div className="head-title">
                <div className="Data-Retention-titles">
                  <span className="Data-Retentionchild-titles">
                    {" "}
                    6 - Data Retention{" "}
                  </span>
                </div>
                <div className="Data-Retentionchild-content">
                  <p>
                    We are committed to retaining your data only for as long as
                    necessary to fulfill the purposes for which it was
                    collected, in compliance with Indian data protection laws,
                    including the Information Technology (Reasonable Security
                    Practices and Procedures and Sensitive Personal Data or
                    Information) Rules, 2011, and any other relevant
                    regulations.
                  </p>
                  <p>
                    We retain your personal data permanently unless one of the
                    following conditions applies:
                  </p>
                </div>
                {/* 6.1 */}
                <div className="head-title">
                  <div className="child-Data-Sharing-heading">
                    <span className="child-titles">
                      {" "}
                      6.1 - Business Transactions :{" "}
                    </span>
                    You have the right to request the deletion of your personal
                    data at any time. If you choose to exercise this right, we
                    will delete your data from our systems, subject to certain
                    legal and contractual obligations that may require us to
                    retain certain information for a longer period. To request
                    the deletion of your data, you can contact us at the
                    provided contact details, and we will process your request
                    in accordance with applicable laws.
                  </div>
                </div>
                {/* 6.2 */}
                <div className="head-title">
                  <div className="child-Data-Sharing-heading">
                    <span className="child-titles">
                      {" "}
                      6.2 - Legal Obligations :{" "}
                    </span>
                    <p>
                      In some cases, we may be required to retain your data for
                      a longer period to comply with legal obligations,
                      including regulatory requirements, dispute resolution, or
                      enforcement of agreements.
                    </p>
                    <p>
                      If we are legally required to retain your data for such
                      purposes, we will ensure that it is securely stored and
                      protected as per the standards set by applicable
                      regulations. Once the retention period for such legal
                      obligations expires, we will securely delete or anonymize
                      your data.
                    </p>
                  </div>
                </div>
                {/* 6.3 */}
                <div className="head-title">
                  <div className="child-Data-Sharing-heading">
                    <span className="child-titles">
                      {" "}
                      6.3 - Data Security During Retention :{" "}
                    </span>
                    <p>
                      {" "}
                      While your data is retained, we continue to implement the
                      best security measures available to ensure its
                      confidentiality and integrity. Once your data is no longer
                      necessary for the purposes outlined in this Privacy Policy
                      or required for legal retention, it will be securely
                      deleted or anonymized to protect your privacy.
                    </p>
                    <p>
                      We strive to maintain a balance between retaining data for
                      legitimate purposes and respecting your right to privacy
                      under Indian law. If you have any concerns about how long
                      we retain your data, please feel free to contact us for
                      further clarification.
                    </p>
                  </div>
                </div>
              </div>

              {/* 7 */}
              <div className="head-title">
                <div className="Your-rights-titles">
                  <span className="Your-rights-titles"> 7 - Your Rights </span>
                </div>
                <div className="Your-rights-content">
                  <p>
                    We are committed to providing you with the rights and
                    ability to manage and control the information you share with
                    us. The rights available to you are as follows:
                  </p>
                </div>
                {/* 7.1 */}

                <div className="head-title">
                  <div className="Your-rights-heading">
                    <span className="Your-rights-child-titles">
                      {" "}
                      7.1 - Right to Access and Review Your Personal Data :{" "}
                    </span>
                    You have the right to request the deletion of your personal
                    data at any time. If you choose to exercise this right, we
                    will delete your data from our systems, subject to certain
                    legal and contractual obligations that may require us to
                    retain certain information for a longer period. To request
                    the deletion of your data, you can contact us at the
                    provided contact details, and we will process your request
                    in accordance with applicable laws.
                  </div>
                </div>
                {/* 7.2 */}
                <div className="head-title">
                  <div className="Your-rights-heading">
                    <span className="Your-rights-child-titles">
                      {" "}
                      7.2 - Right to Correct Inaccurate Data :{" "}
                    </span>
                    You have the right to request the deletion of your personal
                    data at any time. If you choose to exercise this right, we
                    will delete your data from our systems, subject to certain
                    legal and contractual obligations that may require us to
                    retain certain information for a longer period. To request
                    the deletion of your data, you can contact us at the
                    provided contact details, and we will process your request
                    in accordance with applicable laws.
                  </div>
                </div>
                {/* 7.3 */}
                <div className="head-title">
                  <div className="Your-rights-heading">
                    <span className="Your-rights-child-titles">
                      {" "}
                      7.3 - Right to Withdraw Consent for Data Processing{" "}
                    </span>
                    You have the right to request the deletion of your personal
                    data at any time. If you choose to exercise this right, we
                    will delete your data from our systems, subject to certain
                    legal and contractual obligations that may require us to
                    retain certain information for a longer period. To request
                    the deletion of your data, you can contact us at the
                    provided contact details, and we will process your request
                    in accordance with applicable laws.
                  </div>
                </div>
                {/* 7.4 */}
                <div className="head-title">
                  <div className="Your-rights-heading">
                    <span className="Your-rights-child-titles">
                      {" "}
                      7.4 - Right to Request Deletion of Your Data :{" "}
                    </span>
                    You can request that we delete your personal data from our
                    systems. However, please note that we may be required to
                    retain certain data due to legal obligations, contractual
                    commitments, or for legitimate business interests, as
                    outlined in our Data Retention policy. When you request
                    deletion, we will review your request and take necessary
                    actions to delete or anonymize your data in compliance with
                    applicable laws.
                  </div>
                </div>
              </div>
              {/* 8 */}
              <div className="head-title">
                <div className="Security-titles">
                  <span className="Security-titles">
                    {" "}
                    8 - Security of Your Data{" "}
                  </span>
                </div>
                <div className="Security-content">
                  <p>
                    We understand the significance of keeping your data safe and
                    secure and take the best possible security measures to
                    protect it. We are committed to implementing
                    industry-standard security practices to ensure the
                    confidentiality and integrity of your personal information.
                    However, please note that no method of data transmission or
                    storage is entirely foolproof, and while we strive to
                    implement the highest level of security, we cannot guarantee
                    absolute security.
                  </p>
                </div>
              </div>
              {/* 9 */}
              <div className="head-title">
                <div className="Updates-titles">
                  <span className="Updates-titles">
                    {" "}
                    9 - Updates to this Privacy Policy{" "}
                  </span>
                </div>
                <div className="Updates-content">
                  <p>
                    We may update this Privacy Policy periodically. Changes will
                    be posted on this page with the revised effective date.
                  </p>
                </div>
              </div>
              {/* 10 */}
              <div className="head-title">
                <div className="Contact-titles">
                  <span className="Contact-titles"> 10 - Contact us </span>
                </div>
                <div className="Contact-content">
                  <p>
                    We may update this Privacy Policy periodically. Changes will
                    be posted on this page with the revised effective date.
                  </p>
                </div>
              </div>
              <div className="contacts">Email : support@insocialorbit.com</div>
              <div className="contacts">Phone :</div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;

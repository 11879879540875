import React from "react";
import Navbar from "./Navbar/Navbar";
import Banner from "./Banner/Banner";
import CoreBeliefs from "./CoreBelief/CoreBeliefs";
import AboutSection from "./AboutSection/AboutSection";
import MissionVision from "./MissionVission/MissionVision";
import Footer from "./Footer/Footer";

function LandingContent() {
  return (
    <div>

      <Navbar />
      <Banner />
      <AboutSection />
      {/* <CoreBeliefs /> */}
      <MissionVision />
      <Footer />
      
    </div>
  );
}

export default LandingContent;

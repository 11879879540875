import React from "react";
import Goal from "../../../components/assets/goal.png";
import Mission from "../../../components/assets/mission.png";
import Vision from "../../../components/assets/vision.png";
import "./missionVission.scss";
import { Description } from "@mui/icons-material";

function MissionVision() {
  const missionvision = [
    {
      title: "Our Mission",
      img: Mission, // Replace with an actual img or image
      description:
        "To empower brands and influencers by creating a streamlined platform that fosters trust, facilitates meaningful collaborations, and maximizes the value of their resources, enabling them to achieve measurable success together.",
    },
    {
      title: "Our Vision",
      img: Vision, // Replace with an actual img or image
      description:
        "To be the global leader in influencer marketing, revolutionizing the way brands and creators collaborate by building a seamless, transparent, and inclusive ecosystem that drives growth and innovation",
    },
    {
      title: "Our Goal",
      img: Goal, // Replace with an actual img or image
      description:
        "To connect 1 million brands and influencers worldwide by 2030, fostering impactful collaborations through cutting-edge technology, data-driven insights, and a commitment to creating value for all stakeholders",
    },
  ];

  return (
    <>
      <div className="container-mission-vission my-5 w-100 d-flex justify-content-center">
        <div className="row d-flex justify-content-around">
          {missionvision.map((item, index) => (
            <div
              key={index}
              className=" col-xl-4 col-xs-12 col-md-3 col-sm-6 d-flex"
            >
              <div className="card-body position-relative rounded-3">
                <img
                  src={item.img}
                  alt={item.title}
                  className="card-img-top"
                  style={{
                    height: "390px",
                    objectFit: "fill",
                    width: "90%",
                    marginLeft: "0%",
                  }}
                />
                <h5 className="about card-title-vmg text-white position-absolute top-0 start-50 translate-middle-x text-center mt-3">
                  {item.title}
                </h5>
                <p className=" about card-description text-white position-absolute top-50 start-50 translate-middle-x text-center belief-description">
                  {item.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default MissionVision;

import React from "react";
import Navbar from "./Navbar/Navbar";
import BannerHome from "./Banner-Home/BannerHome";
import PathtoExcellenceHome from "./PathtoExcellenceHome/PathtoExcellenceHome";
import UserReviewsHome from "./UserReviewsHome/UserReviewsHome";
import WhyWeHome from "./WhyWeHome/WhyWeHome";
import Footer from "./Footer/Footer";

function LandingContentHome() {
  return (
    <>
      <Navbar />
      <BannerHome />
      <WhyWeHome />
      <PathtoExcellenceHome />
      <UserReviewsHome />
      <Footer />
    </>
  );
}

export default LandingContentHome;

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
  Outlet,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { useEffect, useState } from "react";
import { loadUser } from "./actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./components/SideBar/SideBar";
import Header from "./components/Header/Header";
import LandingPage from "./components/LandingPage/LandingPage";
// import Brandsignup from "./components/BrandSignup/Brandsignup";
import Commonlogin from "./components/LogIn/CommonLogin";
import Phone_Sign_Up from "./components/Phone_Sign_Up/Phone_Sign_Up";
import BrandProfile from "./components/BrandProfile/BrandProfile";
import ContactDetails from "./components/ContactDetails/ContactDetails";
import Dashboard from "./components/Dashboard/Dashboard";
import ProgressBar from "./components/ProgressBar/ProgressBar";
// import Signup from "./components/InfluencerSignup/SignupInfluencer";
import InfluencerDashboard from "./components/InfluencerDashboard/InfluencerDashboard";
import CampaignDashboard from "./components/CampaignDashboard/CampaignDashboard";
import CampaignDetails from "./components/CampaignDetails/CampaignDetails";
import CampaignOverview from "./components/CampaignOverview/CampaignOverview";
import Form from "./components/Campaign/CampaignForm";
import ProfileSection from "./components/ProfileSection/ProfileSection";
import Youtube from "./components/Youtube/Youtube";
import Stats from "./components/Stats/Stats";
import Engagement from "./components/Stats/Engagement";
import Reach from "./components/Reach/Reach";
import InfluencerStats from "./components/InfluencerStatsPage/InfluencerStats";
import Filter from "./components/Filter/Filter";
import InfluencerCampaign from "./components/InfluencerCampaignPage/InfluencerCampaign";
import InfluencerOverview from "./components/InfluencerOverview/InfluencerOverview";
import CollabRequestPage from "./components/CollabRequestPage/CollabRequest";
import InfluencerHome from "./components/InfluencerHome/InfluencerHome";
import Payment from "./components/Payment/Payment";
import Chats from "./components/Chats/Chats";
import PaymentHome from "./components/Payment/PaymentHome";
import PaymentDetails from "./components/Payment/PaymentDetails";
import PaymentProfile from "./components/Payment/Profile";
import PaymentVerification from "./components/Payment/VerificationWindow";
import AcceptedRequest from "./components/AcceptedRequest/AcceptedRequest";
import ContractPage from "./components/ContractsPage/ContractsPage";
import InfluencerProfile from "./components/InfluencerProfile/InfluencerProfile";
import Sales from "./components/test/Sales";
import ConnectWithSocialMedia from "./components/ConnectWithSocialMedia/ConnectWithSocialMedia";
import AdminHome from "./components/Admin/AdminHome";
import SignUpPage from "./components/SignUpPage/SignUpPage";
import PhoneEmail from "./components/Phone or Email/PhoneEmail";
import MobileOtp from "./components/MobileNumber Otp/MobileOtp";
import Email from "./components/EmailLoginPage/Email";
import axios from "axios";
import BannerHome from "./components/LandingComponents/Banner-Home/BannerHome";
import PathtoExcellenceHome from "./components/LandingComponents/PathtoExcellenceHome/PathtoExcellenceHome";
import UserReviewsHome from "./components/LandingComponents/UserReviewsHome/UserReviewsHome";
import LandingContentHome from "./components/LandingComponents/LandingContentHome";
import PrivacyPolicy from "./components/LandingComponents/PrivacyPolicy/PrivacyPolicy";
import Loader from "./components/layout/Loader/Loader";
import SignUpWithLinks from "./components/SignUpWithLinks/SignUpWithLinks";
import ContactUs from "./components/ContactUs/ContactUs";
import BrandLanding from "./components/BrandLandingPage/BrandLanding";

function App() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState("");

  async function findUserDetail() {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/me`, {
        withCredentials: true,
      })
      .then((res) => setUser(res.data.user))
     
      .catch((err) => console.log(err));

    await dispatch(loadUser())
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }

  useEffect(() => {
    findUserDetail();
  }, []);

  const { isAuthenticated } = useSelector((state) => state.user);

  // const contentWidth = isAuthenticated ? "93vw" : "100vw";

  // useEffect(() => {
  //   if (
  //     !isLoading &&
  //     user &&
  //     user.isprofilecompleted === 0 &&
  //     user.role !== "admin"
  //   ) {
  //     navigate("/dashboard");
  //   }
  // }, [user, isLoading]);

  const PrivateRoutes = () => {
    // debugger;
    return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
  };

  const PublicRoutes = () => {
    return isAuthenticated ? <Navigate to="/home" /> : <Outlet />;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    // <div className="main-dashboard">
    //   {isAuthenticated && <Sidebar />}
    //   <div
    //     className={isAuthenticated ? "content" : ""}
    //     style={{ width: "100vw" }}
    //   >
    //     {isAuthenticated && <Header />}
    //     <Routes>
    //       <Route element={<PublicRoutes />}>
    //         <Route path="/" element={<LandingPage />} />
    //         <Route path="/sign-up/:role" element={<Brandsignup />} />
    //         <Route path="/login" element={<Commonlogin />} />
    //         <Route path="/phone-sign-up" element={<Phone_Sign_Up />} />
    //       </Route>
    //       <Route element={<PrivateRoutes />}>
    //         <Route path="/home" element={<InfluencerDashboard />} />
    //         <Route path="/brand-profile" element={<BrandProfile />} />
    //         <Route path="/campaign-details" element={<CampaignDetails />} />
    //         <Route path="/contact-details" element={<ContactDetails />} />
    //         <Route
    //           path="/dashboard"
    //           element={
    //             user?.role === "brand" ? (
    //               <Dashboard />
    //             ) : user?.role === "admin" ? (
    //               <InfluencerDashboard />
    //             ) : (
    //               <InfluencerProfile />
    //             )
    //           }
    //         />
    //         <Route path="/profile" element={<InfluencerProfile />} />
    //         <Route path="/progress-bar" element={<ProgressBar />} />
    //         <Route
    //           path="/campaign"
    //           element={
    //             user?.role === "brand" ? (
    //               <CampaignDashboard />
    //             ) : (
    //               <InfluencerCampaign />
    //             )
    //           }
    //         />
    //         <Route path="/campaign/:id" element={<CampaignOverview />} />
    //         <Route path="/progress-bar" element={<ProgressBar />} />
    //         <Route path="/campaign/create" element={<Form />} />
    //         <Route
    //           path="/me"
    //           element={
    //             user?.role === "brand" ? <ProfileSection /> : <PaymentProfile />
    //           }
    //         />
    //         <Route path="/stats" element={<Stats />} />
    //         <Route path="/engagement" element={<Engagement />} />
    //         <Route path="/youtube" element={<Youtube />} />
    //         <Route path="/reach" element={<Reach />} />
    //         <Route path="/chat" element={<Chats />} />
    //         <Route path="/influencerstats" element={<InfluencerStats />} />
    //         <Route path="/acceptedrequest" element={<AcceptedRequest />} />
    //         <Route path="/contractspage" element={<ContractPage />} />
    //         <Route
    //           path="/influencercampaign"
    //           element={<InfluencerCampaign />}
    //         />
    //         <Route path="/chat" element={<InfluencerCampaign />} />
    //         <Route path="/connect" element={<ConnectWithSocialMedia />} />
    //         <Route path="/payment" element={<Payment />} />
    //         <Route
    //           path="/influenceroverview"
    //           element={<InfluencerOverview />}
    //         />
    //         <Route path="/collabrequest" element={<CollabRequestPage />} />
    //         <Route path="/payment" element={<Payment />} />
    //         <Route path="/paymenthome" element={<PaymentHome />} />
    //         <Route path="/paymentdetails" element={<PaymentDetails />} />
    //         <Route path="/paymentprofile" element={<PaymentProfile />} />
    //         <Route path="/test" element={<Sales />} />
    //         <Route
    //           path="/paymentverification"
    //           element={<PaymentVerification />}
    //         />
    //       </Route>
    //     </Routes>
    //   </div>
    // </div> old as it is

    //new route for home page
    <div className="main-dashboard">
      {isAuthenticated && <Sidebar />}
      <div
        className={isAuthenticated ? "content" : ""}
        style={{ width: "100%" }}
      >
        {isAuthenticated && <Header />}
        {/* <SignUpPage/> */}
        <Routes>
          {/* Public Routes */}
          <Route element={<PublicRoutes />}>
            <Route path="/about" element={<LandingPage />} />
            {/* <Route path="/sign-up/:role" element={<Brandsignup />} /> */}
            <Route path="/login" element={<Commonlogin />} />
            <Route path="/phone-sign-up" element={<Phone_Sign_Up />} />
            <Route path="/signUp" element={<SignUpPage />} />
            <Route path="/phone-email" element={<PhoneEmail />} />
            <Route path="/otp" element={<MobileOtp />} />
            <Route path="/email" element={<Email />} />
            <Route path="/banner-home" element={<BannerHome />} />
            <Route path="/User-Reviews-Home" element={<UserReviewsHome />} />
            <Route path="/signup-with-socials" element={<SignUpWithLinks />} />
            <Route path="/signup-with-socials" element={<SignUpWithLinks />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/brand" element={<BrandLanding />} />
            <Route
              path="/Path-to-Excellence-Home"
              element={<PathtoExcellenceHome />}
            />
          </Route>
          <Route path="/" element={<LandingContentHome />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          {/* Private Routes */}
          <Route element={<PrivateRoutes />}>
            {/* Dynamic Home Route Based on Role */}
            <Route
              path="/home"
              element={
                user?.role === "admin" || user?.role === "brand" ? (
                  <InfluencerDashboard /> // Show for brand users
                ) : (
                  <InfluencerCampaign route="home" /> // Show for influencer users and default
                )
              }
            />

            {/* Other Routes */}
            <Route path="/brand-profile" element={<BrandProfile />} />
            <Route path="/campaign-details" element={<CampaignDetails />} />
            <Route path="/contact-details" element={<ContactDetails />} />
            <Route
              path="/dashboard"
              element={
                user?.role === "brand" ? (
                  <Dashboard />
                ) : user?.role === "admin" ? (
                  <InfluencerDashboard />
                ) : (
                  <InfluencerProfile />
                )
              }
            />
            <Route path="/profile" element={<InfluencerProfile />} />
            <Route path="/progress-bar" element={<ProgressBar />} />
            <Route
              path="/campaign"
              element={
                user?.role === "brand" ? (
                  <CampaignDashboard />
                ) : (
                  <InfluencerCampaign route="campaign" />
                )
              }
            />
            <Route path="/campaign/:id" element={<CampaignOverview />} />
            <Route path="/campaign/create" element={<Form />} />
            <Route
              path="/me"
              element={
                user?.role === "brand" ? <ProfileSection /> : <PaymentProfile />
              }
            />
            <Route path="/stats" element={<Stats />} />
            <Route path="/engagement" element={<Engagement />} />
            <Route path="/youtube" element={<Youtube />} />
            <Route path="/reach" element={<Reach />} />
            <Route path="/chat" element={<Chats />} />
            <Route path="/influencerstats" element={<InfluencerStats />} />
            <Route path="/acceptedrequest" element={<AcceptedRequest />} />
            <Route path="/contractspage" element={<ContractPage />} />
            <Route
              path="/influencercampaign"
              element={<InfluencerCampaign route="influencercampaign" />}
            />
            <Route path="/connect" element={<ConnectWithSocialMedia />} />
            <Route path="/payment" element={<Payment />} />
            <Route
              path="/influenceroverview"
              element={<InfluencerOverview />}
            />
            <Route path="/collabrequest" element={<CollabRequestPage />} />
            <Route path="/paymenthome" element={<PaymentHome />} />
            <Route path="/paymentdetails" element={<PaymentDetails />} />
            <Route path="/paymentprofile" element={<PaymentProfile />} />
            <Route path="/test" element={<Sales />} />
            <Route
              path="/paymentverification"
              element={<PaymentVerification />}
            />
          </Route>
        </Routes>
      </div>
    </div>
  );
}

export default function Root() {
  return (
    <Router>
      <App />
    </Router>
  );
}

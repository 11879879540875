import React from "react";
import "./BannerHome.scss";

function BannerHome() {
  return (
    <div>
      <div className="hero-section-banner">
        <div className="overlay">
          <div className="animation-text">
            <h1>Where Brands and Influencers Orbit Together</h1>
            <button className="connect-button">Let’s Connect</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerHome;

import React from "react";
import "./WhyWeHome.scss";
import StrategicExcellence from "../../assets/StrategicExcellence.png";
import InnovativeSolutions from "../../assets/InnovativeSolutions.png";
import GenuineConnections from "../../assets/GenuineConnections.png";
import DataDrivenSuccess from "../../assets/Data-Driven Success.png";
import YourPartner from "../../assets/Your Partner.png";
import EmpoweringGrowth from "../../assets/Empowering Growth.png";

function WhyWeHome() {
  const whywe = [
    {
      title: "Strategic Excellence",
      img: StrategicExcellence, // Replace with an actual img or image
      description:
        "We don’t just create strategies; we craft tailored roadmaps that align with your brand’s unique goals, audience, and vision. Every campaign is designed to deliver measurable results.",
    },
    {
      title: "Innovative Solutions",
      img: InnovativeSolutions, // Replace with an actual img or image
      description:
        "From trend-driven insights to cutting-edge tools, we ensure your brand stays ahead of the curve. Innovation fuels our approach to amplify your online presence.",
    },
    {
      title: "Genuine Connections",
      img: GenuineConnections, // Replace with an actual img or image
      description:
        "Building meaningful relationships is at the heart of what we do. Whether it’s engaging your audience or fostering collaborations, we help you create connections that matter.",
    },
    {
      title: "Data-Driven Success",
      img: DataDrivenSuccess, // Replace with an actual img or image
      description:
        "Our decisions are guided by analytics, ensuring that every step is backed by insights. This approach guarantees your resources are invested where they create the most value.",
    },
    {
      title: "Your Partner",
      img: YourPartner, // Replace with an actual img or image
      description:
        "We work alongside you, understanding your needs and challenges. Together, we turn your aspirations into achievements.",
    },
    {
      title: "Empowering Growth",
      img: EmpoweringGrowth, // Replace with an actual img or image
      description:
        "Unlock your potential with tailored strategies designed to drive results and foster meaningful connections. Together, we pave the way for lasting success, empowering your journey to new heights.",
    },
  ];

  return (
    <>
      {/* why choose us */}
      <section class="why-choose-section">
        <div class="container">
          <div className="d-flex justify-content-start">
            <div className="why-we-title">
            <h2>Why Choose InSocial Orbit?</h2>
            </div>
            <div className="why-we">
            <p>
              In today’s fast-paced digital landscape, standing out requires
              more than just presence; it demands impact. InSocial Orbit is
              your partner in navigating the vast universe of social media and
              digital connections. Here’s why we’re the perfect choice for
              your growth:
            </p>
            </div>
          </div>
        </div>
        <div className="container my-5 w-100 " >
          <div className="row d-flex justify-content-center">
            {/* First Row of Cards */}
            {whywe.slice(0, 3).map((item, index) => (
              <div
                key={index}
                className="col-xl-4 col-xs-12 col-md-4 col-sm-6 d-flex mb-4"
                style={{
                  height:"380px",
                  width:"410px"
                }}
              >
                <div className="card-body position-relative rounded-3">
                  <img
                    src={item.img}
                    alt={item.title}
                    className="card-img-top img-fluid"
                    style={{
                      height: "auto",
                      objectFit: "cover",
                      width: "100%",
                      marginLeft: "3%",
                      borderRadius: "2%",
                    }}
                  />
                  <h5 className="card-title-vmg text-white position-absolute top-0 start-50 translate-middle-x text-center mt-3">
                    {item.title}
                  </h5>
                  <p className="card-description text-white position-absolute top-50 start-50 translate-middle-x text-center belief-description">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* Space Between Rows */}
          <div className="row d-flex justify-content-center">
            {/* Second Row of Cards */}
            {whywe.slice(3, 6).map((item, index) => (
              <div
                key={index}
                className="col-xl-4 col-xs-12 col-md-4 col-sm-6 d-flex mb-4"
                style={{
                  height:"380px",
                  width:"410px"
                }}
              >
                <div className="card-body position-relative rounded-3">
                  <img
                    src={item.img}
                    alt={item.title}
                    className="card-img-top img-fluid "
                    style={{
                      height: "auto",
                      objectFit: "cover",
                      width: "100%",
                      marginLeft: "3%",
                      borderRadius: "2%",
                    }}
                  />
                  <h5 className="card-title-vmg text-white position-absolute top-0 start-50 translate-middle-x text-center mt-3">
                    {item.title}
                  </h5>
                  <p className="card-description text-white position-absolute top-50 start-50 translate-middle-x text-center belief-description w3-container w3-center w3-animate-left">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* cards */}

    </>
  );
}

export default WhyWeHome;

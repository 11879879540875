import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { sendOtpEmail, setEmailInRedux } from "../../actions/userAction";
import Footer from "../LandingComponents/Footer/Footer";
import NavBar from "../LandingComponents/Navbar/Navbar";
import loginimg from "../assets/loginsideimg.png";
import "./Email.scss";
import Loader from "../layout/Loader/Loader";

function Email({ onClick }) {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({ email: "", server: "" });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isOtpSendSuccessfully, error: serverError, loading } = useSelector(
    (state) => state.user
  );

  // Validation function
  const validateEmail = () => {
    let emailError = "";

    if (!email) {
      emailError = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      emailError = "Please enter a valid email address.";
    }

    setErrors({ email: emailError, server: "" });
    return !emailError;
  };

  // Handle Login Click
  const handleLoginClick = (e) => {
    e.preventDefault();

    if (validateEmail()) {
      dispatch(setEmailInRedux(email)); // Set email in Redux
      dispatch(sendOtpEmail({ email })); // Send OTP request
    }
  };

  // Handle navigation on successful OTP sending
  useEffect(() => {
    if (isOtpSendSuccessfully) {
      navigate("/otp");
      if (onClick) onClick("Loginemail", email);
    }
  }, [isOtpSendSuccessfully, navigate, onClick, email]);

  // Handle server-side errors
  useEffect(() => {
    if (serverError) {
      setErrors((prev) => ({ ...prev, server: serverError }));
    }
  }, [serverError]);

  return (
    <>
      <NavBar />
      <div className="container-email p-0 mt-5 mb-5">
        <div className="card">
          <div className="row no-gutters">
            {/* Left Side (Image Section) */}
            <div className="col-xs-12 col-sm-12 col-md-6 left">
              <img src={loginimg} alt="sideimg" className="img" />
            </div>

            {/* Right Side (Login Section) */}
            <div className="col-xs-12 col-sm-12 col-md-6 right">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                  <h2>
                    Collaborate.
                    <br /> Create. Connect.
                  </h2>
                  <form onSubmit={handleLoginClick}>
                    <div className="inputForm">
                      <input
                        placeholder="Enter your Email"
                        className="input text-center"
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{ color: "rgba(107, 142, 118, 1)" }}
                      />
                    </div>
                    {errors.email && <p className="error-text">{errors.email}</p>}
                    {errors.server && <p className="error-text">{errors.server}</p>}

                    <div className="flex-row d-flex justify-content-between align-items-center mt-3">
                      <div>
                        <input type="checkbox" />
                        <label className="ms-2">Remember me</label>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="button-submit"
                      disabled={loading}
                    >
                      {loading ? <Loader /> : "Login In With Email"}
                    </button>
                  </form>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 footer">
                  <div className="sign-link mt-auto">
                    <Link
                      to="/phone-sign-up"
                      className="btn-link"
                      onClick={() => navigate("/phone-sign-up")}
                    >
                      Login With Phone
                    </Link>
                  </div>
                  <div className="signup-instruction">
                    By continuing, you agree to our terms and conditions.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Email;

import React, { useState } from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
import footerLogo from "../../assets/logo.png";
import footercall from "../../assets/footercall.png";
import footermail from "../../assets/footermail.png";

function Footer() {
  const [hovered, setHovered] = useState(false);

  return (
    <>
      <footer className="custom-footer">
        <div className="container">
          <div className="row justify-content-between align-items-start">
            {/* Left Section */}
            <div className="col-xl-6 col-xs-12 col-md-12 col-sm-12 mb-4 footer-about">
              <div className="footer-logo">
                <img src={footerLogo} alt="InSocialOrbit Logo" />
              </div>
              <p className="description">
                At InSocialOrbit, we connect influencers and brands, enabling
                seamless collaboration through profile syncing, showcasing
                achievements, and campaign metrics.
              </p>
              <div className="contact-info">
                <div className="contact-box-call mb-2">
                  <p className="m-0">
                    <img
                      src={footercall}
                      alt="Phone Icon"
                      className="contact-icon"
                    />
                    &nbsp; +91 94260 26611
                  </p>
                </div>
                <div className="contact-box-mail">
                  <p className="m-0">
                    <img
                      src={footermail}
                      alt="Email Icon"
                      className="contact-icon"
                    />{" "}
                    &nbsp; support@insocialorbit.com{" "}
                  </p>
                </div>
              </div>
            </div>

            {/* Right Section */}
            <div className="col-xl-4 col-xs-12 col-md-6 col-sm-12 all-links">
              <div className="row">
                {/* Quick Links */}
                <div className="col-xl-4 col-xs-12 col-md-3 col-sm-6 footer-links">
                  <h5 className=" link mb-3">Quick Links</h5>
                  <ul className="list-unstyled">
                    <li className="mb-2">
                      <Link to="/" className="text-decoration-none text-white">
                        Home
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link to="/about" className="text-decoration-none text-white">
                        About us
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link
                        to="/contact-us"
                        className="text-decoration-none text-white"
                      >
                        Contact us
                      </Link>
                    </li>
                  </ul>

                  {/* <div className="back color-1">
                    <div className="row columns">
                      <h3>Effect 31</h3>
                      <ul className="menu align-center expanded text-center SMN_effect-31">
                        <li>
                          <a href="#" data-hover="Home">
                            <span>Home</span>
                          </a>
                        </li>
                        <li>
                          <a href="#" data-hover="About">
                            <span>About</span>
                          </a>
                        </li>
                        <li>
                          <a href="#" data-hover="Gallery">
                            <span>Gallery</span>
                          </a>
                        </li>
                        <li>
                          <a href="#" data-hover="Notes">
                            <span>Notes</span>
                          </a>
                        </li>
                        <li>
                          <a href="#" data-hover="Contact">
                            <span>Contact</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>

                {/* Legal & Company Info */}
                <div className="col-xl-6 col-xs-12 col-md-6 col-sm-6 mb-4 footer-legal">
                  <h5 className="link mb-3">Legal & Company Info</h5>

                  <ul className="menu align-center expanded text-center SMN_effect-31">
                    <li>
                      <Link
                        to="/privacy-policy"
                        data-hover="Privacy Policy"
                        className="text-decoration-none text-white"
                      >
                        <span>Privacy Policy</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        data-hover="Terms & Conditions"
                        className="text-decoration-none text-white"
                      >
                        <span>Terms & Conditions</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        data-hover="Copy Rights"
                        className="text-decoration-none text-white"
                      >
                        <span>Copy Rights</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        data-hover="User Agreement"
                        className="text-decoration-none text-white"
                      >
                        <span>User Agreement</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        data-hover="Cookie Policy"
                        className="text-decoration-none text-white"
                      >
                        <span>Cookie Policy</span>
                      </Link>
                    </li>
                  </ul>
                  {/* <ul className="list-unstyled">
                    <li className="mb-2">
                      <Link
                        className={hovered ? "hovered" : ""}
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        to="/privacy-policy"
                        // className="text-decoration-none text-white"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link to="#" className="text-decoration-none text-white">
                        Terms & Conditions
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link to="#" className="text-decoration-none text-white">
                        Copy Rights
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link to="#" className="text-decoration-none text-white">
                        User Agreement
                      </Link>
                    </li>
                    <li className="mb-2">
                      <Link to="#" className="text-decoration-none text-white">
                        Cookie Policy
                      </Link>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>

          {/* Footer Bottom */}
          <div className="footer-bottom text-center mt-2">
            <p className="m-0">Copyright by 2024 InSocial Orbit</p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;

import React, { useState, useEffect } from "react";
import BackArrow from "../assets/BackArrow.svg";
import NextArrow from "../assets/Nextarrow.svg";
import Polygon from "../assets/Polygon.png";
import GIF from "../assets/loadingGIF.gif";
import { State, City } from "country-state-city";
import Select from "react-select";
import Hand from "../assets/Hand.png";
import Mediainfo from "../assets/Mediainfo.png";
import Audience from "../assets/Audience.png";
import WarningIcon from "@mui/icons-material/Warning";
import Stepper from "./Stepper";
import FileIcon from "../assets/file_invoice.png";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Divider,
  Grid,
  TextField,
  styled,
} from "@mui/material";

import {
  createCampaign,
  getCampaignOverview,
  setCampaignDates,
  setPublishStatus,
  setTargetAudienceForCampaign,
  updateCampaign,
  updateCampaignDates,
  updateTargetAudienceforCampaign,
} from "../../actions/campaignAction";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CampaignForm.scss";
import campaignIcon from "../../components/assets/Campaign_icon.png";

const Form = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const location = useLocation();
  const editCampaign = location.state?.editCampaign || {};
  const isedited = location.state?.isedited || false;

  const [completedSteps, setCompletedSteps] = useState([]);
  const steps = [
    { id: 1, label: "Campaign", icon: campaignIcon },
    { id: 2, label: "Audience", icon: campaignIcon },
    { id: 3, label: "Deliverables", icon: campaignIcon },
    { id: 4, label: "Overview", icon: campaignIcon },
  ];

  const [campaignDetails, setCampaignDetails] = useState({
    campaign_poster: editCampaign?.campaign_poster || "",
    campaign_title: editCampaign?.campaign_title || "",
    campaign_obj: editCampaign?.campaign_obj || "",
    description: editCampaign?.description || "",
    compensation_type: editCampaign?.compensation_type || "",
    budget: editCampaign?.budget || "",
    campaign_id: editCampaign?.campaign_id || "",
  });

  const [emptyFieldWarnings, setEmptyFieldWarnings] = useState({
    campaign_title: false,
    campaign_obj: false,
    description: false,
    compensation_type: false,
    budget: false,
  });

  const [emptyStateWarnings, setEmptyStateWarnings] = useState({
    state: false,
  });
  const [emptyCityWarnings, setEmptyCityWarnings] = useState({ city: false });
  const [emptyGenderWarnings, setEmptyGenderWarnings] = useState({
    gender: false,
  });
  const [emptyLangWarnings, setEmptyLangWarnings] = useState({
    language: false,
  });
  const [emptyAgeWarnings, setEmptyAgeWarnings] = useState({ ageGroup: false });
  const [targetAudience, setTargetAudience] = useState({
    state: "",
    city: "",
    gender: "",
    language: "",
    age_group: "",
  });

  const handleNextButtonClick = () => {
    if (isedited) {
      const warnings = {
        state: editCampaign.state.length === 0,
        city: editCampaign.city.length === 0,
        gender: editCampaign.gender.length === 0,
        language: editCampaign.language.length === 0,
        ageGroup: selectedButtons.length === 0,
      };

      setEmptyStateWarnings(warnings);
      setEmptyCityWarnings(warnings);
      setEmptyGenderWarnings(warnings);
      setEmptyLangWarnings(warnings);
      setEmptyAgeWarnings(warnings);

      if (Object.values(warnings).some((value) => value)) {
        return;
      }
      handleNext();
    } else {
      const warnings = {
        state: selectedStates.length === 0,
        city: selectedCities.length === 0,
        gender: selectedGenders.length === 0,
        language: selectedLanguages.length === 0,
        ageGroup: selectedButtons.length === 0,
      };

      setEmptyStateWarnings(warnings);
      setEmptyCityWarnings(warnings);
      setEmptyGenderWarnings(warnings);
      setEmptyLangWarnings(warnings);
      setEmptyAgeWarnings(warnings);

      if (Object.values(warnings).some((value) => value)) {
        return;
      }
      handleNext();
    }
  };

  function formatDBDate(inputDate) {
    if (inputDate) {
      return new Date(inputDate).toISOString().split("T")[0];
    } else {
      return "";
    }
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    campaign,
    isCampaignCreated,
    isTargetAudienceSetted,
    isCampaignDatesSetted,
  } = useSelector((state) => state?.campaign?.campaign || {});
  const { user } = useSelector((state) => state?.user || {});

  const [options1, setOptions1] = useState([0, 0, 0]);
  const [options2, setOptions2] = useState([0, 0, 0]);
  const [weekData, setWeekData] = useState(
    Array.isArray(editCampaign?.timeline) ? editCampaign.timeline : []
  );

  const [startDate, setStartDate] = useState(
    isedited ? formatDBDate(editCampaign.start_date) : ""
  );
  const [endDate, setEndDate] = useState(
    isedited ? formatDBDate(editCampaign.end_date) : ""
  );
  const [loading, setLoading] = useState(false);
  const [selectedButtons, setSelectedButtons] = useState(
    editCampaign.age_group?.length > 0 ? editCampaign.age_group : []
  );
  const [selectedButtonTexts, setSelectedButtonTexts] = useState(
    editCampaign.age_group?.length > 0 ? editCampaign.age_group : []
  );
  const [NumberOfSteps, setNumerOfSteps] = useState(0);
  const [selectedWeek, setSelectedWeek] = useState(1);
  const [displayType, setDisplayType] = useState("day");
  const [ispublished, setIsPublished] = useState(0);
  const [isautomatciallyPublished, setIsAutomaticallyPublished] = useState(0);
  const instagramActivities = ["reels", "posts", "story"];
  const youtubeActivities = ["shorts", "videos", "live_stream"];

  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "#A0AAB4",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#6B8E76",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#6B8E76",
        borderRadius: "10px",
      },
      "&:hover fieldset": {
        borderColor: "#6B8E76",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#6B8E76",
      },
    },
  });

  const isNextButtonDisabled = () => {
    switch (currentStep) {
      case 1:
        return (
          !campaignDetails.campaign_title ||
          !campaignDetails.campaign_obj ||
          !campaignDetails.description
        );

      case 2:
        return (
          selectedStates.length === 0 ||
          selectedCities.length === 0 ||
          selectedGenders.length === 0 ||
          selectedLanguages.length === 0 ||
          selectedButtons.length === 0
        );

      default:
        return false;
    }
  };

  const handleCounterChange = (optionIndex, counterIndex, increment, type) => {
    let updatedWeekData = [...weekData];
    if (optionIndex === 1) {
      const updatedOptions1 = options1;
      updatedOptions1[counterIndex] += increment;
      setOptions1(updatedOptions1);
      const timelineData = generateTimeline(selectedWeek, options1, options2);
      if (updatedWeekData[selectedWeek - 1]) {
        updatedWeekData[selectedWeek - 1] = { ...timelineData };
      } else {
        updatedWeekData.push({ ...timelineData });
      }
    }
    if (optionIndex === 2) {
      const updatedOptions2 = options2;
      updatedOptions2[counterIndex] += increment;
      setOptions2(updatedOptions2);
      const timelineData = generateTimeline(selectedWeek, options1, options2);
      if (updatedWeekData[selectedWeek - 1]) {
        updatedWeekData[selectedWeek - 1] = { ...timelineData };
      } else {
        updatedWeekData.push({ ...timelineData });
      }
    }

    setWeekData(updatedWeekData);
  };

  const getNumberOfWeeks = (startDate, endDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const firstDate = new Date(startDate);
    const secondDate = new Date(endDate);

    return Math.round(Math.abs((firstDate - secondDate) / oneDay) / 7);
  };

  function getNumberOfDays(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return Math.floor((end - start) / (1000 * 60 * 60 * 24)) + 1; // Include the start date
  }

  function getNumberOfMonths(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const yearDiff = end.getFullYear() - start.getFullYear();
    const monthDiff = end.getMonth() - start.getMonth();

    return yearDiff * 12 + monthDiff;
  }

  const generateTimeline = (selectedWeek, options1, options2) => {
    const platform1 = "Instagram";
    const platform2 = "YouTube";

    let platform1Data = {};
    let platform2Data = {};

    if (options1 && options1.length === 3) {
      platform1Data = {
        [platform1]: {
          [instagramActivities[0]]: options1[0],
          [instagramActivities[1]]: options1[1],
          [instagramActivities[2]]: options1[2],
        },
      };
    }

    if (options2 && options2.length === 3) {
      platform2Data = {
        [platform2]: {
          [youtubeActivities[0]]: options2[0],
          [youtubeActivities[1]]: options2[1],
          [youtubeActivities[2]]: options2[2],
        },
      };
    }
    const timeline = {
      [displayType]: selectedWeek,
      ...platform1Data,
      ...platform2Data,
    };

    return timeline;
  };

  const calculateCounts = (timeline) => {
    const counts = {
      Instagram: {},
      YouTube: {},
    };

    timeline.forEach((week) => {
      Object.keys(week).forEach((platform) => {
        if (typeof week[platform] === "object") {
          Object.keys(week[platform]).forEach((item) => {
            counts[platform][item] =
              (counts[platform][item] || 0) + week[platform][item];
          });
        }
      });
    });

    return counts;
  };

  let formattedInstagramCounts = "";
  let formattedYouTubeCounts = "";

  if (campaign?.timeline) {
    const platformCounts = calculateCounts(campaign?.timeline);
    const formatPlatformCounts = (platform) => {
      const items = Object.keys(platformCounts[platform]);
      const nonZeroItems = items.filter(
        (item) => platformCounts[platform][item] !== 0
      );
      return nonZeroItems
        .map((item) => `${platformCounts[platform][item]} ${item}`)
        .join(", ");
    };

    formattedInstagramCounts = formatPlatformCounts("Instagram");
    formattedYouTubeCounts = formatPlatformCounts("YouTube");
  }

  const handleWeekClick = (index) => {
    const weekNumber = index + 1;
    setSelectedWeek(weekNumber);
    const selectedWeekData = weekData[weekNumber - 1];

    if (selectedWeekData) {
      const instagramData = selectedWeekData.Instagram || {};
      const youtubeData = selectedWeekData.YouTube || {};

      setOptions1([
        instagramData.reels || 0,
        instagramData.posts || 0,
        instagramData.story || 0,
      ]);

      setOptions2([
        youtubeData.shorts || 0,
        youtubeData.videos || 0,
        youtubeData.live_stream || 0,
      ]);
    } else {
      setOptions1([0, 0, 0]);
      setOptions2([0, 0, 0]);
    }
  };

  const handlePublishRadioChange = () => {
    setIsAutomaticallyPublished((prevValue) => (prevValue === 0 ? 1 : 0));
  };

  const formatGenders = (genderJson) => {
    try {
      const genderArray =
        typeof genderJson === "object" ? genderJson : JSON.parse(genderJson);

      if (Array.isArray(genderArray)) {
        const formattedGenders = genderArray.map((gender) => {
          if (typeof gender === "string" && gender.length > 0) {
            return gender.charAt(0).toUpperCase() + gender.slice(1);
          } else {
            return gender;
          }
        });

        return formattedGenders.join(" & ");
      } else if (typeof genderArray === "string") {
        return genderArray;
      } else {
        return "";
      }
    } catch (error) {
      console.error("Error parsing gender JSON:", error);
      return "";
    }
  };

  const formatLanguages = (languagesJson) => {
    try {
      const languagesArray =
        typeof languagesJson === "object"
          ? languagesJson
          : JSON.parse(languagesJson);
      return languagesArray.join(", ");
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return "";
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      setLoading(true);

      const noOfDays = getNumberOfDays(startDate, endDate);
      const numberOfWeeks = getNumberOfWeeks(startDate, endDate);

      if (numberOfWeeks === 0) {
        setDisplayType("day");
        setNumerOfSteps(noOfDays);
      } else if (numberOfWeeks > 8) {
        setDisplayType("month");
        const numberOfMonths = getNumberOfMonths(startDate, endDate);
        setNumerOfSteps(numberOfMonths);
      } else {
        setDisplayType("week");
        setNumerOfSteps(numberOfWeeks);
      }

      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [startDate, endDate, dispatch]);

  const handleInputChange = (field, value) => {
    setCampaignDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));

    setEmptyFieldWarnings((prevWarnings) => ({
      ...prevWarnings,
      [field]: false,
    }));
  };

  useEffect(() => {}, [ispublished]);

  const handleNext = async () => {
    setCompletedSteps((prevCompletedSteps) => [
      ...prevCompletedSteps,
      currentStep,
    ]);
    const requiredFields = ["campaign_title", "campaign_obj", "description"];
    let hasEmptyField = false;

    requiredFields.forEach((field) => {
      if (!campaignDetails[field]) {
        setEmptyFieldWarnings((prevWarnings) => ({
          ...prevWarnings,
          [field]: true,
        }));
        hasEmptyField = true;
      }
    });

    if (!campaignDetails.compensation_type) {
      setEmptyFieldWarnings((prevWarnings) => ({
        ...prevWarnings,
        compensation_type: true,
      }));
      hasEmptyField = true;
    }

    if (!campaignDetails.budget) {
      setEmptyFieldWarnings((prevWarnings) => ({
        ...prevWarnings,
        budget: true,
      }));
      hasEmptyField = true;
    }

    if (hasEmptyField) {
      return;
    }

    if (currentStep === 1) {
      isedited
        ? dispatch(updateCampaign(campaignDetails))
        : dispatch(createCampaign(campaignDetails));
      console.log("campaign created succesfully", campaignDetails);
      toast.success("Campaign Created successfully", {
        position: "top-right",
        autoClose: 3000,
        bodyClassName: "custom-toast-body",
      });
    } else if (currentStep === 2) {
      editCampaign.age_group = selectedButtons;
      isedited
        ? dispatch(
            updateTargetAudienceforCampaign({
              state: editCampaign.state,
              city: editCampaign.city,
              gender: editCampaign.gender,
              language: editCampaign.language,
              age_group: selectedButtons,
              campaign_id: campaignDetails.campaign_id,
            })
          )
        : dispatch(
            setTargetAudienceForCampaign({
              state: selectedStates,
              city: storeCities,
              gender: storeGender,
              language: storeLanguages,
              age_group: selectedButtonTexts,
            })
          );
      toast.success("Target audience setted successfully", {
        position: "top-right",
        autoClose: 3000,
        bodyClassName: "custom-toast-body",
      });
    } else if (currentStep === 3) {
      isedited
        ? await dispatch(
            updateCampaignDates({
              start_date: startDate,
              end_date: endDate,
              timeline: weekData,
              campaign_id: campaignDetails.campaign_id,
            })
          )
        : await dispatch(
            setCampaignDates({
              start_date: startDate,
              end_date: endDate,
              timeline: weekData,
            })
          );

      toast.success("Campaign Dates set successfully", {
        position: "top-right",
        autoClose: 3000,
        bodyClassName: "custom-toast-body",
      });

      dispatch(getCampaignOverview(campaignDetails.campaign_id));
      console.log(editCampaign, "editCampaigneditCampaign");
    } else if (currentStep === 4) {
      if (isautomatciallyPublished === 0) {
        setIsPublished(1);
      }
      if (isedited) {
        dispatch(
          setPublishStatus({
            ispublished,
            isautomatciallyPublished,
            campaignId: campaignDetails.campaign_id,
          })
        );
      } else {
        dispatch(setPublishStatus({ ispublished, isautomatciallyPublished }));
      }
    }
    setCurrentStep(currentStep + 1);
  };

  useEffect(() => {
    if (ispublished)
      dispatch(setPublishStatus({ ispublished, isautomatciallyPublished }));
  }, [ispublished]);

  const isBackButtonDisabled = currentStep === 1;

  const handlePrev = () => {
    const activeStep = document.querySelector(
      `.stepper-step.active:not(.completed)`
    );
    if (activeStep) {
      activeStep.classList.remove("active");
    }

    setCurrentStep((prevStep) => prevStep - 1);
    if (!isBackButtonDisabled) {
      setCurrentStep(currentStep - 1);
    }
  };

  const defaultCountryCode = "IN";
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [storeLanguages, setStoredLanguage] = useState([]);
  const [storeStates, setStoredState] = useState([]);
  const [storeCities, setStoredCities] = useState([]);
  const [storeGender, setStoreGender] = useState([]);
  const [isStartDateValid, setIsStartDateValid] = useState(true);
  const [isEndDateValid, setIsEndDateValid] = useState(true);

  useEffect(() => {
    const fetchedStates = State.getStatesOfCountry(defaultCountryCode).map(
      (state) => ({
        value: state.isoCode,
        label: state.name,
      })
    );
    setStateOptions(fetchedStates);

    setSelectedStates([]);
    setSelectedCities([]);
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      borderColor: "#6B4750",
      border: "2px solid #6B4750",
      borderRadius: "8px",
      fontFamily: "Poppins",
      boxShadow: state.isFocused ? "none" : provided.boxShadow,
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid #6B4750",
      color: "#8B4513",
      fontFamily: "Poppins",
      backgroundColor: state.isFocused ? "#6B4750" : null,
      color: state.isFocused ? "white" : "#6B4750",
      "&:hover": {
        backgroundColor: "#6B4750",
        color: "white",
      },
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: "10px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      borderRadius: "10px",
    }),

    input: (provided) => ({
      ...provided,
      outline: "none",
      border: "none",
    }),
    select: (provided) => ({
      ...provided,
      outline: "none",
      border: "2px solid #8B4513",
      "&:hover": {
        outline: "none",
        border: "2px solid #8B4513",
      },
    }),
  };

  const handleStateChange = (selectedOptions) => {
    if (isedited) {
      setSelectedStates(selectedOptions);
      editCampaign.state = selectedOptions;
      setStoredState(selectedOptions.map((option) => option.value));
      setEmptyStateWarnings(false);
    } else {
      setSelectedStates(selectedOptions);
      const selectedValues = selectedOptions.map((option) => option.value);
      setStoredState(selectedValues);
      setEmptyStateWarnings(false);
    }
  };

  const handleCityChange = (selectedOptions) => {
    if (isedited) {
      editCampaign.city = selectedOptions;
      setStoredCities(selectedOptions.map((option) => option.value));
      setEmptyCityWarnings(false);
    } else {
      setSelectedCities(selectedOptions);
      const selectedValues = selectedOptions.map((option) => option.value);
      setStoredCities(selectedValues);
      setEmptyCityWarnings(false);
    }
  };

  const options = {
    genders: [
      { value: "All", label: "All" },
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
      { value: "trans", label: "Trans" },
    ],
    languages: [
      { value: "All", label: "All" },
      { value: "Hindi", label: "Hindi" },
      { value: "English", label: "English" },
      { value: "Punjabi", label: "Punjabi" },
      { value: "Gujarati", label: "Gujarati" },
      { value: "Marathi", label: "Marathi" },
      { value: "Telugu", label: "Telugu" },
      { value: "Tamil", label: "Tamil" },
      { value: "Malayalam", label: " Malayalam" },
      { value: "Kannada", label: "Kannada" },
    ],
  };
  useEffect(() => {
    const fetchedCities = isedited
      ? editCampaign.state?.length > 0
        ? [...(isedited ? editCampaign.state : selectedStates)].flatMap(
            (state) =>
              City.getCitiesOfState(defaultCountryCode, state.value).map(
                (city) => ({
                  value: city.name,
                  label: city.name,
                })
              )
          )
        : []
      : selectedStates.flatMap((state) =>
          City.getCitiesOfState(defaultCountryCode, state.value).map(
            (city) => ({
              value: city.name,
              label: city.name,
            })
          )
        );
    setCityOptions(
      isedited
        ? editCampaign.state?.length > 0
          ? fetchedCities
          : []
        : fetchedCities
    );
  }, [selectedStates]);

  const [selectedGenders, setSelectedGenders] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const handleGenderChange = (selectedOptions) => {
    if (isedited) {
      editCampaign.gender = selectedOptions;
      setStoreGender(selectedOptions.map((option) => option.value));
      setEmptyGenderWarnings(false);
    } else {
      setSelectedGenders(selectedOptions);
      const selectedValues = selectedOptions.map((option) => option.value);
      setStoreGender(selectedValues);
      setEmptyGenderWarnings(false);
    }
  };

  const handleLanguageChange = (selectedOptions) => {
    if (isedited) {
      editCampaign.language = selectedOptions;
      setStoredLanguage(selectedOptions.map((option) => option.value));
      setEmptyLangWarnings(false);
    } else {
      setSelectedLanguages(selectedOptions);
      const selectedValues = selectedOptions.map((option) => option.value);
      setStoredLanguage(selectedValues);
      setEmptyLangWarnings(false);
    }
  };

  const buttonData = [
    "13-17",
    "18-24",
    "25-34",
    "35-44",
    "45-54",
    "55-64",
    "65+",
    "All",
  ];

  const handleButtonClick = (buttonId, buttonText) => {
    if (selectedButtons.includes(buttonId)) {
      setSelectedButtons(selectedButtons.filter((id) => id !== buttonId));
      setSelectedButtonTexts(
        selectedButtonTexts.filter((text) => text !== buttonText)
      );
      setTargetAudience((prevDetails) => ({
        ...prevDetails,
        age_group: selectedButtonTexts.filter((text) => text !== buttonText),
      }));
    } else {
      setSelectedButtons([...selectedButtons, buttonId]);
      setSelectedButtonTexts([...selectedButtonTexts, buttonText]);
      setTargetAudience((prevDetails) => ({
        ...prevDetails,
        age_group: [...selectedButtonTexts, buttonText],
      }));
    }
    setEmptyAgeWarnings(false);
  };

  function formatDateRange(startDate, endDate) {
    const options = { day: "numeric", month: "long", year: "numeric" };
    const start = new Date(startDate).toLocaleDateString("en-US", options);
    const end = new Date(endDate).toLocaleDateString("en-US", options);

    const startDay = new Date(startDate).getDate();
    const startMonth = new Date(startDate).toLocaleDateString("en-US", {
      month: "short",
    });
    const startYear = new Date(startDate).getFullYear();

    const endDay = new Date(endDate).getDate();
    const endMonth = new Date(endDate).toLocaleDateString("en-US", {
      month: "short",
    });
    const endYear = new Date(endDate).getFullYear();

    return `From ${startDay}th ${startMonth} to ${endDay}th ${endMonth} ${endYear}`;
  }

  const generateSummary = () => {
    const summaries = weekData?.map((week) => {
      const youtubeTotal = Object.values(week.YouTube).reduce(
        (acc, val) => acc + val,
        0
      );
      const instagramTotal = Object.values(week.Instagram).reduce(
        (acc, val) => acc + val,
        0
      );

      return (
        <div key={week.week}>
          <h3 className="weeks">
            {displayType === "day"
              ? `Day`
              : displayType === "week"
              ? `Week`
              : `Month`}
            {week.week}
          </h3>
          <ul>
            {(week.YouTube.shorts > 0 ||
              week.YouTube.videos > 0 ||
              week.YouTube.live_stream > 0) && (
              <li className="li-content">
                {(week.YouTube.shorts > 0 ||
                  week.YouTube.videos > 0 ||
                  week.YouTube.live_stream > 0) &&
                  `YouTube -`}
                {week.YouTube.shorts > 0 && ` Shorts: ${week.YouTube.shorts},`}
                {week.YouTube.videos > 0 && ` Videos: ${week.YouTube.videos},`}
                {week.YouTube.live_stream > 0 &&
                  ` Live Stream: ${week.YouTube.live_stream},`}
              </li>
            )}
            {(week.Instagram.posts > 0 ||
              week.Instagram.reels > 0 ||
              week.Instagram.story > 0) && (
              <li className="li-content">
                {(week.Instagram.posts > 0 ||
                  week.Instagram.reels > 0 ||
                  week.Instagram.story > 0) &&
                  `Instagram -`}
                {week.Instagram.posts > 0 && ` Posts: ${week.Instagram.posts},`}
                {week.Instagram.reels > 0 && ` Reels: ${week.Instagram.reels},`}
                {week.Instagram.story > 0 && ` Story: ${week.Instagram.story},`}
              </li>
            )}
          </ul>
        </div>
      );
    });

    return summaries;
  };

  const ageGroups = isedited
    ? editCampaign?.age_group || []
    : campaign?.age_group || [];

  let minAge = Infinity;
  let maxAge = -Infinity;
  let all = "";

  ageGroups.forEach((ageRange) => {
    if (ageRange === "All") {
      all = "All";
    } else {
      const [start, end] =
        ageRange?.split("-")?.length === 1
          ? [minAge.toString(), ageRange?.split("+")?.at(0)]
          : ageRange.split("-");
      const startAge = parseInt(start, 10);
      const endAge =
        end?.split("+")?.length === 2
          ? parseInt(end?.split("+")?.at(0))
          : parseInt(end);

      minAge = Math.min(minAge, startAge);
      maxAge = Math.max(maxAge, endAge);
    }
  });

  const consolidatedAgeRange = all !== "" ? all : `${minAge}-${maxAge}`;

  useEffect(() => {
    if (isCampaignCreated) {
      console.log("campaign created succesfully");
    }
  }, [isCampaignCreated]);

  useEffect(() => {
    if (isTargetAudienceSetted) {
      console.log("audience set succesfully");
    }
  }, [isTargetAudienceSetted]);

  useEffect(() => {
    if (isCampaignDatesSetted) {
      console.log("dates seted successfully");
    }
  }, [isCampaignDatesSetted]);

  const handleDataForCase1 = async () => {
    console.log("entred for save as draft");
    await dispatch(createCampaign(campaignDetails));
    navigate("/campaign");
  };

  const handleDataForCase2 = async () => {
    await dispatch(
      setTargetAudienceForCampaign({
        state: selectedStates,
        city: storeCities,
        gender: storeGender,
        language: storeLanguages,
        age_group: selectedButtonTexts,
      })
    );
    navigate("/campaign");
  };

  const handleDataForCase3 = async () => {
    await dispatch(
      setCampaignDates({
        start_date: startDate,
        end_date: endDate,
        timeline: weekData,
      })
    );

    await dispatch(getCampaignOverview());
    navigate("/campaign");
  };

  const handleDataForCase4 = async () => {
    await dispatch(setPublishStatus({ ispublished, isautomatciallyPublished }));
    navigate("/campaign");
  };

  const handleUploadClick = () => {
    document.getElementById("campaignPoster").click();
  };

  const currentDate = new Date().toISOString().split("T")[0];

  const handleChangeStartDate = (e) => {
    setIsStartDateValid(true);
    setStartDate(e.target.value);
  };

  const handleChangeEndDate = (e) => {
    setIsEndDateValid(true);
    setEndDate(e.target.value);
  };

  useEffect(() => {
    if (isedited && editCampaign.timeline && editCampaign.timeline.length > 0) {
      const selectedWeekData = editCampaign.timeline[0];
      if (selectedWeekData) {
        const instagramData = selectedWeekData.Instagram || {};
        const youtubeData = selectedWeekData.YouTube || {};

        setOptions1([
          instagramData.reels || 0,
          instagramData.posts || 0,
          instagramData.story || 0,
        ]);

        setOptions2([
          youtubeData.shorts || 0,
          youtubeData.videos || 0,
          youtubeData.live_stream || 0,
        ]);
      }
    }
  }, [isedited, editCampaign.timeline]);

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <div className="campaign-details-form">
              <h3>Campaign</h3>

              <div className="file-input">
                <label htmlFor="campaignPoster">
                  Campaign Poster <span style={{ color: "red" }}>*</span>
                </label>

                <div className="image-input-box" onClick={handleUploadClick}>
                  <div className="image-wrapper">
                    <img src={FileIcon} alt="file-icon" />
                  </div>

                  <input
                    type="file"
                    id="campaignPoster"
                    accept=".jpg, .png"
                    style={{ display: "none" }}
                    onChange={(e) =>
                      handleInputChange("campaign_poster", e.target.files[0])
                    }
                  />

                  <span>
                    Drag & Drop or <span className="browse">Browse</span>
                  </span>
                  <small>
                    Supported files: <span className="jpg-png">JPG, PNG</span>
                  </small>
                  <small>File size should not exceed 1MB</small>
                </div>
              </div>

              <div className="campaign-row">
                <div className="input-row-labels">
                  <label htmlFor="title">
                    Campaign Title <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    placeholder="Give a catchy and suitable title"
                    type="text"
                    id="title"
                    value={campaignDetails.campaign_title}
                    onChange={(e) =>
                      handleInputChange("campaign_title", e.target.value)
                    }
                  />
                  {emptyFieldWarnings.campaign_title && (
                    <small className="warning">
                      <WarningIcon className="warning-icon" />
                      Oops,You missed this one. Please enter it!
                    </small>
                  )}
                </div>
                <div className="input-row-labels">
                  <label htmlFor="Objective">
                    Objective <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    placeholder="Increase brand awareness, drive website traffic, generate leads, boost sales"
                    type="text"
                    id="Objective"
                    value={campaignDetails.campaign_obj}
                    onChange={(e) =>
                      handleInputChange("campaign_obj", e.target.value)
                    }
                  />
                  {emptyFieldWarnings.campaign_obj && (
                    <small className="warning">
                      <WarningIcon className="warning-icon" />
                      Oops,You missed this one. Please enter it!
                    </small>
                  )}
                </div>
              </div>

              <div className="description-box">
                <label htmlFor="description">
                  Description <span style={{ color: "red" }}>*</span>
                </label>
                <textarea
                  placeholder="Describe your campaign"
                  id="description"
                  value={campaignDetails.description}
                  onChange={(e) =>
                    handleInputChange("description", e.target.value)
                  }
                />
                {emptyFieldWarnings.description && (
                  <small className="warning">
                    <WarningIcon className="warning-icon" />
                    Oops,You missed this one. Please enter it!
                  </small>
                )}
              </div>
              <div className="payment">
                <h3>Payment and Compensation</h3>
                <div className="payment-info-box-wrapper">
                  <div className="payment-input">
                    <label htmlFor="Compensation">
                      Compensation Type <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      id="Compensation"
                      value={campaignDetails.compensation_type}
                      onChange={(e) =>
                        handleInputChange("compensation_type", e.target.value)
                      }
                    >
                      <option value="" disabled selected>
                        Flat fee, commission, product
                      </option>
                      <option value="type1">type1</option>
                      <option value="type2">type2</option>
                      <option value="type3">type3</option>
                    </select>
                    {emptyFieldWarnings.compensation_type && (
                      <small className="warning">
                        <WarningIcon className="warning-icon" />
                        Oops,You missed this one. Please enter it!
                      </small>
                    )}
                  </div>

                  <div className="payment-input">
                    <label htmlFor="budget">
                      Budget for the campaign{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      id="budget"
                      value={campaignDetails.budget}
                      onChange={(e) =>
                        handleInputChange("budget", e.target.value)
                      }
                    >
                      <option value="" disabled selected>
                        Select budget
                      </option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                      <option value="2000">2000</option>
                    </select>
                    {emptyFieldWarnings.budget && (
                      <small className="warning">
                        <WarningIcon className="warning-icon" />
                        Oops,You missed this one. Please enter it!
                      </small>
                    )}
                  </div>
                </div>
              </div>

              <button className="change-form-page-button" onClick={handleNext}>
                Next
                <img src={NextArrow} alt="back" />
              </button>
              <button
                className="save-form-page-button"
                onClick={handleDataForCase1}
              >
                Save
              </button>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <div className="section-heading">Target Audience</div>

            <div className="select-group-one">
              <div className="select-input">
                <label htmlFor="field1">
                  State <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  isMulti
                  value={isedited ? editCampaign.state : selectedStates}
                  onChange={handleStateChange}
                  options={[{ value: "all", label: "All" }, ...stateOptions]} // Add "All" option
                  styles={customStyles}
                  closeMenuOnSelect={false}
                />
                {emptyStateWarnings.state && (
                  <small className="warning">
                    <WarningIcon className="warning-icon" />
                    Oops, You missed this one. Please select at least one state!
                  </small>
                )}
              </div>

              <div className="select-input">
                <label htmlFor="field2">
                  City <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  isMulti
                  value={isedited ? editCampaign.city : selectedCities}
                  onChange={handleCityChange}
                  options={[{ value: "all", label: "All" }, ...cityOptions]} // Add "All" option
                  styles={customStyles}
                  closeMenuOnSelect={false}
                />
                {emptyCityWarnings.city && (
                  <small className="warning">
                    <WarningIcon className="warning-icon" />
                    Oops, You missed this one. Please select at least one city!
                  </small>
                )}
              </div>
            </div>

            <div className="select-group-two">
              <div className="select-input">
                <label htmlFor="field3">
                  Gender <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  isMulti
                  value={isedited ? editCampaign.gender : selectedGenders}
                  onChange={handleGenderChange}
                  options={options.genders}
                  styles={customStyles}
                  closeMenuOnSelect={false}
                />
                {emptyGenderWarnings.gender && (
                  <small className="warning">
                    <WarningIcon className="warning-icon" />
                    Oops, You missed this one. Please select at least one
                    gender!
                  </small>
                )}
              </div>

              <div className="select-input">
                <label htmlFor="field4">
                  Language <span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  isMulti
                  value={isedited ? editCampaign.language : selectedLanguages}
                  onChange={handleLanguageChange}
                  options={options.languages}
                  styles={customStyles}
                  closeMenuOnSelect={false}
                />
                {emptyLangWarnings.language && (
                  <small className="warning">
                    <WarningIcon className="warning-icon" />
                    Oops, You missed this one. Please select at least one
                    language!
                  </small>
                )}
              </div>
            </div>

            <div className="age-group">
              <label htmlFor="ageGroup">
                Age Group <span style={{ color: "red" }}>*</span>
              </label>
              <div className="button-group">
                {buttonData.map((button) => (
                  <button
                    key={button}
                    className={
                      selectedButtons.includes(button) ? "selected" : ""
                    }
                    onClick={() => handleButtonClick(button, button)}
                  >
                    {button}
                  </button>
                ))}
              </div>
              {emptyAgeWarnings.ageGroup && (
                <small className="warning">
                  <WarningIcon className="warning-icon" />
                  Oops, You missed this one. Please select at least one age
                  group!
                </small>
              )}
            </div>

            <div className="button-section">
              <button
                className="save-form-page-button"
                onClick={handleDataForCase2}
              >
                Save
              </button>
              <button
                onClick={handleNextButtonClick}
                className="change-form-page-button"
              >
                Next
                <img src={NextArrow} alt="back" />
              </button>
            </div>
          </div>
        );

      case 3:
        return (
          <div>
            <div className="deliverables-container">
              <div className="deliverables-section">
                <div className="campaign-date">
                  <label>Campaign Start Date</label>
                  <CssTextField
                    id="date1"
                    type="date"
                    className="date-input"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={startDate || ""}
                    onChange={handleChangeStartDate}
                    inputProps={{ min: currentDate }} // Set minimum date
                  />
                  {!isStartDateValid && (
                    <small className="warning">
                      <WarningIcon className="warning-icon" />
                      Oops, the start date cannot be earlier than today. Please
                      select a valid date!
                    </small>
                  )}
                </div>

                <div className="campaign-date">
                  <label>Campaign End Date</label>
                  <CssTextField
                    id="date2"
                    type="date"
                    className="date-input"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    value={endDate || ""}
                    onChange={handleChangeEndDate}
                    inputProps={{ min: startDate || currentDate }} // Set minimum date to start date or current date
                  />
                  {!isEndDateValid && (
                    <small className="warning">
                      <WarningIcon className="warning-icon" />
                      Oops, the end date cannot be earlier than the start date.
                      Please select a valid date!
                    </small>
                  )}
                </div>
              </div>
              {loading && (
                <div className="loadingContainer">
                  <img src={GIF} alt="loading" />
                  <div className="loadingText">
                    Building Your Custom Timeline
                  </div>
                </div>
              )}

              {!loading && startDate && endDate && NumberOfSteps && (
                <div className="radio-button-counter-container">
                  <div className="frame">
                    <div className="navbar">
                      {Array.from({ length: NumberOfSteps }, (_, index) => (
                        <div
                          key={index + 1}
                          className={`text-wrapper-${index + 1} ${
                            selectedWeek === index + 1 ? "selected-week" : ""
                          }`}
                          onClick={() => handleWeekClick(index)}
                        >
                          {displayType === "day"
                            ? `Day ${index + 1}`
                            : displayType === "week"
                            ? `Week ${index + 1}`
                            : `Month ${index + 1}`}
                        </div>
                      ))}
                    </div>

                    <div className="group">
                      <div className="group-2">
                        <div className="text-wrapper-8">End</div>
                        <img className="polygon" alt="Polygon" src={Polygon} />
                      </div>
                      {Array.from({ length: NumberOfSteps }, (_, index) => (
                        <div
                          key={index + 1}
                          className={`text-wrapper-${index + 1}`}
                        >
                          <img className="img" alt="Polygon" src={Polygon} />
                        </div>
                      ))}
                      <div className="group-3">
                        <div className="text-wrapper-8"> Start </div>
                        <img
                          className="polygon-8"
                          alt="Polygon"
                          src={Polygon}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="promotion-box">
                    <div className="radio-button-counter-group">
                      <div>
                        <h3>Instagram</h3>
                        <div className="radio-row">
                          <label>
                            {/* <input type="radio" name="group1" /> */}
                            Reels
                          </label>
                          <div className="counter">
                            <button
                              onClick={() =>
                                handleCounterChange(1, 0, -1, "reels")
                              }
                            >
                              -
                            </button>
                            <span>{options1[0]}</span>
                            <button
                              onClick={() =>
                                handleCounterChange(1, 0, 1, "reels")
                              }
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className="radio-row">
                          <label>
                            {/* <input type="radio" name="group1" /> */}
                            Posts
                          </label>
                          <div className="counter">
                            <button
                              onClick={() =>
                                handleCounterChange(1, 1, -1, "posts")
                              }
                            >
                              -
                            </button>
                            <span>{options1[1]}</span>
                            <button
                              onClick={() =>
                                handleCounterChange(1, 1, 1, "posts")
                              }
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className="radio-row">
                          <label>
                            {/* <input type="radio" name="group1" /> */}
                            Story
                          </label>
                          <div className="counter">
                            <button
                              onClick={() =>
                                handleCounterChange(1, 2, -1, "story")
                              }
                            >
                              -
                            </button>
                            <span>{options1[2]}</span>
                            <button
                              onClick={() =>
                                handleCounterChange(1, 2, 1, "story")
                              }
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="radio-button-counter-group">
                      <div>
                        <h3>Youtube</h3>
                        <div className="radio-row">
                          <label>
                            {/* <input type="radio" name="group2" /> */}
                            Shorts
                          </label>
                          <div className="counter">
                            <button
                              onClick={() =>
                                handleCounterChange(2, 0, -1, "shorts")
                              }
                            >
                              -
                            </button>
                            <span>{options2[0]}</span>
                            <button
                              onClick={() =>
                                handleCounterChange(2, 0, 1, "shorts")
                              }
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className="radio-row">
                          <label>
                            {/* <input type="radio" name="group2" /> */}
                            Video
                          </label>
                          <div className="counter">
                            <button
                              onClick={() =>
                                handleCounterChange(2, 1, -1, "video")
                              }
                            >
                              -
                            </button>
                            <span>{options2[1]}</span>
                            <button
                              onClick={() =>
                                handleCounterChange(2, 1, 1, "video")
                              }
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className="radio-row">
                          <label>
                            {/* <input type="radio" name="group2" /> */}
                            Live Stream
                          </label>
                          <div className="counter">
                            <button
                              onClick={() =>
                                handleCounterChange(2, 2, -1, "live")
                              }
                            >
                              -
                            </button>
                            <span>{options2[2]}</span>
                            <button
                              onClick={() =>
                                handleCounterChange(2, 2, 1, "live")
                              }
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="summary-container">
                    <div className="label">
                      <label className="summary">Summary</label>
                      {generateSummary()}
                    </div>
                  </div>
                </div>
              )}

              <div className="button-section">
                <button
                  className="save-form-page-button"
                  disabled={isNextButtonDisabled()}
                  onClick={handleDataForCase3}
                >
                  Save
                </button>
                <button
                  onClick={handleNext}
                  className="change-form-page-button"
                >
                  Next
                  <img src={NextArrow} alt="back" />
                </button>
              </div>
            </div>
          </div>
        );

      case 4:
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              minHeight: "100vh",
              padding: 2,
              backgroundColor: "#f5f5f5",
            }}
          >
            <Card
              sx={{
                width: "100%",
                boxShadow: 3,
                borderRadius: 2,
                backgroundColor: "white",
              }}
            >
              <CardContent>
                <Grid container spacing={2}>
                  {/* Campaign Name */}
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      sx={{ color: "#D72756", padding: "6px" }}
                    >
                      {/* Name: */}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {campaign?.campaign_title}
                    </Typography>
                  </Grid>

                  {/* Campaign Objective */}
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      sx={{ color: "#D72756", padding: "6px" }}
                    >
                      {/* Objective: */}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {campaign?.campaign_obj}
                    </Typography>
                  </Grid>

                  {/* Campaign Description */}
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      sx={{ color: "#D72756", padding: "6px" }}
                    >
                      {/* Description: */}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {campaign?.description}
                    </Typography>
                  </Grid>

                  {/* Compensation Type and Budget */}
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      sx={{ color: "#D72756", padding: "6px" }}
                    >
                      Compensation:
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={Hand}
                        alt="Hand"
                        width={20}
                        height={20}
                        style={{ marginRight: 8 }}
                      />
                      <Typography variant="body1" color="text.secondary">
                        {campaign?.compensation_type}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{ marginLeft: 1 }}
                      >
                        •
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        {campaign?.budget}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Expected Deliverables */}
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      sx={{ color: "#D72756", padding: "6px" }}
                    >
                      Expected Deliverables:
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={Mediainfo}
                        alt="Mediainfo"
                        width={20}
                        height={20}
                        style={{ marginRight: 8 }}
                      />
                      <Typography variant="body1" color="text.secondary">
                        {formattedYouTubeCounts &&
                          `${formattedYouTubeCounts} &`}{" "}
                        {formattedInstagramCounts}
                      </Typography>
                      {(formattedYouTubeCounts || formattedInstagramCounts) && (
                        <Typography
                          variant="body1"
                          color="text.secondary"
                          sx={{ marginLeft: 1 }}
                        >
                          •
                        </Typography>
                      )}
                      <Typography variant="body1" color="text.secondary">
                        {campaign
                          ? formatDateRange(
                              campaign?.start_date,
                              campaign?.end_date
                            )
                          : ""}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Target Audience */}
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      sx={{ color: "#D72756", padding: "6px" }}
                    >
                      Target Audience:
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={Audience}
                        alt="Audience"
                        width={20}
                        height={20}
                        style={{ marginRight: 8 }}
                      />
                      <Typography variant="body1" color="text.secondary">
                        {campaign?.age_group &&
                          campaign.age_group.length > 0 &&
                          `Age: ${consolidatedAgeRange}`}
                      </Typography>
                      {campaign?.age_group && campaign.age_group.length > 0 && (
                        <Typography
                          variant="body1"
                          color="text.secondary"
                          sx={{ marginLeft: 1 }}
                        >
                          •
                        </Typography>
                      )}
                      <Typography variant="body1" color="text.secondary">
                        {isedited
                          ? editCampaign
                            ? "Gender: " +
                              formatGenders(
                                editCampaign.gender.map((ele) =>
                                  ele.value === undefined ? ele : ele.value
                                )
                              )
                            : ""
                          : campaign
                          ? "Gender: " + formatGenders(campaign.gender)
                          : ""}
                      </Typography>
                      {campaign?.gender && campaign.gender.length > 0 && (
                        <Typography
                          variant="body1"
                          color="text.secondary"
                          sx={{ marginLeft: 1 }}
                        >
                          •
                        </Typography>
                      )}
                      <Typography variant="body1" color="text.secondary">
                        {user?.area}
                      </Typography>
                      {campaign?.language && campaign.language.length > 0 && (
                        <Typography
                          variant="body1"
                          color="text.secondary"
                          sx={{ marginLeft: 1 }}
                        >
                          •
                        </Typography>
                      )}
                      <Typography variant="body1" color="text.secondary">
                        {isedited
                          ? editCampaign
                            ? "Language: " +
                              formatLanguages(
                                editCampaign.language.map((ele) =>
                                  ele.value === undefined ? ele : ele.value
                                )
                              )
                            : ""
                          : campaign
                          ? "Language: " + formatLanguages(campaign.language)
                          : ""}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>

              <Divider />

              {/* Publish Options */}
              <CardActions
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleDataForCase4}
                  disabled={isNextButtonDisabled()}
                  sx={{ flex: 1 }}
                >
                  Save Draft
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  sx={{ flex: 1 }}
                >
                  Publish
                  <img
                    src={NextArrow}
                    alt="Next Arrow"
                    style={{ marginLeft: 8 }}
                  />
                </Button>
              </CardActions>
            </Card>
          </Box>
        );
      case 5:
        navigate("/campaign");
        break;
      default:
        return null;
    }
  };

  return (
    <div className="campaign-wrapper-container">
      {/* <Link to="/campaign" className="back-to-campaign-home-button">
        <img src={BackArrow} alt="back" />
        Back
      </Link> */}
      {currentStep > 1 && (
        <button className="back-to-campaign-home-button" onClick={handlePrev}>
          <img src={BackArrow} alt="back" />
          Back
        </button>
      )}

      <div className="form-container">
        <Stepper
          currentStep={currentStep}
          completedSteps={completedSteps || []} // Ensure completedSteps is not null or undefined
          steps={steps}
          onStepClick={(step) => setCurrentStep(step.id)}
          onNextClick={handleNext}
          onPrevClick={handlePrev}
        />
        <div className="form">{renderStepContent()}</div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Form;

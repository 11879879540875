import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./ProfileSetup.scss";

const ProfileSetup = () => {
  const [step, setStep] = useState(false);

  // Validation Schema using Yup
  const validationSchema = Yup.object({
    brandName: Yup.string().required("Brand's Name is required"),
    category: Yup.string().required("Brand's Category is required"),
    flat: Yup.string(),
    area: Yup.string().required("Area, Street, Sector is required"),
    state: Yup.string().required("State is required"),
    pinCode: Yup.string()
      .matches(/^\d{6}$/, "PIN Code must be 6 digits")
      .required("PIN Code is required"),
    brandBio: Yup.string().required("Brand's Bio is required"),
    website: Yup.string().url("Invalid website URL").optional(),
    socialLinks: Yup.array().of(Yup.string().url("Invalid URL")),
  });

  const formik = useFormik({
    initialValues: {
      brandName: "",
      category: "",
      flat: "",
      area: "",
      state: "",
      pinCode: "",
      brandBio: "",
      website: "",
      socialLinks: [""],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const Next = () => {
    setStep(true);
  };

  return (
    <div className="ProfileSetUp">
      <div className="row g-0">
        <div className="col-12">
          <h1 className="text-center mb-4">Let's setup your Profile!</h1>

          {/* Logo Upload */}
          <div className="text-center mb-5">
            <div className="logo-circle mb-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="white"
                viewBox="0 0 16 16"
              >
                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
              </svg>
            </div>
            <small className="text-muted">Add logo</small>
          </div>

          <form onSubmit={formik.handleSubmit}>
            {step === false ? (
              <>
                {/* Brand Information */}
                <div className="row mb-4">
                  <div className="col-md-6 mb-3 mb-md-0">
                    <label className="form-label">Your Brand's name</label>
                    <input
                      type="text"
                      className={`form-control ${
                        formik.touched.brandName && formik.errors.brandName
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Brand's Name"
                      {...formik.getFieldProps("brandName")}
                    />
                    {formik.touched.brandName && formik.errors.brandName ? (
                      <div className="invalid-feedback">
                        {formik.errors.brandName}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">
                      Select your Brand's Category
                    </label>
                    <select
                      className={`form-select ${
                        formik.touched.category && formik.errors.category
                          ? "is-invalid"
                          : ""
                      }`}
                      {...formik.getFieldProps("category")}
                    >
                      <option defaultValue>Select a Category</option>
                      <option value="1">Category 1</option>
                      <option value="2">Category 2</option>
                      <option value="3">Category 3</option>
                    </select>
                    {formik.touched.category && formik.errors.category ? (
                      <div className="invalid-feedback">
                        {formik.errors.category}
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* Location */}
                <div className="mb-4">
                  <h5 className="mb-3">Location</h5>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-label">
                        Flat, Building, Apartment{" "}
                        <span className="text-muted">(Optional)</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name of your Flat, Building, Apartment............"
                        {...formik.getFieldProps("flat")}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Area, Street, Sector</label>
                      <input
                        type="text"
                        className={`form-control ${
                          formik.touched.area && formik.errors.area
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Name of your Area, Street, Sector............"
                        {...formik.getFieldProps("area")}
                      />
                      {formik.touched.area && formik.errors.area ? (
                        <div className="invalid-feedback">
                          {formik.errors.area}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">State</label>
                      <select
                        className={`form-select ${
                          formik.touched.state && formik.errors.state
                            ? "is-invalid"
                            : ""
                        }`}
                        {...formik.getFieldProps("state")}
                      >
                        <option defaultValue>Select your State</option>
                        <option value="1">State 1</option>
                        <option value="2">State 2</option>
                        <option value="3">State 3</option>
                      </select>
                      {formik.touched.state && formik.errors.state ? (
                        <div className="invalid-feedback">
                          {formik.errors.state}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">PIN Code</label>
                      <input
                        type="text"
                        className={`form-control ${
                          formik.touched.pinCode && formik.errors.pinCode
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="6 Digit PIN Code"
                        {...formik.getFieldProps("pinCode")}
                      />
                      {formik.touched.pinCode && formik.errors.pinCode ? (
                        <div className="invalid-feedback">
                          {formik.errors.pinCode}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* Brand Bio */}
                <div className="mb-4">
                  <label className="form-label">Brand's Bio</label>
                  <textarea
                    className={`form-control ${
                      formik.touched.brandBio && formik.errors.brandBio
                        ? "is-invalid"
                        : ""
                    }`}
                    rows="4"
                    placeholder="Write your Brand's Bio..."
                    {...formik.getFieldProps("brandBio")}
                  />
                  {formik.touched.brandBio && formik.errors.brandBio ? (
                    <div className="invalid-feedback">
                      {formik.errors.brandBio}
                    </div>
                  ) : null}
                </div>
              </>
            ) : (
              <>
                <div className="col-md-12 mb-3 mb-md-0">
                  <label className="form-label">
                    Your Brand’s Website (Optional)
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formik.touched.website && formik.errors.website
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Brand's Website"
                    {...formik.getFieldProps("website")}
                  />
                  {formik.touched.website && formik.errors.website ? (
                    <div className="invalid-feedback">
                      {formik.errors.website}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-8 mb-3 mt-3">
                  <label className="form-label">
                    Brand’s Social media Links
                  </label>
                  <div className="AddSocial">
                    <input
                      type="text"
                      className={`form-control ${
                        formik.touched.socialLinks && formik.errors.socialLinks
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Social Media Link"
                      {...formik.getFieldProps("socialLinks[0]")}
                    />
                    {formik.touched.socialLinks && formik.errors.socialLinks ? (
                      <div className="invalid-feedback">
                        {formik.errors.socialLinks[0]}
                      </div>
                    ) : null}
                    <div className="MoreLinks">
                      <span>+</span> Add More Links
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Action Buttons */}
            <div className="d-flex justify-content-between align-items-center">
              <button type="button" className="btn clear-all">
                Clear all
              </button>
              <div className="d-flex gap-2">
                <button
                  type="button"
                  className="btn btn-outline-success px-4"
                  onClick={() => console.log(formik.values)}
                >
                  Save
                </button>
                {step === false ? (
                  <button
                    type="submit"
                    className="btn btn-success px-4"
                    onClick={Next}
                    disabled={!(formik.isValid && formik.dirty)} // Disable if form is not valid or hasn't been modified
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-success px-4"
                    onClick={Next}
                  >
                    Finish
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfileSetup;
  
import React, { useEffect, useState } from "react";
import "./CommonLogin.scss";
import Flag from "../assets/Flag Of India.svg";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  sendOtp,
  verifyPhoneOtp,
  sendOtpEmail,
  verifyEmailOtp,
  setEmailInRedux,
  loadUser,
} from "../../actions/userAction";
import Loader from "../layout/Loader/Loader";
import Navbar from "../LandingComponents/Navbar/Navbar";
import loginimg from "../assets/loginsideimg.png";
import Footer from "../LandingComponents/Footer/Footer";

const LoginHome = ({ onClick }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const emailVerificationSuccess = useSelector(
    (state) => state.user?.user?.isOtpSendSuccessfully
  );
  const isLoading = useSelector((state) => state.user.loading);

  const handleLoginWithEmail = () => {
    dispatch(setEmailInRedux(email)); // Dispatch the action to set the email in Redux
    dispatch(sendOtpEmail({ email }));
    onClick("Loginemail", email);
  };

  useEffect(() => {
    if (emailVerificationSuccess) {
      onClick("Loginemail", email);
    }
  }, [emailVerificationSuccess, onClick, email, setEmail]);
  const navigate = useNavigate();

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Navbar />
          <div className="container-login p-0 mt-5 mb-5">
            <div className="card">
              <div className="row no-gutters">
                {/* Left Side (Image Section) */}
                <div className="col-12 col-md-6 col-sm-12 col-xs-12 left">
                  <img src={loginimg} alt="loginimg" className="img" />
                </div>

                {/* Right Side (Login and Signup Buttons) */}
                <div className="col-12 col-md-6 col-sm-12 col-xs-12 right">
                  <h2>Welcome to InSocial Orbit</h2>
                  <button
                    type="button"
                    className="btn btn-success btn-lg mb-2"
                    onClick={() => navigate("/phone-email")} // Navigate to SignupPage with login action
                  >
                    Login
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-success btn-lg"
                    onClick={() => navigate("/signUp")} // Navigate to SignupPage with signup action
                  >
                    Signup
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Footer/>
        </>
      )}
    </>
  );
};

// const SignupPage = ({ onClick }) => {
//   const location = useLocation();
//   const params = new URLSearchParams(location.search);
//   const action = params.get("action"); // Get the "action" query parameter

//   return (
//     <>
//       <div className="container-signup p-0">
//       <div className="card">
//         <div className="row no-gutters">
//           {/* Left Side (Image Section) */}
//           <div className="col-12 col-md-6 left">
//             <img src={loginimg} alt="Image" className="img" />
//           </div>

//           {/* Right Side: Dynamic Content Based on `action` */}
//           <div className="col-12 col-md-6 right">
//             {action === "login" ? (
//               <>
//                 <h2>Welcome Back to InSocial Orbit</h2>
//                 <button type="button" className="btn btn-success btn-lg mb-2">
//                   Login with Email
//                 </button>
//                 <button type="button" className="btn btn-outline-success btn-lg">
//                   Login with Phone
//                 </button>
//               </>
//             ) : (
//               <>
//                 <h2>Join InSocial Orbit</h2>
//                 <button type="button" className="btn btn-success btn-lg mb-2">
//                   Sign up as a Brand
//                 </button>
//                 <button type="button" className="btn btn-outline-success btn-lg">
//                   Sign up as an Influencer
//                 </button>
//               </>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//     </>
//   );
// };

const LoginEmailVerification = ({ onClick }) => {
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.loading);
  const { user, success, isOtpVerified } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const userEmail = useSelector((state) => state.email?.userEmail);

  const handleVerifyEmailOtp = () => {
    dispatch(verifyEmailOtp({ otp }));
  };

  useEffect(() => {
    if (user && user.isLoginSuccess) {
      navigate("/home");
    }
  }, [user, navigate]);

  useEffect(() => {
    if (isOtpVerified) {
      dispatch(loadUser());
      navigate("/home");
    }
  }, [isOtpVerified]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="login-page">
          <div className="logo">
            <span className="brand-text-login">Insocial Orbit</span>
          </div>

          <div className="header-text">
            <h1>Collaborate.</h1>
            <h1>Create. Connect</h1>
          </div>
          <div className="email-login-details">
            <label>Your E-mail Address</label>
            <input
              id="login-email-otp"
              type="text"
              placeholder={userEmail}
              readOnly
            />
            <label>Enter OTP</label>
            <input
              id="login-email-otp"
              type="text"
              value={otp}
              maxLength={6}
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>

          <button
            className="login-otp-submit-button"
            onClick={handleVerifyEmailOtp}
          >
            Submit
          </button>
          {/* <div className="bottom-text">
            <p>
              <button
                className="navigate-phone-email-button"
                onClick={() => onClick("Phone")}
              >
                Login with Phone Number
              </button>
            </p>
          </div> */}
        </div>
      )}
    </>
  );
};

const LoginPhoneVerification = ({ onClick }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.loading);

  const handleGenerateOTP = () => {
    const phone_no = "+91" + phoneNumber;
    dispatch(sendOtp({ phone_no }));
    onClick("OTP");
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="login-page">
          <div className="logo">
            <span className="brand-text-login">Insocial Orbit</span>
          </div>

          <div className="header-text">
            <h1>Collaborate.</h1>
            <h1>Create. Connect</h1>
          </div>
          <div className="phone-detail-box">
            <img src={Flag} alt="flag-logo" id="flag-logo" />
            <input type="text" id="country-code" value="+91" readOnly />
            <input
              type="text"
              placeholder="Mobile No."
              id="phone-number-signup"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <div className="signup-check-box">
            <input className="signup-checkbox" type="checkbox"></input>
            <label>Remember Me</label>
          </div>

          <button id="login-generate-otp-button" onClick={handleGenerateOTP}>
            Generate OTP
          </button>
          <div className="bottom-text">
            <p>
              <button
                className="navigate-phone-email-button"
                onClick={() => onClick("Loginemail")}
              >
                Login with E-mail
              </button>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

const LoginOTPVerification = ({ onClick }) => {
  const [otp, setOtp] = useState(Array.from({ length: 6 }, () => ""));
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.loading);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleVerifyOTP = () => {
    dispatch(verifyPhoneOtp({ otp: otp }));
  };

  useEffect(() => {
    if (user.isLoginSuccess) {
      navigate("/home");
    }
  });
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="login-page">
          <div className="logo">
            <span className="brand-text-login">Insocial Orbit</span>
          </div>

          <div className="header-text">
            <h1>Collaborate.</h1>
            <h1>Create. Connect</h1>
          </div>
          <div>
            <MuiOtpInput
              value={otp}
              onChange={setOtp}
              length={6}
              separator={<span>-</span>}
              isInputNum
            />
          </div>
          <div className="user-pref-box">
            <div className="signup-check-box">
              <input className="signup-checkbox" type="checkbox"></input>
              <label>Remember Me</label>
            </div>
            <p onClick={() => onClick("Phone")} className="change-phone-number">
              Change Phone No.
            </p>
          </div>

          <button id="login-generate-otp-button" onClick={handleVerifyOTP}>
            Validate and Log In
          </button>

          <div className="bottom-text">
            <p>
              <button
                className="navigate-phone-email-button"
                onClick={() => onClick("Loginemail")}
              >
                Login with E-mail
              </button>
            </p>
          </div>
        </div>
      )}
    </>
  );
};


const LoginPage = () => {
  const [currentComponent, setCurrentComponent] = useState("LoginHome");
  const [userEmail, setUserEmail] = useState("");

  async function handleButtonClick(component, email) {
    await setUserEmail(email);
    setCurrentComponent(component);
  }

  return (
    <div className="home-div">
      {currentComponent === "LoginHome" && (
        <LoginHome onClick={handleButtonClick} />
      )}

      {currentComponent === "Loginemail" && (
        <LoginEmailVerification onClick={handleButtonClick} />
      )}
      {currentComponent === "Phone" && (
        <LoginPhoneVerification onClick={handleButtonClick} />
      )}

      {currentComponent === "OTP" && (
        <LoginOTPVerification onClick={handleButtonClick} />
      )}
    </div>
  );
};

export default LoginPage;

import React, { useEffect, useState } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./BrandLanding.scss";
import Navbar from "../LandingComponents/Navbar/Navbar";
import Footer from "../LandingComponents/Footer/Footer";
import brandpanel from "../assets/brand-panel-2-original.jpg";
import selfieBrandCarousel from "../assets/Image 2.png";
import brandGroupCarousel from "../assets/image 3.png";
import brandcarousel from "../assets/Image 1 (1).png";
import brandpanel4 from "../assets/brandpanel4.png";
import roundbrand from "../assets/roundbrand.png";
import brandPanelCardsRpund from "../assets/brandNetwork.png";
import brandNetwork from "../assets/brandNetwork.png";
import brandInsights from "../assets/brandInsights.png";
import brandScalableCampaing from "../assets/brandScalableCampaing.png";
import brandAuthentic from "../assets/brandAuthentic.png";
import brandSupport from "../assets/brandSupport.png";
import UserReviewsHome from "../LandingComponents/UserReviewsHome/UserReviewsHome";
import Slider from "react-slick";

function BrandLanding() {
  const stats = [
    {
      title: "ROI",
      value: "578%",
      description:
        "Business report an average return of $5.78 for every $1 spent on influencer marketing, indicating a 578% ROI. (Quickframe)",
    },
    {
      title: "$24 B",
      value: "",
      description:
        "The global influencer marketing market size has more than tripled since 2019, reaching an estimated $24 billion in 2024. (Statista)",
    },
    {
      title: "70%",
      value: "",
      description:
        "70% of teens trust influencers more than traditional celebrities. (EY Insights)",
    },
    {
      title: "135%",
      value: "",
      description:
        "Video content campaigns drive 135% more organic engagement than static posts. (Sprout Social)",
    },
  ];

  const slides = [
    {
      title: "Digital Innovation",
      description:
        "Exploring the latest trends in digital transformation and technology advancement",
    },
    {
      title: "Future of Work",
      description:
        "Discovering new ways of working and collaborating in the digital age",
    },
    {
      title: "Sustainable Tech",
      description:
        "Leading the way in environmental sustainability through technology",
    },
  ];
  const [currentSlide, setCurrentSlide] = useState(0);
  const images = [brandcarousel, selfieBrandCarousel, brandGroupCarousel];

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500, // Transition speed
    slidesToShow: 1, // Number of slides to show
    slidesToScroll: 1, // Number of slides to scroll
    autoplay: true, // Auto slide
    autoplaySpeed: 3000, // Auto slide interval
    arrows: false, // Hide previous/next arrows
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(timer);
  }, []);
  return (
    <>
      <Navbar />
      {/* Banner */}
      <div className="brand-container">
        {/* Hero Section */}
        <div className="brand-hero-section d-flex align-items-center justify-content-center">
          <div className="text-center">
            <h1 className="hero-title mt-auto">
              Where Connections Ignite and Partnerships Thrive{" "}
            </h1>
            <span className="text-light">
              <hr />
            </span>
          </div>
        </div>
        {/* panel-2 */}
        {/* Why Influencer Marketing Thrives */}
        <section className="why-marketing container py-5">
          <div className="brand-panel-title">
            <div className="brand-section-title">
              Why Influencer Marketing Thrives
            </div>
          </div>

          <div className="mt-4">
            {/* Top Row: Two Images */}
            <div className="row justify-content-center">
              {["Trust and authenticity", "Targeted reach"].map(
                (item, index) => (
                  <div className="col-md-6 mb-4" key={index}>
                    <div className="card shadow-sm text-center">
                      <div className="image-container">
                        <img
                          src={brandpanel}
                          alt={item}
                          className="card-img-top"
                        />
                        <div className="overlay-text">
                          <p className="card-text">{item}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>

            {/* Bottom Row: Three Images */}
            <div className="row">
              {[
                "Higher engagement",
                "Content creation power",
                "Evolving consumer behavior",
              ].map((item, index) => (
                <div className="col-md-4 mb-4" key={index}>
                  <div className="card shadow-sm text-center">
                    <div className="image-container">
                      <img
                        src={brandpanel}
                        alt={item}
                        className="card-img-top"
                      />
                      <div className="overlay-text">
                        <p className="card-text">{item}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* panel-3 */}
        <div className="text-center">
          <span className="text-light">
            <hr />
          </span>

          {/* Carousel Section */}
          <div className="mt-4 slider">
            <Slider {...settings}>
              {images.map((imgSrc, index) => (
                <div key={index}>
                  <img
                    src={imgSrc}
                    className="d-block w-100"
                    alt={`Slide ${index + 1}`}
                    style={{ height: "25rem" }}
                  />
                  <div className="slider-overlay-text">
                    <p className="card-text">Key Trends</p>
                    <h2
                      className={`card-text ${index === 1 ? "mt-2" : "mt-0"}`}
                    >
                      Lorem Ipsum Trends
                    </h2>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div
          className="container"
          style={{ paddingLeft: "5rem", paddingRight: "5rem" }}
        >
          {/* panel-4 */}
          <div className="container brand-panel-4 mt-5 mb-5">
            <div className="row align-items-center">
              {/* Left Side: Text Content */}
              <div className="col-xs-12 col-sm-12 col-md-6">
                <div>
                  <div className="heading-Challenges">
                    Challenges for Brands in Influencer Marketing
                  </div>
                </div>

                {/* Map over challenges */}
                {[
                  {
                    id: 1,
                    title: "Finding the Right Influencer",
                    description:
                      "Aligning the influencer's voice with brand values.",
                  },
                  {
                    id: 2,
                    title: "Measuring ROI",
                    description:
                      "Tracking performance and outcomes beyond likes and shares.",
                  },
                  {
                    id: 3,
                    title: "Content Authenticity",
                    description:
                      "Balancing sponsored content with genuine storytelling.",
                  },
                  {
                    id: 4,
                    title: "Scalability",
                    description:
                      "Managing multiple influencers across different campaigns.",
                  },
                  {
                    id: 5,
                    title: "Budget Constraints",
                    description:
                      "Allocating resources effectively for influencer collaborations.",
                  },
                ].map((challenge) => (
                  <div
                    key={challenge.id}
                    className="d-flex align-items-center my-4"
                  >
                    {/* Left Image */}
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        src={roundbrand}
                        alt="Influencer"
                        style={{
                          width: "30px",
                          height: "auto",
                          borderRadius: "8px",
                        }}
                      />
                      {/* Numeric Number */}
                      <div
                        style={{
                          color: "rgba(107, 142, 118, 1)",
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          fontWeight: "600",
                          lineHeight: "30px",
                          textAlign: "left",
                          textUnderlinePosition: "from-font",
                          textDecorationSkipInk: "none",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        {challenge.id}
                      </div>
                    </div>

                    {/* Right Text */}
                    <div className="challenges-brand">
                      <div className="title-Challenges">{challenge.title}</div>
                      <p className="description-Challenges">
                        {challenge.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              {/* Right Side: Image */}
              <div className="col-xs-12 col-sm-12 col-md-6">
                {/* <div className="brand-challenges-img"> */}
                <div className="about-image">
                  <img
                    src={brandpanel4} // Replace with your actual image
                    alt="About us"
                    className="img-fluid"
                  />
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* panel-5 */}
        <div
          className="main-content"
          style={{ paddingLeft: "5rem", paddingRight: "5rem" }}
        >
          <div className="heading-brand-benifit">
            <div className="titles-brand">
              How Brands Benefit with Insocial Orbit
            </div>
          </div>
          <div className="all-cards row mb-5">
            {[
              {
                id: 1,
                image: brandPanelCardsRpund,
                heading: "Curated Influencer Network",
                description:
                  "Access to verified, high impact influencers across niches.",
              },
              {
                id: 2,
                image: brandPanelCardsRpund,
                heading: "Data Driven Insights",
                description:
                  "Performance tracking and ROI metrics to optimize campaigns.",
              },
              {
                id: 3,
                image: brandPanelCardsRpund,
                heading: "Scalable Campaigns",
                description:
                  "Effortlessly manage multiple influencers at scale.",
              },
              {
                id: 4,
                image: brandPanelCardsRpund,
                heading: "Authentic Storytelling",
                description:
                  "Tailored campaigns that align influencers' voices with your brand message.",
              },
              {
                id: 5,
                image: brandPanelCardsRpund,
                heading: "End to End Support",
                description:
                  "From influencer discovery to campaign execution and performance reporting.",
              },
            ].map((card) => (
              <div
                key={card.id}
                className="col-xs-12 col-sm-12 col-md-12 col-lg-4 text-center"
              >
                <div className="card-one">
                  <div className="card">
                    <div className="card-content">
                      <div className="card-image">
                        <img
                          src={card.image} // Replace with your actual image
                          alt="Card Image"
                        />
                      </div>
                      <div className="brand-text-container">
                        <div className="brand-card-heading">
                          <div className="card-heading">{card.heading}</div>
                        </div>
                        <div className="brand-card-description">
                          <p className="card-description">{card.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* panel-6 */}
        <div
          className="container impactful-stats"
          style={{ paddingLeft: "5rem", paddingRight: "5rem" }}
        >
          <h2 className="title">Impactful Stats</h2>
          <div className="stats-container">
            {stats.map((stat, index) => (
              <div className="stat-card" key={index}>
                <h3 className="stat-title">{stat.title}</h3>
                <hr className="divider" />
                <div className="stats-description">
                  <p className="stat-description">{stat.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <UserReviewsHome />
      </div>
      <Footer />
    </>
  );
}

export default BrandLanding;

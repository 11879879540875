import {
  REGISTER_EMAIL_REQUEST,
  REGISTER_EMAIL_SUCCESS,
  REGISTER_EMAIL_FAIL,
  VERIFY_EMAIL_OTP_REQUEST,
  VERIFY_EMAIL_OTP_SUCCESS,
  VERIFY_EMAIL_OTP_FAIL,
  REGISTER_PHONE_REQUEST,
  REGISTER_PHONE_SUCCESS,
  REGISTER_PHONE_FAIL,
  VERIFY_PHONE_OTP_REQUEST,
  VERIFY_PHONE_OTP_SUCCESS,
  VERIFY_PHONE_OTP_FAIL,
  LOGIN_SEND_OTP_EMAIL_REQUEST,
  LOGIN_SEND_OTP_PHONE_REQUEST,
  LOGIN_SEND_OTP_EMAIL_SUCCESS,
  LOGIN_SEND_OTP_PHONE_SUCCESS,
  LOGIN_SEND_OTP_EMAIL_FAIL,
  LOGIN_SEND_OTP_PHONE_FAIL,
  VERIFY_EMAIL_OTP_RESET,
  VERIFY_PHONE_OTP_RESET,
  REGISTER_EMAIL_RESET,
  REGISTER_PHONE_RESET,
  LOGIN_SEND_OTP_EMAIL_RESET,
  LOGIN_VERIFY_OTP_EMAIL_SUCCESS,
  LOGIN_VERIFY_OTP_PHONE_SUCCESS,
  SET_EMAIL_IN_REDUX,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  CLEAR_ERROR,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  LOGIN_VERIFY_OTP_EMAIL_REQUEST,
  LOGIN_VERIFY_OTP_EMAIL_FAIL,
} from "../constants/userConstants";

export const userReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case REGISTER_EMAIL_REQUEST:
    case LOGIN_SEND_OTP_EMAIL_REQUEST:
    case LOGIN_SEND_OTP_PHONE_REQUEST:
    case LOAD_USER_REQUEST:
      return {
        ...state,
        loading: true,
        isAuthenticated: false,
      };

    case REGISTER_PHONE_REQUEST:
      return {
        ...state,
        loading: true,
        isOtpSendForMobile: false,
      };

    case VERIFY_PHONE_OTP_REQUEST:
      return {
        ...state,
        loading: true,
        isOtpVerifiedForMobile: false,
      };

    case VERIFY_EMAIL_OTP_REQUEST:
      return {
        ...state,
        loading: true,
        isOtpVerifiedSuccessfully: false,
      };

    case REGISTER_EMAIL_SUCCESS:
    case LOGIN_SEND_OTP_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        // user: action.payload,
        isOtpSendSuccessfully: true,
      };
    case LOGIN_SEND_OTP_PHONE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };

    case VERIFY_PHONE_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        isOtpVerifiedForMobile: true,
      };

    case REGISTER_PHONE_SUCCESS:
      return {
        ...state,
        loading: false,
        isOtpSendForMobile: true,
      };

    case VERIFY_EMAIL_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        isOtpVerifiedSuccessfully: true,
      };

    case LOGOUT_SUCCESS:
      return {
        loading: false,
        user: null,
        isAuthenticated: false,
      };

    case LOGIN_VERIFY_OTP_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        isOtpVerified: false,
      };

    case LOGIN_VERIFY_OTP_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        isOtpVerified: true,
      };

    case REGISTER_EMAIL_FAIL:
    case VERIFY_EMAIL_OTP_FAIL:
    case REGISTER_PHONE_FAIL:
    case VERIFY_PHONE_OTP_FAIL:
    case LOGIN_SEND_OTP_EMAIL_FAIL:
    case LOGIN_SEND_OTP_PHONE_FAIL:
    case LOGIN_VERIFY_OTP_EMAIL_FAIL:
    case LOAD_USER_FAIL:
    case LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        isAuthenticated: false,
      };

    case VERIFY_EMAIL_OTP_RESET:
    case VERIFY_PHONE_OTP_RESET:
    case REGISTER_EMAIL_RESET:
    case REGISTER_PHONE_RESET:
    case LOGIN_SEND_OTP_EMAIL_RESET:
      return {
        ...state,
        loading: false,
        success: false,
        isAuthenticated: false,
      };
    case LOGIN_VERIFY_OTP_PHONE_SUCCESS:
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        isAuthenticated: true,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const emailReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_EMAIL_IN_REDUX:
      return {
        ...state,
        userEmail: action.payload,
      };
    default:
      return state;
  }
};

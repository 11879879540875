import React from "react";
import "./SignUpWithLinks.scss";
import loginimg from "../../components/assets/loginsideimg.png";
import google from "../../components/assets/google-signup.png"; // Add your Google icon path
import mobile from "../../components/assets/mobile-signup.png"; // Add your Phone icon path
import mail from "../../components/assets/mail-signup.png"; // Add your Email icon path
import { Link, useNavigate } from "react-router-dom";

function SignUpWithLinks() {
  const navigate = useNavigate();

  return (
    <>
      <div className="container-signup-with-links p-0 mt-5 mb-5">
        <div className="card">
          <div className="row no-gutters">
            {/* Left Side (Image Section) */}
            <div className="col-12 col-md-6 col-sm-12 left">
              <img src={loginimg} alt="login-side-img" className="img" />
            </div>

            {/* Right Side (Login and Signup Buttons) */}
            <div className="col-12 col-md-6 col-sm-12 right">
              <div className="heading">
                <div className="title">Welcome to InSocial Orbit</div>
              </div>
              <div className="buttons">
                <button
                  type="button"
                  className="btn btn-lg mb-2 d-flex align-items-center "
                  onClick={() => navigate("/google-login")} // Navigate to Google Login page
                >
                  <img
                    src={google}
                    alt="Google Icon"
                    className="icon mr-2"
                    style={{
                      width: "24px",
                      height: "25px",
                      marginRight: "10px",
                    }}
                  />
                  Continue with Google
                </button>

                <button
                  type="button"
                  className="btn btn-lg mb-2 d-flex align-items-center"
                  onClick={() => navigate("/phone-sign-up")} // Navigate to Phone Number Login page
                >
                  <img
                    src={mobile}
                    alt="Phone Icon"
                    className="icon mr-2"
                    style={{
                      width: "17px",
                      height: "25px",
                      marginRight: "16px",
                    }}
                  />
                  Continue with Phone Number
                </button>

                <button
                  type="button"
                  className="btn btn-lg d-flex align-items-center"
                  onClick={() => navigate("/email")} // Navigate to Email Login page
                >
                  <img
                    src={mail}
                    alt="Email Icon"
                    className="icon mr-2"
                    style={{
                      width: "25px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                  />
                  Continue with Email
                </button>
              </div>

              <div className="socials">
                <div className="links-with-socials">
                  <p>
                    Already Have Account?{" "}
                    <Link to="#social-links">Log In Here</Link>
                  </p>
                </div>
                {/* New "Not a Brand?" Section */}
                <div className="not-a-brand">
                  <p>
                    Not a Brand?{" "}
                    <span
                      onClick={() => navigate("/influencer-signup")}
                      className="signup-link"
                    >
                      <Link>Sign up as Influencer</Link>
                    </span>
                  </p>
                </div>
                {/* Terms and Conditions */}
                <div className="terms-and-conditions text-center">
                  <p>By continuing, you agree to our Terms and Conditions .</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUpWithLinks;

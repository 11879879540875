import React, { useEffect, useState } from "react";
import SearchIcon from "../assets/Search.svg";
import Vector from "../assets/Vector.svg";
import Dashboard from "../assets/dashboard.svg";
import "./CampaignDashboard.scss";
import Ongoing from "../assets/Ongoing.svg";
import Calender from "../assets/Calender.svg";
import Location from "../assets/Location.svg";
import Line from "../assets/Line.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCampaign,
  getAllCampaignsForBrand,
} from "../../actions/campaignAction";
import CampaignMain from "../Campaign/Campaign";
import { useNavigate } from "react-router-dom";
import Draft from "../assets/Draft.svg";
import CompletedIcon from "../assets/Completed.svg";
import Scheduled from "../assets/Scheduled.svg";
import threeDots from "../assets/Frame1.svg";
import Warning from "../assets/Warning.svg";
import Delete from "../assets/Delete.svg";
import Edit from "../assets/Edit.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Poster from "../assets/Poster.png";
import MuiLoader from "../layout/Loader/MuiLoader";
import { City } from "country-state-city";

function CampaignDashboard() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [opendialog, setOpendialog] = React.useState(false);

  const [selectedCampaignId, setSelectedCampaignId] = useState("");
  const open3Dots = Boolean(anchorEl);
  const handleDots = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedCampaignId(id);
  };
  const handleDotsClose = () => {
    setAnchorEl(null);
  };

  const handledialogEdit = () => {};

  const handledialogOpen = () => {
    setOpendialog(true);
  };

  const handledialogClose = () => {
    setOpendialog(false);
    handleDotsClose();
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isDeleted } = useSelector((state) => state?.campaigns || {});

  useEffect(() => {
    dispatch(getAllCampaignsForBrand());
    if (isDeleted) {
      // setOpendialog(false);
      dispatch(getAllCampaignsForBrand());
    }
  }, [dispatch, isDeleted]);

  const handledeleteCampaign = (campaignId) => {
    dispatch(deleteCampaign(campaignId));
    setOpendialog(false);
    handleDotsClose();
  };

  const { campaigns, loading } = useSelector((state) => state?.campaigns || {});
  const { user } = useSelector((state) => state?.user || {});

  const goToCampaignOverview = (campaign) => {
    navigate(`/campaign/${campaign.campaign_id}`, { state: { campaign } });
  };

  const goToEditCampaign = (editCampaign) => {
    navigate(`/campaign/create`, {
      state: {
        editCampaign: {
          ...editCampaign,
          state: JSON.parse(editCampaign.state),
          city:
            JSON.parse(editCampaign.city) !== null
              ? JSON.parse(editCampaign.city).map((ele) => {
                  return {
                    value: ele.value === undefined ? ele : ele.value,
                    label: ele.label === undefined ? ele : ele.label,
                  };
                })
              : [],
          gender:
            JSON.parse(editCampaign.gender) !== null
              ? JSON.parse(editCampaign.gender).map((ele) => {
                  return {
                    value: ele.value === undefined ? ele : ele.value,
                    label: ele.label === undefined ? ele : ele.label,
                  };
                })
              : [],
          language:
            JSON.parse(editCampaign.language) !== null
              ? JSON.parse(editCampaign.language).map((ele) => {
                  return {
                    value: ele.value === undefined ? ele : ele.value,
                    label: ele.label === undefined ? ele : ele.label,
                  };
                })
              : [],
        },
        isedited: true,
      },
    });

    console.log({
      ...editCampaign,
      state: JSON.parse(editCampaign.state),
      city: JSON.parse(editCampaign.city),
      gender: JSON.parse(editCampaign.gender),
      language: JSON.parse(editCampaign.language),
    });
  };

  const goTOCreateCampaignForm = () => {
    navigate("/campaign/create");
  };

  const truncateTitle = (title, maxLength) => {
    if (title.length > maxLength) {
      return `${title.slice(0, maxLength)}...`;
    }
    return title;
  };

  const formatLanguages = (languagesJson) => {
    try {
      const languagesArray = JSON.parse(languagesJson);

      return languagesArray.map((language, index) => (
        <span
          key={index}
          style={{
            width: "40px",
            height: "23px",
            borderRadius: "5px",
            border: "0.5px solid rgba(0, 0, 0, 0.5)",
            background: "#fff",
            color: "rgba(0, 0, 0, 0.5)",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
            marginRight: "5px",
          }}
        >
          {language.slice(0, 3)}
        </span>
      ));
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return "";
    }
  };

  function formatDateRange(startDate, endDate) {
    const options = { day: "numeric", month: "long", year: "numeric" };
    const start = new Date(startDate).toLocaleDateString("en-US", options);
    const end = new Date(endDate).toLocaleDateString("en-US", options);

    const startDay = new Date(startDate).getDate();
    const startMonth = new Date(startDate).toLocaleDateString("en-US", {
      month: "short",
    });
    const startYear = new Date(startDate).getFullYear();

    const endDay = new Date(endDate).getDate();
    const endMonth = new Date(endDate).toLocaleDateString("en-US", {
      month: "short",
    });
    const endYear = new Date(endDate).getFullYear();

    return `${startDay}th ${startMonth} to ${endDay}th ${endMonth} ${endYear}`;
  }

  const countInstagramTotal = (timelineData) => {
    return timelineData?.reduce((acc, weekData) => {
      acc +=
        (weekData.Instagram?.reels || 0) +
        (weekData.Instagram?.posts || 0) +
        (weekData.Instagram?.story || 0);
      return acc;
    }, 0);
  };

  const countYouTubeTotal = (timelineData) => {
    return timelineData?.reduce((acc, weekData) => {
      acc +=
        (weekData.YouTube?.videos || 0) + (weekData.YouTube?.live_stream || 0);
      return acc;
    }, 0);
  };

  const getCurrentDate = () => new Date();

  const isOngoing = (campaign) => {
    if (!campaign) {
      return false;
    }

    const currentDate = getCurrentDate();
    let isOngoing =
      campaign.issavedasdraft === 0 &&
      campaign.isautopublished === 0 &&
      currentDate >= new Date(campaign.start_date) &&
      currentDate <= new Date(campaign.end_date);

    return isOngoing;
  };

  const isCompleted = (campaign) => {
    if (!campaign) {
      return false;
    }

    const currentDate = getCurrentDate();
    const completed =
      campaign.issavedasdraft === 0 &&
      campaign.isautopublished === 0 &&
      currentDate > new Date(campaign.end_date);

    return completed;
  };

  const isScheduledForLater = (campaign) => {
    if (!campaign) {
      return false;
    }

    const currentDate = getCurrentDate();
    const completed =
      campaign.issavedasdraft === 0 &&
      campaign.isautopublished === 0 &&
      new Date(campaign.start_date) > currentDate;
    return completed;
  };

  const isSavedDraft = (campaign) => campaign.issavedasdraft === 1;

  const renderCampaignSection = (title, filterFunction) => {
    const filteredCampaigns = campaigns.filter(filterFunction);

    return filteredCampaigns.length > 0 ? (
      <>
        <h3>{title}</h3>
        <div className="campaigns">
          {filteredCampaigns.map((campaign, index) => (
            <div className="campaign-card" key={index}>
              <div className="campaign-image">
                <img src={campaign?.campaign_poster || Poster} alt="Campaign" />
                {campaign.issavedasdraft === 1 && (
                  <div
                    className="three-dots"
                    onClick={(event) =>
                      handleDots(event, campaign?.campaign_id)
                    }
                  >
                    <img src={threeDots} alt="Three Dots" />
                  </div>
                )}
                <Menu
                  id={`basic-menu-${campaign?.campaign_id}`}
                  anchorEl={anchorEl}
                  open={open3Dots}
                  onClose={handleDotsClose}
                  elevation={0}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <MenuItem onClick={handledialogEdit}>
                    <img src={Edit} alt="Dashboard Icon" />
                    <span>Edit This Campaign?</span>
                  </MenuItem>
                  <MenuItem onClick={handledialogOpen}>
                    <img src={Delete} alt="Dashboard Icon" />
                    <span>Delete This Campaign?</span>
                  </MenuItem>
                  <Dialog
                    id={`dialog-${campaign?.campaign_id}`}
                    open={opendialog}
                    onClose={handledialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="dialog-box"
                    BackdropProps={{
                      style: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                    }}
                  >
                    <DialogTitle id="alert-dialog-title">
                      <img src={Warning} alt="Warning" />
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <span>
                          Deleting this campaign will remove it from your
                          Campaigns and deactivate any active influencer
                          collaborations. Are you sure?
                        </span>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => handledeleteCampaign(selectedCampaignId)}
                      >
                        Yes, Delete this Campaign
                      </Button>
                      <Button onClick={handledialogClose}>
                        Cancel, Keep this Campaign
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Menu>
              </div>
              <div className="campaign-content">
                <div className="campaign-status">
                  <span>{truncateTitle(campaign?.campaign_title, 16)}</span>
                  {isOngoing(campaign) && (
                    <div className="status">
                      <img src={Ongoing} alt="Ongoing Icon" />
                      <span className="ongoing">Ongoing</span>
                    </div>
                  )}
                  {isCompleted(campaign) && (
                    <div className="status">
                      <img src={CompletedIcon} alt="Completed Icon" />
                      <span className="completed">Completed</span>
                    </div>
                  )}
                  {isSavedDraft(campaign) && (
                    <div className="status">
                      <img src={Draft} alt="Draft Icon" />
                      <span className="draft">Saved as Draft</span>
                    </div>
                  )}
                  {isScheduledForLater(campaign) && (
                    <div className="status">
                      <img src={Scheduled} alt="Draft Icon" />
                      <span className="scheduled">Scheduled for later</span>
                    </div>
                  )}
                </div>
                <div className="aim">
                  {truncateTitle(campaign?.campaign_obj, 30)}
                </div>
                <div className="location-calendar-info">
                  <div className="calender-info">
                    <img src={Calender} alt="Calender Icon" />
                    <span>
                      {(campaign?.start_date && campaign?.end_date && (
                        <span>
                          {formatDateRange(
                            campaign.start_date,
                            campaign.end_date
                          )}
                        </span>
                      )) || <span className="missing-info">Date missing</span>}
                    </span>
                  </div>

                  <div className="location-info">
                    <img src={Location} alt="Calender Icon" />
                    <span>
                      {(user?.area && <span>{user?.area}</span>) || (
                        <span className="missing-info">Location missing</span>
                      )}
                    </span>
                  </div>
                </div>
                <div className="line"></div>
                <span className="deliverables-title">Deliverables</span>
                <span>
                  {(campaign && campaign.timeline && (
                    <div className="required-content">
                      {campaign.timeline && (
                        <div className="instagram-total">
                          <span>{`${countInstagramTotal(
                            campaign.timeline
                          )} from Insta `}</span>
                        </div>
                      )}
                      {/* <span>•</span> */}
                      {campaign.timeline && (
                        <div className="youtube-total">
                          <span>{`${countYouTubeTotal(
                            campaign.timeline
                          )} from YT`}</span>
                        </div>
                      )}
                      {/* {campaign.language && <span>•</span>} */}
                      {campaign.language && (
                        <div className="languages">
                          {formatLanguages(campaign.language)}
                        </div>
                      )}
                    </div>
                  )) || (
                    <div className="required-content">
                      <span>Deliverables missing</span>
                    </div>
                  )}
                </span>
                {/* <div className="location-calendar-info"> */}
                <div
                  className="campaign-details"
                  onClick={
                    isSavedDraft(campaign)
                      ? () => goToEditCampaign(campaign)
                      : () => goToCampaignOverview(campaign)
                  }
                >
                  <span>
                    {isSavedDraft(campaign) ? (
                      <>Edit your Saved Draft</>
                    ) : (
                      <>Campaign Details</>
                    )}
                  </span>
                  <img src={Line} alt="Edit Icon" />
                </div>
                {/* </div> */}
              </div>
            </div>
          ))}
        </div>
      </>
    ) : null;
  };

  return (
    <div className="campaign-dashboard">
      <>
        {loading ? (
          <MuiLoader />
        ) : campaigns?.length > 0 ? (
          <div className="container">
            <div className="filters">
              <div className="all-filters">
                <div className="search">
                  <img
                    src={SearchIcon}
                    alt="Search Icon"
                    className="search-icon"
                  />
                  <input
                    className="search-bar"
                    type="text"
                    placeholder="Search for Campaigns"
                  />
                </div>
                <div className="filters-buttons">
                  <div className="createCampaign">
                    <span onClick={() => goTOCreateCampaignForm()}>
                      Create Campaign
                    </span>
                    <img src={Dashboard} alt="Dashboard Icon" />
                  </div>
                  <div className="dashboard">
                    <span>Filters</span>
                    <img src={Vector} alt="Vector Icon" />
                  </div>
                </div>
              </div>
            </div>
            {renderCampaignSection(
              "Recently Created Campaigns",
              (campaign) => isOngoing(campaign) || isCompleted(campaign)
            )}
            {renderCampaignSection("Campaigns Saved as Draft", isSavedDraft)}
            {renderCampaignSection(
              "Campaigns Scheduled For Later",
              isScheduledForLater
            )}
          </div>
        ) : (
          <CampaignMain />
        )}
      </>
    </div>
  );
}

export default CampaignDashboard;

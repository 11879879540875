import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../components/LandingComponents/Navbar/Navbar";
import loginimg from "../../components/assets/loginsideimg.png";
import Footer from "../LandingComponents/Footer/Footer";
import "./PhoneEmail.scss";

function PhoneEmail() {
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <div className="container-phone-email p-0 mt-5 mb-5">
        <div className="card">
          <div className="row no-gutters">
            {/* Left Side (Image Section) */}
            <div className="col-12 col-md-6 left">
              <img src={loginimg} alt="phone-email" className="img" />
            </div>

            {/* Right Side (Login and Signup Buttons) */}
            <div className="col-12 col-md-6 right">
              <div className="row">
                <div className="col-12 col-md-12 text-center">
                  <h2>Collaborate.<br /> Create. Connect.</h2>
                  <button
                    type="button"
                    className="btn btn-outline-success btn-lg mb-2"
                    onClick={() => navigate("/phone-sign-up")} // Navigate to PhoneEmail page
                  >
                    Log In with Phone No.
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-success btn-lg mb-3"
                    onClick={() => navigate("/email")} // Navigate to PhoneEmail page
                  >
                    Log In with Email
                  </button>
                </div>

                <div className="col-12 col-md-12 footer">
                  <div className="sign-link mt-auto">
                    Not having a Account? &nbsp;
                    {/* Add link to navigate back to the signup page */}
                    <Link
                      to="/signup"
                      className="btn-link"
                      onClick={() => navigate("/phone-email")} // Navigate to PhoneEmail page
                    >
                      Sign up &nbsp;
                    </Link>
                    here
                  </div>
                  <div className="signup-instruction">
                    By continuing you agree to our terms and conditions
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PhoneEmail;

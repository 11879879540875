import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./navbar.scss";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/navbar-logo.png";

function BasicExample({ path }) {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div className="row-nav">
      <Navbar expand="lg">
        <Container className="">
          <Navbar.Brand to="/home">
            <div className="d-flex align-items-center">
              {/* Logo */}
              <img
                src={logo} // Replace with your actual logo path
                alt="Logo"
                style={{
                  width: "35px",
                  height: "38px",
                  marginRight: "10px",
                  color: "green",
                }} // Adjust the size as needed
              />

              {/* Text */}
              <div className="d-flex flex-column">
                <span
                  className="fw-bold"
                  style={{
                    color: "#6B8E76",
                    fontSize: "1.5rem",
                    width: "20px",
                  }}
                >
                  INSOCIAL
                </span>
                <span
                  className="text-muted"
                  style={{
                    fontSize: "0.6rem",
                    letterSpacing: "1px",
                    fontWeight: "500",
                    paddingLeft: "3%",
                    color: "#676767",
                  }}
                >
                  ORBIT DIGITAL
                </span>
              </div>
            </div>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Link
                to="/"
                className={`nav-link ${currentPath === "/" ? "active" : ""}`}
              >
                Home
              </Link>
              <Link
                to="/about"
                className={`nav-link ${currentPath === "/about" ? "active" : ""}`}
              >
                About
              </Link>
              <Link
                to="/brand"
                className={`nav-link ${currentPath === "/brand" ? "active" : ""}`}
              >
                Brand
              </Link>
              <Link
                to="/influencer"
                className={`nav-link ${currentPath === "/influencer" ? "active" : ""}`}
              >
                Influencer
              </Link>
              {/* Login Button */}
              {(currentPath === "/" ||
                currentPath === "/about" ||
                currentPath === "/brand") && (
                <Nav>
                  <Link
                    to="/login"
                    className="btn btn-success"
                    style={{
                      backgroundColor: "#6C8C7D",
                      borderColor: "#6C8C7D",
                      borderRadius: "8px",
                      fontWeight: "500",
                      padding: "6px 20px",
                      color: "white",
                    }}
                  >
                    Log In
                  </Link>
                </Nav>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default BasicExample;

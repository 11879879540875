import React from "react";
import aboutimg from '../../../components/assets/bannerbg.jpeg';
import "./aboutSection.scss";

const AboutUs = () => {
  return (
    <div className="container-about-section my-5">
      <div className="row align-items-center">
        {/* Left Side: Text Content */}
        <div className="col-xs-12 col-sm-12 col-md-6">
          <h2 className="text-success">About us</h2>
          <p className="text-justify">
            The story of InSocialOrbit began during Harsh's time at MICA, where
            he explored the challenges in media and influencer marketing. He
            noticed that brands and influencers faced significant hurdles, such
            as finding reliable partners, dealing with fake followers, and
            overcoming language barriers. The process lacked a streamlined
            approach to connecting the right resources. Motivated to solve these
            issues, Harsh envisioned a platform that simplifies collaboration
            between brands and influencers. InSocialOrbit was created as a space
            where resources could be utilized efficiently. Brands can find
            influencers based on specific needs, set up campaigns, and measure
            results with ease. Influencers, on the other hand, gain access to
            genuine opportunities to showcase their talent. Today, InSocialOrbit
            empowers businesses and creators to grow together, making the most
            of their resources while fostering trust and meaningful
            collaborations.
          </p>
        </div>

        {/* Right Side: Image */}

        <div className="col-xs-12 col-sm-12 col-md-6">
          <div className="about-image-wrapper">
            <img
              src={aboutimg} // Replace with your actual image
              alt="About us"
              className="img-fluid rounded shadow"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
